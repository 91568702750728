import React from 'react';
import { ReactComponent as Logo } from '@/assets/images/tklogowheel.svg';
import TKSelectLanguage from '@/components/SelectLanguage/TKSelectLanguage';

interface Props {
	children: React.ReactNode | React.ReactNode[];
}

const AuthLayout = ({ children }: Props) => {
	return (
		<div className="flex h-full">
			<div className="hidden sm:hidden md:flex md:w-1/2 bg-primary justify-center items-center">
				<Logo className="w-48 h-48 text-white" />
			</div>
			<div className="w-full md:w-1/2 flex flex-col p-4">
				<div className="self-end bg-muted rounded-lg mb-2">
					<TKSelectLanguage darkBackground />
				</div>
				<div className="flex-grow flex justify-center items-center">
					<div className="w-full max-w-md">{children}</div>
				</div>
			</div>
		</div>
	);
};

export default AuthLayout;
