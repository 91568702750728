import React, { memo, useMemo } from 'react';
import { Checkbox } from '@/components/ui/checkbox';
import { Button } from '@/components/ui/button';
import { ChevronRight } from 'lucide-react';
import { MultistepItem } from '.';
import { MultistepElementsGeneratorProps } from './MultistepElementsGenerator';
import { Label } from '@/components/ui/label';

type CheckboxItemProps = {
	el: MultistepItem;
	variant: 'expand' | 'select';
	hasCheckedSubItems: boolean;
	index: number;
	hasSubItems: boolean;
} & Pick<
	MultistepElementsGeneratorProps,
	'handleAddStep' | 'handleSelectCategory' | 'handleSelectElement'
>;

const CheckboxItem: React.FC<CheckboxItemProps> = ({
	el,
	handleAddStep,
	handleSelectCategory,
	handleSelectElement,
	variant,
	index,
	hasCheckedSubItems,
}) => {
	const checkboxDataAttributes = useMemo(
		() => ({
			'data-checkbox-value': el.value,
			'data-checkbox-category': el.category,
			'data-checkbox-has-checked-sub-items': hasCheckedSubItems,
		}),
		[el, hasCheckedSubItems],
	);

	const handleChange = (checked: boolean) => {
		if (variant === 'select') {
			handleSelectElement(el, checked);
		} else {
			handleSelectCategory(
				el.value.toString(),
				hasCheckedSubItems || el.active,
			);
		}
	};

	return (
		<div className="flex w-full justify-between items-center pl-4 hover:bg-accent transition-colors duration-150">
			<Label
				htmlFor={`checkbox-${el.value}`}
				className="flex items-center flex-1 cursor-pointer py-2"
			>
				<div className="flex items-center space-x-2 w-full">
					<Checkbox
						id={`checkbox-${el.value}`}
						checked={hasCheckedSubItems || el.active}
						onCheckedChange={handleChange}
						{...checkboxDataAttributes}
					/>
					<span className="flex-1 truncate max-w-[180px]">{el.label}</span>
				</div>
			</Label>

			{variant === 'expand' && (
				<Button
					variant="ghost"
					size="sm"
					className="hover:bg-black/10"
					onClick={() => handleAddStep(index)}
				>
					<ChevronRight className="h-4 w-4" />
				</Button>
			)}
		</div>
	);
};

export default memo(CheckboxItem);
