import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/contexts/Global/AuthContext';
import { trpc } from '@/api/trpc';
import TKTimeTooltip from '@/components/Common/TKTimeTooltip';
import CompanyUserActionsMenu from './CompanyUserActionsMenu';
import { Role, RoleType } from '@/types/common/roles';
import TKHelpTooltip from '@/components/Common/TKHelpTooltip';
import EmailInvitationActionsMenu from './EmailInvitationActionsMenu';
import { DataTable } from '@/components/Table/data-table';
import { TypographySmall } from '@/components/ui/typography';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Badge } from '@/components/ui/badge';

export default function CompanyUsersTable(): JSX.Element {
	const { companyUserId, companyId } = useAuth();
	const { t } = useTranslation();

	const usersQuery = trpc.companyUser.list.useQuery({ companyId });
	const invitationsQuery = trpc.invitation.list.useQuery({ companyId });
	const users = usersQuery.data ?? [];
	const invitations = invitationsQuery.data ?? [];

	const wrappedData = [
		...users.map((user) => ({
			id: user.id,
			email: user.email,
			fullName: user.fullName,
			createdAt: user.createdAt,
			isSuperuser: user.isSuperuser,
			type: 'user',
			role: user.role,
			status: t('company.users.inviteStatus.invitationAccepted'),
		})),
		...invitations.map((invitation) => ({
			id: invitation.id,
			email: invitation.email,
			fullName: invitation.fullName ?? '-',
			isSuperuser: false,
			createdAt: invitation.createdAt,
			type: 'invitation',
			role: invitation.role,
			status: t('company.users.inviteStatus.invitationSent'),
		})),
	];

	return (
		<DataTable
			columns={[
				{
					id: 'fullName',
					title: t('company.users.table.name'),
					enableSorting: true,
					render: (row) => {
						if (row.fullName === '-') return row.fullName;

						const namesArray = row.fullName.split(' ');
						const firstname = namesArray[0];
						const lastName = namesArray.pop()! ?? '';
						return (
							<div className="flex items-center">
								<Avatar className="mr-2 h-8 w-8">
									<AvatarFallback>
										{firstname[0]}
										{lastName[0]}
									</AvatarFallback>
								</Avatar>
								<TypographySmall>{row.fullName}</TypographySmall>
							</div>
						);
					},
				},
				{
					id: 'email',
					title: t('company.users.table.email'),
					render: (row) => {
						if (row.email === '-') return row.email;
						return (
							<a
								href={`mailto:${row.email}`}
								target="_blank"
								rel="noopener noreferrer"
								className="hover:underline"
							>
								{row.email}
							</a>
						);
					},
				},
				{
					id: 'createdAt',
					title: t('company.users.table.registeredAt'),
					render: (row) => <TKTimeTooltip timestamp={row.createdAt} />,
				},
				{
					id: 'status',
					title: t('company.users.table.inviteStatus'),
					filter: true,
				},
				{
					id: 'role',
					title: t('company.users.table.role'),
					filter: true,
					valueToLabel: (value: RoleType) => {
						return t(`shared-roles.${value}`);
					},
					render: (row) => {
						if (row.isSuperuser) {
							return (
								<TKHelpTooltip
									name="SUPERUSER"
									description="Only superusers can see this user, it is not visible to regular company users."
								/>
							);
						}
						return (
							<Badge variant="secondary">
								<TypographySmall>
									{t(`shared-roles.${row.role}`)}
								</TypographySmall>
							</Badge>
						);
					},
				},
				{
					id: 'id',
					title: ' ',
					size: 40,
					render: (row) => {
						const isInvitation = row.type === 'invitation';
						const isMyUser = row.id === companyUserId;

						if (isMyUser) return null;

						if (isInvitation) {
							return (
								<EmailInvitationActionsMenu
									id={row.id as string}
									email={row.email}
									companyId={companyId}
								/>
							);
						} else {
							return (
								<CompanyUserActionsMenu
									id={row.id as number}
									email={row.email}
									role={row.role as Role}
									fullName={row.fullName}
									companyId={companyId}
								/>
							);
						}
					},
				},
			]}
			data={wrappedData}
			searchColumn="fullName"
			defaultSortColumn="fullName"
			defaultSortOrder="asc"
			isLoading={usersQuery.isLoading || invitationsQuery.isLoading}
			error={usersQuery.error || invitationsQuery.error}
			paginationPageSize={100}
		/>
	);
}
