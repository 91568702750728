import { max } from 'date-fns';

/**
 * Get the max date from an array of dates as ISO string.
 */
const getMaxDateAsISOString = (timestamps: string[]) => {
	const validTimestamps: Date[] = [];
	// Create date instances only for valid timestamps
	for (const timestamp of timestamps) {
		if (timestamp) {
			validTimestamps.push(new Date(timestamp));
		}
	}
	// Find max date if there's any items in the array
	const timestamp = validTimestamps.length
		? max(validTimestamps).toISOString()
		: undefined;

	return timestamp;
};

export { getMaxDateAsISOString };
