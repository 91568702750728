export enum EquipmentTypeEnum {
	TRUCK = 'TRUCK',
	MACHINE = 'MACHINE',
	TRAILER = 'TRAILER',
	CAR = 'CAR',
	CONTAINER = 'CONTAINER',
}

export enum EquipmentFuelTypeEnum {
	GASOLINE = 'GASOLINE',
	DIESEL = 'DIESEL',
	LNG_GAS = 'LNG_GAS',
	LBG_GAS = 'LBG_GAS',
	CNG_GAS = 'CNG_GAS',
	CBG_GAS = 'CBG_GAS',
	ELECTRIC = 'ELECTRIC',
}

export interface EquipmentById {
	model: string | null;
	registrationNumber: string | null;
	serialNumber: string;
	type: EquipmentTypeEnum;
	fuelType: EquipmentFuelTypeEnum;
	brand: string | null;
	soldAt: string | null;
	customName: string | null;
	id: string;
	displayName: string;
	groupIds: string[];
	createdAt: string;
}

export enum EquipmentTachographStateEnum {
	Drive = 'DRIVE',
	DriverAvailable = 'DRIVER_AVAILABLE',
	Rest = 'REST',
	Work = 'WORK',
}
