import React, { memo } from 'react';
import FilterBase, { FilterBaseVariant } from '../FilterBase';
import CompareSettingsList from './CompareSettingsList';
import DescribeIcon from '@/components/Common/DescribeIcon';
import { Trans, useTranslation } from 'react-i18next';
import { FiltersConfig } from '@/types/analysis';
import { TypographySmall } from '@/components/ui/typography';
import { GitCompareIcon } from 'lucide-react';
import { getActiveComparison } from './getActiveComparison';

export type CompareFiltersType = Pick<
	Partial<FiltersConfig>,
	'groupDrivers' | 'groupEquipment' | 'groupProjects'
>;

export interface CompareFiltersProps extends CompareFiltersType {
	handleChange: (value: CompareFiltersType) => void;
}

function useCompareFiltersTitle(props: CompareFiltersType) {
	const { t } = useTranslation();
	const comparison = getActiveComparison(props);

	return t(`filters.compare.${comparison}`);
}
function CompareFilters({
	groupDrivers,
	groupEquipment,
	groupProjects,
	handleChange,
	variant,
}: CompareFiltersProps & FilterBaseVariant) {
	const { t } = useTranslation();
	const title = useCompareFiltersTitle({
		groupDrivers,
		groupEquipment,
		groupProjects,
	});
	return (
		<FilterBase
			variant={variant}
			iconButton={GitCompareIcon}
			buttonText={title}
			className="max-w-[280px]"
			dataTestButton="open-comparison-filters-button"
			dataTestContent="comparison-filters-content"
		>
			<div className="min-w-[150px] max-w-full flex flex-col items-center">
				<div className="flex items-center">
					<TypographySmall className="font-semibold">
						{t('filters.compare.text')}
					</TypographySmall>
					<DescribeIcon>
						<Trans i18nKey="filters.compare.description" />
					</DescribeIcon>
				</div>
				<CompareSettingsList
					groupDrivers={groupDrivers}
					groupEquipment={groupEquipment}
					groupProjects={groupProjects}
					handleChange={handleChange}
				/>
			</div>
		</FilterBase>
	);
}

export default memo(CompareFilters);
