import { AuthError } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { toast } from 'sonner';
import { Translations } from '@/i18n-resources';
import { useTranslation } from 'react-i18next';

type AvailableTranslatedErrorCodes =
	Translations['validations']['emailAuth']['errorMessages'];

const useFirebaseAuthUtils = () => {
	const { t } = useTranslation();

	/**
	 * Notify the user in case there's been a authentication error when trying to login.
	 *
	 * @param {AuthError} err
	 */
	const notifySmsVerificationError = (err: unknown) => {
		const error = err as FirebaseError;

		let errorMessage = `${t(
			'validations.verificationCode.errorMessages.general',
		)}`;

		const errors = t('validations.verificationCode.errorMessages', {
			returnObjects: true,
		}) as Record<string, string>;

		if (errors?.[error.code]) {
			errorMessage = errors[error.code];
		}

		toast.error(errorMessage);
	};

	const notifyEmailSignupError = (err: unknown) => {
		const error = err as AuthError;

		// Translate the error based on code, if it's a known translation then show it
		// Otherwise, default to a more general error message
		const generalErrorMessage = t(
			'validations.emailAuth.errorMessages.general',
		);
		const errorTranslations = t(`validations.emailAuth.errorMessages`, {
			returnObjects: true,
		});
		const code = error.code as keyof AvailableTranslatedErrorCodes;
		const translatedError =
			errorTranslations?.[code] || `${generalErrorMessage} ${error.code}`;

		toast.error(translatedError);
	};

	const notifyVerificationCodeSent = () => {
		toast.info(t('login.verificationCode.subtitle'));
	};

	return {
		notifySmsVerificationError,
		notifyEmailSignupError,
		notifyVerificationCodeSent,
	};
};

export { useFirebaseAuthUtils };
