/** Firebase configuration from the current environment. */
const firebaseConfig = {
	apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
	authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
	databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
	projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
	storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
	appId: import.meta.env.VITE_FIREBASE_APP_ID,
	measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
};

/** Google configuration from the current environment. */
const mapboxConfig = {
	apiKey: import.meta.env.VITE_MAPBOX_API_KEY,
};

/** Object with the current domain and the API endpoint */
const endpoints = {
	domain: window.location.origin,
	api: import.meta.env.VITE_BACKEND_URL,
};

export { firebaseConfig, mapboxConfig, endpoints };
