import { ReportInterval } from '@/hooks/analysis/useReportsTemplatesList';

export interface DashboardForm {
	id: number;
	name: string;
	isShareable: boolean;
	sharedWithCompanyUserIds: number[];
}

export type ReportExecuteResult = {
	equipmentIds: string[];
	driverIds: string[];
	projectIds: string[];
	value: number | null;
	periodStart: string;
	periodEnd: string;
	driversLabel: string;
	equipmentLabel: string;
	projectsLabel: string;
	unit: ReportUnit;
}[];

export interface FiltersConfig {
	fromTime: string | null;
	toTime: string | null;
	interval: ReportInterval;

	groupIds: string[] | null;
	equipmentIds: string[] | null;
	driverIds: number[] | null;
	projectIds: string[] | null;

	groupEquipment: boolean;
	groupDrivers: boolean;
	groupProjects: boolean;
	hideDataFromUnknownDrivers: boolean;
	hideDataFromUnknownProjects: boolean;
	hideDataFromWithoutGroups: boolean;

	presetType: PresetTypes;
	presetAmount: number;
	dateType: 'custom' | 'preset';
}

export type PresetTypes =
	| 'allTime'
	| 'lastDays'
	| 'lastDays'
	| 'lastDays'
	| 'lastMonths'
	| 'lastMonths'
	| 'lastMonths'
	| 'lastYears'
	| 'thisWeek'
	| 'thisMonth'
	| 'previousWeek'
	| 'previousMonth'
	| 'previousYear'
	| 'thisYear';

export enum ReportUnit {
	Kilometer = 'kilometer',
	Liter = 'liter',
	LiterPer10Km = 'liter_per_10km',
	KgPer10Km = 'kg_per_10km',
	Percent = 'percent',
	Hours = 'hours',
	Count = 'count',
	Ton = 'ton',
	Kg = 'kg',
	KilometerPerHour = 'kilometer_per_hour',
}

export enum ReportChartTypes {
	Graph = 'graph',
	List = 'list',
	Card = 'card',
}
