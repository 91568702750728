/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spinner } from '@/components/ui/spinner';
import { DataTableFacetedFilter } from '@/components/ui/data-table-faceted-filter';
import { Input } from '@/components/ui/input';
import { Table as TableProps } from '@tanstack/react-table';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from '@/components/ui/table';
import { TRPCClientErrorLike } from '@/api/trpc';
import {
	ColumnDef,
	ColumnFiltersState,
	SortingState,
	flexRender,
	getCoreRowModel,
	getFacetedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
} from '@tanstack/react-table';
import type { HeaderGroup, PaginationState, Row } from '@tanstack/react-table';
import { useState, useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTableColumnHeader } from '@/components/ui/data-table-column-header';
import {
	Pagination,
	PaginationButton,
	PaginationContent,
	PaginationItem,
	PaginationNextButton,
	PaginationPreviousButton,
} from '../ui/pagination';
import { TypographyMuted } from '../ui/typography';
import { getClientFacetedUniqueValues, filterFn } from './table-utils';
import { Button } from '../ui/button';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/popover';
import { PopoverClose } from '@radix-ui/react-popover';
import { PlusCircleIcon, XIcon } from 'lucide-react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { cn } from '@/lib/utils';

export type DataColumn<TData> = {
	hidden?: boolean;
	id: keyof TData extends string ? keyof TData : never;
	title: string;
	filter?: boolean;
	render?: (
		value: TData,
	) => React.ReactNode | string | number | React.ReactNode[];
	accessorFn?: (row: TData) => any;
	valueToLabel?: (value: any) => string;
	enableSorting?: boolean;
	size?: number;
	minSize?: number;
	maxSize?: number;
};

interface Props<TData> {
	data: TData[];
	columns: DataColumn<TData>[];
	searchColumn?: keyof TData extends string ? keyof TData : never;
	defaultSortColumn?: keyof TData extends string ? keyof TData : never;
	defaultSortOrder?: 'desc' | 'asc';
	isLoading: boolean;
	error: Error | TRPCClientErrorLike | any;
	linkFromRow?: (row: Row<TData>) => string;
	paginationPageSize?: number;
	totalCount?: number;
	wrapperClassName?: string;
}

function uppercaseFirst(str: string) {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

// Memoized TableHeader component
const MemoizedTableHeader = memo(
	({ headerGroups }: { headerGroups: HeaderGroup<any>[] }) => (
		<TableHeader className="sticky top-0 z-20 bg-background shadow-sm">
			{headerGroups.map((headerGroup, headerGroupIdx) => (
				<TableRow key={headerGroup.id + headerGroupIdx}>
					{headerGroup.headers.map((header, headerIdx) => {
						const { size, minSize, maxSize } = header.column.columnDef;
						return (
							<TableHead
								key={header.id + headerIdx}
								style={{ width: size, maxWidth: maxSize, minWidth: minSize }}
							>
								{header.isPlaceholder
									? null
									: flexRender(
											header.column.columnDef.header,
											header.getContext(),
										)}
							</TableHead>
						);
					})}
				</TableRow>
			))}
		</TableHeader>
	),
);

MemoizedTableHeader.displayName = 'MemoizedTableHeader';

type MemoizedTableBodyType = {
	rows: Row<any>[];
	tableCols: ColumnDef<any>[];
	isLoading: boolean;
	error: Error | null;
};
// Memoized TableBody component
const MemoizedTableBody = memo<MemoizedTableBodyType>(
	({ rows, tableCols, isLoading, error }) => {
		const { t } = useTranslation();
		return (
			<TableBody
				className="flex-1 overflow-auto"
				data-testid={
					!isLoading && !error
						? 'data-table-body-loaded'
						: 'data-table-body-loading'
				}
			>
				{rows.length ? (
					rows.map((row, rowIdx) => (
						<TableRow
							key={row.id + rowIdx}
							data-state={row.getIsSelected() && 'selected'}
						>
							{row.getVisibleCells().map((cell, cellIdx) => (
								<TableCell
									key={cell.id + cellIdx}
									className="relative overflow-hidden"
								>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</TableCell>
							))}
						</TableRow>
					))
				) : (
					<TableRow>
						<TableCell colSpan={tableCols.length}>
							<span className="flex items-center justify-center">
								{error && error.message}
								{isLoading && <Spinner />}
								{!isLoading && !error && t('common.no_results')}
							</span>
						</TableCell>
					</TableRow>
				)}
			</TableBody>
		);
	},
);

MemoizedTableBody.displayName = 'MemoizedTableBody';

type MemoizedPaginationType = {
	table: TableProps<any>;
	pagination: PaginationState;
	data: any[];
};

// Memoized Pagination component
const MemoizedPagination = memo<MemoizedPaginationType>(
	({ table, pagination, data }) => {
		const { t } = useTranslation();
		return (
			<div className="col-data.length-2 grid grid-cols-3 items-center p-1.5">
				<Pagination className="col-span-1 col-start-2">
					<PaginationContent>
						<PaginationItem>
							<PaginationPreviousButton
								disabled={!table.getCanPreviousPage()}
								onClick={() => {
									table.previousPage();
								}}
							/>
						</PaginationItem>
						{!table.getCanNextPage() && pagination.pageIndex - 2 >= 0 && (
							<PaginationItem>
								<PaginationButton
									onClick={() => table.setPageIndex(pagination.pageIndex - 2)}
								>
									{pagination.pageIndex - 1}
								</PaginationButton>
							</PaginationItem>
						)}
						{table.getCanPreviousPage() && pagination.pageIndex - 1 >= 0 && (
							<PaginationItem>
								<PaginationButton onClick={() => table.previousPage()}>
									{pagination.pageIndex}
								</PaginationButton>
							</PaginationItem>
						)}
						<PaginationItem>
							<PaginationButton isActive>
								{pagination.pageIndex + 1}
							</PaginationButton>
						</PaginationItem>
						{table.getCanNextPage() &&
							pagination.pageIndex + 1 <
								Math.ceil(data.length / pagination.pageSize) && (
								<PaginationItem>
									<PaginationButton onClick={() => table.nextPage()}>
										{pagination.pageIndex + 2}
									</PaginationButton>
								</PaginationItem>
							)}
						{!table.getCanPreviousPage() &&
							pagination.pageIndex + 2 <
								Math.ceil(data.length / pagination.pageSize) && (
								<PaginationItem>
									<PaginationButton
										onClick={() => table.setPageIndex(pagination.pageIndex + 2)}
									>
										{pagination.pageIndex + 3}
									</PaginationButton>
								</PaginationItem>
							)}
						<PaginationItem>
							<PaginationNextButton
								disabled={!table.getCanNextPage()}
								onClick={() => {
									table.nextPage();
								}}
							/>
						</PaginationItem>
					</PaginationContent>
				</Pagination>
				<TypographyMuted className="mb:col-span-1 col-span-3 row-start-2 w-full whitespace-nowrap text-center md:col-span-1 md:col-start-3 md:row-start-1 md:text-right">
					{t('common.paginationSummary', {
						from: pagination.pageIndex * pagination.pageSize,
						to: Math.min(
							(pagination.pageIndex + 1) * pagination.pageSize,
							data.length ?? Number.MAX_SAFE_INTEGER,
						),
						total: data.length,
					})}
				</TypographyMuted>
			</div>
		);
	},
);
MemoizedPagination.displayName = 'MemoizedPagination';

export function DataTable<TData>({
	data,
	columns,
	error,
	isLoading,
	searchColumn,
	defaultSortColumn,
	paginationPageSize,
	wrapperClassName,
	defaultSortOrder = 'asc',
}: Props<TData>) {
	const { t } = useTranslation();
	const [sorting, setSorting] = useState<SortingState>(
		defaultSortColumn
			? [
					{
						desc: defaultSortOrder === 'desc',
						id: defaultSortColumn,
					},
				]
			: [],
	);

	const shouldPaginate = typeof paginationPageSize === 'number';
	const [pagination, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: paginationPageSize ?? data.length,
	});

	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

	const [openFilterId, setOpenFilterId] = useState<string | null>(null);

	const memoizedTableCols = useMemo(
		() =>
			columns.map((c) => {
				return {
					accessorKey: c.id,
					enableSorting: c.enableSorting ?? false,
					header: ({ column }) => (
						<DataTableColumnHeader
							column={column}
							data-sorting={sorting}
							title={
								c.title
									? c.title.charAt(0).toUpperCase() + c.title.slice(1)
									: ''
							}
						/>
					),
					size: c.size,
					maxSize: c.maxSize,
					minSize: c.minSize,
					cell: ({ row }) =>
						c.render ? c.render(row.original) : row.getValue(c.id),
					filterFn,
					accessorFn: c.accessorFn,
					enableHiding: true,
				} satisfies ColumnDef<TData>;
			}),
		[columns, sorting],
	);

	const table = useReactTable({
		data,
		columns: memoizedTableCols,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedUniqueValues: getClientFacetedUniqueValues(),
		getFacetedRowModel: getFacetedRowModel(),
		getPaginationRowModel: shouldPaginate ? getPaginationRowModel() : undefined,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		onPaginationChange: shouldPaginate ? setPagination : undefined,

		state: {
			pagination: shouldPaginate ? pagination : undefined,
			sorting,
			columnFilters,
			columnVisibility: Object.fromEntries(
				columns.map((c) => [c.id, !c.hidden]),
			),
		},
	});

	const filterableColumns = columns.filter(
		(c) => c.filter && table.getColumn(c.id as string)?.getIsVisible(),
	);

	const availableFilters = filterableColumns.filter((c) => {
		const column = table.getColumn(c.id as string);
		return column && !column.getFilterValue();
	});

	const sortedFilterableColumns = [...filterableColumns].sort((a, b) => {
		const aIndex = columnFilters.findIndex((filter) => filter.id === a.id);
		const bIndex = columnFilters.findIndex((filter) => filter.id === b.id);

		if (aIndex === -1 && bIndex === -1) return 0;
		if (aIndex === -1) return 1;
		if (bIndex === -1) return -1;
		return aIndex - bIndex;
	});

	const hasAnyOpenFilter = columns.some(
		(c) => c.filter && table.getColumn(c.id as string)?.getFilterValue(),
	);

	function clearAllFilters() {
		setColumnFilters([]);
		setOpenFilterId(null);
	}

	const [animateRef] = useAutoAnimate();

	return (
		<div className="flex flex-1 flex-col overflow-hidden">
			<div
				className={cn(
					'flex min-h-12 flex-row items-center gap-x-2 overflow-x-auto pt-0.5',
					searchColumn || availableFilters.length ? 'min-h-12' : 'min-h-0',
				)}
				ref={animateRef}
			>
				{searchColumn && (
					<Input
						placeholder={t('common.search')}
						onChange={(event) =>
							table.getColumn(searchColumn)?.setFilterValue(event.target.value)
						}
						className="w-[150px] lg:w-[250px]"
					/>
				)}
				{availableFilters.length > 0 && (
					<div>
						<Popover>
							<PopoverTrigger asChild>
								<Button variant="outline" size="sm">
									<PlusCircleIcon className="size-4 mr-1" />
									{t('common.add_filter')}
								</Button>
							</PopoverTrigger>
							<PopoverContent className="w-60">
								<div className="flex flex-col gap-y-2">
									{availableFilters.map((c) => (
										<PopoverClose asChild key={c.id}>
											<Button
												variant="secondary"
												size="sm"
												onClick={() => {
													setOpenFilterId(c.id);
												}}
											>
												{uppercaseFirst(c.title)}
											</Button>
										</PopoverClose>
									))}
								</div>
							</PopoverContent>
						</Popover>
					</div>
				)}

				{sortedFilterableColumns.map((c, colIdx) => {
					const column = table.getColumn(c.id as string);
					if (
						column &&
						c.filter &&
						column.getIsVisible() &&
						(openFilterId === c.id || column.getFilterValue())
					) {
						return (
							<DataTableFacetedFilter
								key={c.id + colIdx}
								column={column}
								title={uppercaseFirst(c.title)}
								valueToLabel={c.valueToLabel}
								open={openFilterId === c.id}
								onOpenChange={(val) => {
									if (!val) {
										setOpenFilterId(null);
									} else {
										setOpenFilterId(c.id);
									}
								}}
							/>
						);
					}
					return null;
				})}
				{hasAnyOpenFilter && (
					<Button
						type="button"
						variant="secondary"
						size="sm"
						className="h-8"
						onClick={() => clearAllFilters()}
					>
						<XIcon className="size-3.5" />
						{t('common.clearFilters')}
					</Button>
				)}
			</div>
			<div
				className={cn(
					'mb-0.5 flex flex-grow flex-col overflow-hidden rounded-md border',
					wrapperClassName,
				)}
			>
				<div className="relative w-full flex-1 overflow-auto">
					<Table className="flex-1">
						<MemoizedTableHeader headerGroups={table.getHeaderGroups()} />
						<MemoizedTableBody
							rows={table.getRowModel().rows}
							tableCols={memoizedTableCols}
							isLoading={isLoading}
							error={error}
						/>
					</Table>
				</div>
				{shouldPaginate && (
					<MemoizedPagination
						table={table}
						pagination={pagination}
						data={data}
					/>
				)}
			</div>
		</div>
	);
}
