import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import UserProfileForm from '@/components/UserProfile/UserProfileForm';
import { useUserProfile } from '@/hooks/auth/useUserProfile';
import { toast } from 'sonner';
import { trpc } from '@/api/trpc';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';

function UserPersonalInformation(): JSX.Element {
	const { t } = useTranslation();
	const userProfile = useUserProfile();

	const updateUserMutation = trpc.user.update.useMutation({
		onSuccess() {
			toast.success(
				t('common.api.update.success', {
					type: t('userProfile.personalInformation.base', { count: 2 }),
				}),
			);
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	return (
		<Card>
			<CardHeader>
				<CardTitle>{t('userProfile.personalInformation.title')}</CardTitle>
			</CardHeader>
			<CardContent>
				<UserProfileForm
					onUserFormSubmit={updateUserMutation.mutate}
					defaultValues={userProfile?.data}
				>
					<div className="flex justify-end mt-2">
						<TKConfirmButton
							dialogType="update"
							isLoading={updateUserMutation.isLoading}
							data-testid="confirm-user-profile"
						/>
					</div>
				</UserProfileForm>
			</CardContent>
		</Card>
	);
}

export default UserPersonalInformation;
