import * as React from 'react';
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	MoreHorizontal,
} from 'lucide-react';

import { cn } from '@/lib/utils';
import { ButtonProps, buttonVariants } from '@/components/ui/button';
import { useTranslation } from 'react-i18next';

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
	<nav
		role="navigation"
		aria-label="pagination"
		className={cn('mx-auto flex w-full justify-center', className)}
		{...props}
	/>
);
Pagination.displayName = 'Pagination';

const PaginationContent = React.forwardRef<
	HTMLUListElement,
	React.ComponentProps<'ul'>
>(({ className, ...props }, ref) => (
	<ul
		ref={ref}
		className={cn('flex flex-row items-center gap-1', className)}
		{...props}
	/>
));
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = React.forwardRef<
	HTMLLIElement,
	React.ComponentProps<'li'>
>(({ className, ...props }, ref) => (
	<li ref={ref} className={cn('', className)} {...props} />
));
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
	isActive?: boolean;
} & Pick<ButtonProps, 'size'> &
	React.ComponentProps<'a'>;

const PaginationLink = ({
	className,
	isActive,
	size = 'icon',
	...props
}: PaginationLinkProps) => (
	<a
		aria-current={isActive ? 'page' : undefined}
		className={cn(
			buttonVariants({
				variant: isActive ? 'outline' : 'ghost',
				size,
			}),
			className,
		)}
		{...props}
	/>
);
PaginationLink.displayName = 'PaginationLink';

type PaginationButtonProps = {
	isActive?: boolean;
} & Pick<ButtonProps, 'size'> &
	React.ComponentProps<'button'>;

const PaginationButton = ({
	className,
	isActive,
	size = 'icon',
	...props
}: PaginationButtonProps) => (
	<button
		aria-current={isActive ? 'page' : undefined}
		className={cn(
			buttonVariants({
				variant: isActive ? 'outline' : 'ghost',
				size,
			}),
			className,
		)}
		{...props}
	/>
);
PaginationButton.displayName = 'PaginationButton';

function PaginationPreviousButton({
	className,
	...props
}: React.ComponentProps<typeof PaginationButton>) {
	const { t } = useTranslation();
	return (
		<PaginationButton
			aria-label={t('common.goToPreviousPage')}
			size="sm"
			className={cn('gap-1 pl-2.5', className)}
			{...props}
		>
			<ChevronLeftIcon className="h-4 w-4" />
			<span>{t('common.previous')}</span>
		</PaginationButton>
	);
}
PaginationPreviousButton.displayName = 'PaginationPreviousButton';

function PaginationNextButton({
	className,
	...props
}: React.ComponentProps<typeof PaginationButton>) {
	const { t } = useTranslation();
	return (
		<PaginationButton
			aria-label={t('common.goToNextPage')}
			size="sm"
			className={cn('gap-1 pr-2.5', className)}
			{...props}
		>
			<span>{t('common.next')}</span>
			<ChevronRightIcon className="h-4 w-4" />
		</PaginationButton>
	);
}
PaginationNextButton.displayName = 'PaginationNextButton';

function PaginationPrevious({
	className,
	...props
}: React.ComponentProps<typeof PaginationLink>) {
	const { t } = useTranslation();

	return (
		<PaginationLink
			aria-label={t('common.goToPreviousPage')}
			size="sm"
			className={cn('gap-1 pl-2.5', className)}
			{...props}
		>
			<ChevronLeftIcon className="h-4 w-4" />
			<span>{t('common.previous')}</span>
		</PaginationLink>
	);
}
PaginationPrevious.displayName = 'PaginationPrevious';

function PaginationNext({
	className,
	...props
}: React.ComponentProps<typeof PaginationLink>) {
	const { t } = useTranslation();

	return (
		<PaginationLink
			aria-label={t('common.goToNextPage')}
			size="sm"
			className={cn('gap-1 pr-2.5', className)}
			{...props}
		>
			<span>{t('common.next')}</span>
			<ChevronRightIcon className="h-4 w-4" />
		</PaginationLink>
	);
}
PaginationNext.displayName = 'PaginationNext';

function PaginationEllipsis({
	className,
	...props
}: React.ComponentProps<'span'>) {
	const { t } = useTranslation();

	return (
		<span
			aria-hidden
			className={cn('flex size-10 items-center justify-center', className)}
			{...props}
		>
			<MoreHorizontal className="h-4 w-4" />
			<span className="sr-only">{t('common.morePages')}</span>
		</span>
	);
}
PaginationEllipsis.displayName = 'PaginationEllipsis';

export {
	Pagination,
	PaginationContent,
	PaginationLink,
	PaginationItem,
	PaginationPrevious,
	PaginationNext,
	PaginationEllipsis,
	PaginationButton,
	PaginationNextButton,
	PaginationPreviousButton,
};
