import React, { useEffect, useMemo, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import MultistepElementsGenerator from './MultistepElementsGenerator';
import {
	getDataForCurrentStep,
	searchSelectableItems,
} from './multistepListCheckboxUtils';
import MultistepHeader from './MultistepHeader';
import { useDebounce } from '@/hooks/common/useDebounce';
import { usePrevious } from '@/hooks/common/usePrevious';
import MultistepFooter from './MultistepFooter';
import MultistepNoDataFound from './MultistepNoDataFound';

export type MultistepItem = {
	title?: string;
	value: string | number;
	label: string;

	/** Used for searching, if not passed in, label will be used as the only search term  */
	searchFields?: string[];
	category?: string;
	items?: MultistepItem[];
	active: boolean;
};

export type CheckboxList = {
	title: string;
	items: MultistepItem[];
};
type MultistepListCheckboxProps = {
	data: CheckboxList;
	handleSelect: (item: MultistepItem) => void;
	handleSelectAll?: () => void;
	handleDeselectAll?: () => void;
	noDataText?: string;
	noDataWithSearchText?: string;
	handleSelectCategory?: (
		category: string,
		hasCheckedSubItems: boolean,
	) => void;
	description?: string | React.ReactNode;
};

const MultistepListCheckbox: React.FC<MultistepListCheckboxProps> = ({
	data,
	handleSelect,
	noDataText,
	noDataWithSearchText,
	handleSelectAll,
	handleDeselectAll,
	handleSelectCategory,
	description,
}) => {
	const [steps, setSteps] = useState<number[]>([]);
	const [search, setSearch] = useState('');
	const debouncedSearch = useDebounce(search, 200);

	const prevDebouncedSearch = usePrevious(debouncedSearch);

	const currentStepData = useMemo(() => {
		if (debouncedSearch) {
			// setSteps([]);
			return searchSelectableItems(data.items, debouncedSearch);
		}

		return getDataForCurrentStep(data, steps);
	}, [data, steps, debouncedSearch]);

	useEffect(() => {
		if (debouncedSearch !== prevDebouncedSearch) {
			setSteps([]);
		}
	}, [debouncedSearch, prevDebouncedSearch]);

	const handleGoOneStepBehind = () => {
		setSteps((s) => s.slice(0, -1));
	};
	const handleAddStep = (index: number) => {
		setSteps((s) => [...s, index]);
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value);
	};

	return (
		<div className="flex flex-col h-full">
			<MultistepHeader
				data={data}
				handleGoOneStepBehind={handleGoOneStepBehind}
				handleSearch={handleSearch}
				search={search}
				steps={steps}
				description={description}
			/>
			<div className="h-[150px] md:h-[300px]">
				{currentStepData.length > 0 ? (
					<AutoSizer>
						{({ height, width }) => (
							<List
								height={height}
								itemCount={currentStepData.length}
								itemSize={32}
								width={width}
								itemData={{
									currentStepData,
									handleSelect,
									handleSelectCategory,
									handleAddStep,
									steps,
								}}
							>
								{Row}
							</List>
						)}
					</AutoSizer>
				) : (
					<MultistepNoDataFound
						noDataText={noDataText}
						search={search}
						noDataWithSearchText={noDataWithSearchText}
					/>
				)}
			</div>
			<MultistepFooter
				handleSelectAll={handleSelectAll}
				handleDeselectAll={handleDeselectAll}
			/>
		</div>
	);
};
export default MultistepListCheckbox;

const Row = ({
	index,
	style,
	data,
}: {
	index: number;
	style: React.CSSProperties;
	data: any;
}) => {
	const {
		currentStepData,
		handleSelect,
		handleSelectCategory,
		handleAddStep,
		steps,
	} = data;

	const item = currentStepData[index];

	return (
		<div style={style}>
			<MultistepElementsGenerator
				handleSelectCategory={handleSelectCategory}
				handleSelectElement={(item, checked) =>
					handleSelect({ ...item, active: checked })
				}
				handleAddStep={handleAddStep}
				steps={steps}
				list={[item]}
			/>
		</div>
	);
};
