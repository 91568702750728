import React from 'react';
import DateFilters from '@/components/FilterDropdowns/DateFilters/DateFilters';
import CompareFilters from '@/components/FilterDropdowns/CompareFilters/CompareFilters';
import EquipmentFilters from '@/components/FilterDropdowns/EquipmentFilters/EquipmentFilters';
import ProjectsFilters from '@/components/FilterDropdowns/ProjectsFilters/ProjectsFilters';
import DriversFilters from '@/components/FilterDropdowns/DriversFilters/DriversFilters';
import FilterDropdownsLayout from '@/components/FilterDropdowns/FilterDropdownsLayout';
import GroupFilters from '@/components/FilterDropdowns/GroupFilters/GroupFilter';
import { useAnalysisFilters } from '@/hooks/analysis/useAnalysisFilters';
import { useCompany } from '@/hooks/auth/useCompany';

interface Props {
	filtersHook: ReturnType<typeof useAnalysisFilters>;
}
function AnalysisFilters({ filtersHook }: Props) {
	const { filters, setFilters } = filtersHook;
	const company = useCompany();

	return (
		<FilterDropdownsLayout>
			<DateFilters
				variant="dark"
				presetType={filters.presetType}
				fromTime={filters.fromTime}
				toTime={filters.toTime}
				interval={filters.interval}
				presetAmount={filters.presetAmount}
				dateType={filters.dateType}
				handleChange={setFilters}
				showInterval
			/>
			{company.data?.featureEnableProjects && (
				<ProjectsFilters
					variant="dark"
					hideDataFromUnknownProjects={filters.hideDataFromUnknownProjects}
					projectIds={filters.projectIds}
					handleChange={setFilters}
				/>
			)}
			{company.data?.featureEnableGroups && (
				<GroupFilters
					variant="dark"
					hideDataFromWithoutGroups={filters.hideDataFromWithoutGroups}
					groupIds={filters.groupIds}
					handleChange={setFilters}
				/>
			)}
			<EquipmentFilters
				variant="dark"
				equipmentIds={filters.equipmentIds}
				handleChange={setFilters}
			/>
			<DriversFilters
				variant="dark"
				driverIds={filters.driverIds}
				hideDataFromUnknownDrivers={filters.hideDataFromUnknownDrivers}
				handleChange={setFilters}
			/>
			<CompareFilters
				variant="dark"
				groupDrivers={filters.groupDrivers}
				groupEquipment={filters.groupEquipment}
				groupProjects={filters.groupProjects}
				handleChange={setFilters}
			/>
		</FilterDropdownsLayout>
	);
}

export default AnalysisFilters;
