import React, { createContext, useContext, useEffect, useState } from 'react';
import { User } from 'firebase/auth';
import { firebaseAuth } from '@/lib/firebaseClient';
import { Role } from '@/types/common/roles';

interface AuthContextType {
	user: User | null;
	isLoading: boolean;
	companyId: number;
	companyUserId: number;
	roles: Role[];
	defaultRole: Role;
	handleSetClaims: (claims: TokenResult['claims']) => void;
	hasPermissions: (rolesToCheck: Role[]) => boolean;
	isManager: boolean;
	isOwner: boolean;
}

export type TokenResult = {
	claims: {
		allowedRoles: Role[];
		companyId?: string;
		companyUserId?: string;
		defaultRole: string;
	};
};

const AuthContext = createContext<AuthContextType>(null!);

interface Props {
	children: React.ReactNode | React.ReactNode[];
}

const AuthContextProvider = ({ children }: Props) => {
	const [user, setUser] = useState<User | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [companyId, setCompanyId] = useState<number | null>(null);
	const [roles, setRoles] = useState<Role[]>([]);
	const [defaultRole, setDefaultRole] = useState<Role>(Role.Unassigned);
	const [companyUserId, setCompanyUserId] = useState<number | null>(null);
	/**
	 * Parses a claims object and sets it into state
	 *
	 * @param {TokenResult['claims']} claims
	 */
	const handleSetClaims = async (claims: TokenResult['claims']) => {
		if (claims) {
			const _companyId = claims?.companyId ? Number(claims.companyId) : null;
			const _roles: Role[] = claims?.allowedRoles ?? [];
			const _defaultRole = (claims?.defaultRole as Role) ?? Role.Unassigned;
			const _companyUserId = claims?.companyUserId
				? Number(claims.companyUserId)
				: null;
			setCompanyId(_companyId);
			setCompanyUserId(_companyUserId);
			setRoles(_roles);
			setDefaultRole(_defaultRole);
		} else {
			setCompanyId(null);
			setCompanyUserId(null);
			setRoles([]);
			setDefaultRole(Role.Unassigned);
		}
	};

	// Retrieves the authenticated user information when it changes and sets it into a state, providing easy reactivity throughout the application to auth changes
	// It also sets the claims into state if they exist
	useEffect(() => {
		const unsubscribe = firebaseAuth.onAuthStateChanged(async (userInfo) => {
			const tokenResult = await userInfo?.getIdTokenResult();
			const claims = tokenResult?.claims as TokenResult['claims'];
			handleSetClaims(claims ?? null);
			setUser(userInfo);
			setIsLoading(false);
		});
		return () => unsubscribe();
	}, [user]);

	const hasPermissions = (rolesToCheck: Role[]) => {
		const userRoles: Role[] = roles || [Role.Unassigned];

		return userRoles.some((role: Role) => rolesToCheck.includes(role));
	};

	const isManager = hasPermissions([Role.Manager]);
	const isOwner = hasPermissions([Role.Owner]);

	const value: AuthContextType = {
		companyUserId: companyUserId!,
		handleSetClaims,
		companyId: companyId!,
		roles,
		user,
		isLoading,
		hasPermissions,
		defaultRole,
		isManager,
		isOwner,
	};
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error(
			'Make sure that there is an existing AuthContextProvider within the tree.',
		);
	}
	return context;
};

export { AuthContextProvider, useAuth };
