import React, { useState } from 'react';
import { HelpCircle, AlertTriangle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { TypographySmall } from '@/components/ui/typography';
import { cva, type VariantProps } from 'class-variance-authority';

const describeIconVariants = cva('p-0 size-7', {
	variants: {
		variant: {
			describe: '',
			warn: 'text-warning',
		},
	},
	defaultVariants: {
		variant: 'describe',
	},
});

type DescribeIconProps = VariantProps<typeof describeIconVariants> & {
	children: React.ReactNode;
	withText?: string;
};

const DescribeIcon: React.FC<DescribeIconProps> = ({
	children,
	withText,
	variant,
}) => {
	const [open, setOpen] = useState(false);

	const Icon = variant === 'warn' ? AlertTriangle : HelpCircle;

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger asChild>
				<Button
					variant="link"
					size="icon"
					className={describeIconVariants({ variant })}
					onMouseEnter={() => setOpen(true)}
					onMouseLeave={() => setOpen(false)}
				>
					<Icon className="h-4 w-4" />
					{withText && (
						<TypographySmall
							className={variant === 'warn' ? 'text-warning ml-1' : 'ml-1'}
						>
							{withText}
						</TypographySmall>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[280px]">{children}</PopoverContent>
		</Popover>
	);
};

export default DescribeIcon;
