import React, { useState } from 'react';
import { trpc } from '@/api/trpc';
import DateFilters, {
	DateFiltersValue,
} from '@/components/FilterDropdowns/DateFilters/DateFilters';
import FilterDropdownsLayout from '@/components/FilterDropdowns/FilterDropdownsLayout';
import ProjectsFilters, {
	ProjectsFiltersValue,
} from '@/components/FilterDropdowns/ProjectsFilters/ProjectsFilters';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import { useAuth } from '@/contexts/Global/AuthContext';
import { startOfDay, subMonths, endOfDay } from 'date-fns';
import { ReportInterval } from '@/hooks/analysis/useReportsTemplatesList';
import { useCompany } from '@/hooks/auth/useCompany';
import RenderPdf from '../RenderPdf';
import { calculateFilterDates } from '@/utils/analysis/calculateFilterDates';
import { useFormatAndDownloadFile } from '@/hooks/common/useFormatAndDownloadFile';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import { FileDown } from 'lucide-react';
import TKDataHandler from '@/components/Progress/TKDataHandler';
import GroupFilters, {
	type GroupFiltersValue,
} from '@/components/FilterDropdowns/GroupFilters/GroupFilter';

export default function CompanyEnvironmentReport() {
	const { t } = useTranslation();
	const formatAndDownload = useFormatAndDownloadFile();
	const company = useCompany();
	const { companyId } = useAuth();
	const [projects, setProjects] = useState<ProjectsFiltersValue>({
		hideDataFromUnknownProjects: false,
		projectIds: null,
	});

	const [groups, setGroups] = useState<GroupFiltersValue>({
		hideDataFromWithoutGroups: false,
		groupIds: null,
	});

	const [dateFilters, setDateFilters] = useState<DateFiltersValue>({
		fromTime: startOfDay(subMonths(new Date(), 3)).toISOString(),
		toTime: endOfDay(new Date()).toISOString(),
		presetAmount: 6,
		dateType: 'preset',
		presetType: 'lastMonths',
		interval: ReportInterval.NoInterval,
	});
	function handleSetDateFilters(value: Partial<DateFiltersValue>) {
		setDateFilters({ ...dateFilters, ...value });
	}
	const dateRange = calculateFilterDates(dateFilters);

	const pdf = trpc.report.generateCompanyEnvironmentReport.useQuery(
		{
			companyId,
			timeZone: Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone,
			fromTime: dateRange.fromTime,
			toTime: dateRange.toTime,
			groupIds: groups.groupIds,
			hideDataFromWithoutGroups: groups.hideDataFromWithoutGroups,
			projectIds: projects.projectIds,
			hideDataFromUnknownProjects: projects.hideDataFromUnknownProjects,
		},
		{ refetchOnWindowFocus: false, keepPreviousData: true },
	);

	return (
		<DashboardLayout
			disablePadding
			AppBarComponent={
				<div className="px-4 py-2 w-full flex justify-between">
					<FilterDropdownsLayout>
						{company.data?.featureEnableProjects && (
							<ProjectsFilters
								{...projects}
								handleChange={setProjects}
								variant="dark"
							/>
						)}
						<GroupFilters {...groups} handleChange={setGroups} variant="dark" />
						<DateFilters
							{...dateFilters}
							handleChange={handleSetDateFilters}
							variant="dark"
						/>
					</FilterDropdownsLayout>

					<div className="mt-1">
						<Button
							variant="default"
							disabled={!pdf.data?.base64Content || pdf.isLoading}
							onClick={() => {
								if (pdf.data?.base64Content) {
									formatAndDownload({
										content: pdf.data?.base64Content,
										date: new Date().toISOString(),
										extension: 'pdf',
										name: t(
											'analysis.reports.company_environment_report.title',
										),
									});
								}
							}}
						>
							<FileDown className="mr-1 h-4 w-4" />
							{t('analysis.download')} PDF
						</Button>
					</div>
				</div>
			}
		>
			<TKDataHandler
				loading={pdf.isInitialLoading}
				error={pdf.error}
				data={pdf.data}
				entryText={`${t(
					'analysis.reports.company_environment_report.title',
				)}`.toLowerCase()}
				showChildrenWhenNoData
			>
				<RenderPdf
					error={pdf.error}
					fileContent={pdf.data?.base64Content}
					isLoading={pdf.isFetching}
				/>
			</TKDataHandler>
		</DashboardLayout>
	);
}
