import { ReactComponent as DataError } from '@/assets/images/companies/undraw_towing_6yy4.svg';
import { TypographyMuted } from '@/components/ui/typography';
import { TKDataHandlerImageProps } from './TKDataHandler';

const TKDataError = ({ imageWidth, children }: TKDataHandlerImageProps) => {
	return (
		<div
			className="flex flex-col justify-center items-center h-full w-full"
			data-testid="data-error"
		>
			<div className={`w-[${imageWidth}] max-w-[400px] max-h-[40%] h-full`}>
				<DataError
					viewBox="0 0 1002.5009 516.22024"
					className="w-full h-full"
				/>
			</div>
			<TypographyMuted className="pt-2 text-center">{children}</TypographyMuted>
		</div>
	);
};

export default TKDataError;
