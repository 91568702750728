import React from 'react';
import { trpc } from '@/api/trpc';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import CompanyBaseCard from './CompanyBaseCard';

interface Props {
	onSelectCompany: (companyId: number) => void;
	selectedCompanyId: number | null;
	isLoginLoading: boolean;
	companyId: number;
	onEditCompany: (companyId: number) => Promise<void>;
	onDeleteCompany: (companyId: number, name: string) => Promise<void>;
}

function UserCompanyCard({
	companyId,
	onSelectCompany,
	selectedCompanyId,
	isLoginLoading,
	onEditCompany,
	onDeleteCompany,
}: Props): JSX.Element | null {
	const { t } = useTranslation();

	const company = trpc.company.byId.useQuery({ companyId });

	return (
		<CompanyBaseCard
			name={company.data?.name}
			role={company.data?.userRole}
			companyId={companyId}
			isTemplateCompany={company.data?.isTemplateCompany}
			isLoading={company.isLoading}
			isError={company.isError}
			onEditCompany={onEditCompany}
			onDeleteCompany={onDeleteCompany}
		>
			<Button
				className="w-full"
				data-company-id={companyId}
				onClick={() => onSelectCompany(companyId)}
				isLoading={isLoginLoading || selectedCompanyId === companyId}
			>
				{t('companies.selectButton')}
			</Button>
		</CompanyBaseCard>
	);
}

export default UserCompanyCard;
