import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/api/trpc';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import NotificationFormDialog from './NotificationFormDialog';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';
import { Button } from '@/components/ui/button';
import { MoreHorizontal, Plus } from 'lucide-react';
import { SysAdminSettingsLayout } from '@/components/Layouts/DashboardLayout/SysAdminSettingsLayout';
import { DataTable } from '@/components/Table/data-table';
import TKTimeTooltip from '@/components/Common/TKTimeTooltip';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { useDialog } from '@/contexts/Global/Dialog/DialogContext';
import { toast } from 'sonner';
import { CollapsedBadges } from '@/components/Badges/CollapsedBadges';

interface Notification {
	id: string;
	title: string;
	createdAt: string;
	type: 'info' | 'warn' | 'error';
	expiresAt: string | null;
	companies: { id: number; name: string }[];
	isGlobal: boolean;
}

function SysAdminNotifications() {
	const { t } = useTranslation();
	const dialog = useDialog();
	const deleteMutation = trpc.notification.deleteById.useMutation({
		onSuccess() {
			toast.success(
				t('common.api.delete.success', {
					type: t('sysAdmin.notifications.base'),
				}),
			);
		},
		onError(error) {
			toast.error(error.message);
		},
	});
	const notificationsQuery = trpc.notification.list.useQuery();

	const [createDialogOpen, setCreateDialogOpen] = useState(false);
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [selectedNotificationToUpdate, setSelectedNotificationToUpdate] =
		useState<Notification | undefined>(undefined);

	function handleUpdateEditDialog(open: boolean) {
		setEditDialogOpen(false);
		if (!open) {
			setSelectedNotificationToUpdate(undefined);
		}
	}

	function handleOpenDeleteDialog(row: Notification) {
		dialog({
			title: t('sysAdmin.notifications.deleteNotificationDialog.title'),
			description: t(
				'sysAdmin.notifications.deleteNotificationDialog.description',
				{
					name: selectedNotificationToUpdate?.title,
				},
			),
			dialogType: 'confirm',
			dataTestConfirm: 'confirm-delete-notification',
		}).then(() =>
			deleteMutation.mutate({
				id: row.id,
			}),
		);
	}

	return (
		<>
			<DashboardLayout noScroll disablePadding>
				<SysAdminSettingsLayout>
					<TKPageAppBar title={t('routes.sysAdminSettingsNotifications')}>
						<Button
							onClick={() => setCreateDialogOpen(true)}
							data-testid="open-create-notification-dialog"
						>
							<Plus className="mr-2 h-4 w-4" />
							{t('sysAdmin.notifications.createNewNotification')}
						</Button>
					</TKPageAppBar>

					<DataTable
						isLoading={notificationsQuery.isLoading}
						error={notificationsQuery.error}
						defaultSortColumn="createdAt"
						defaultSortOrder="desc"
						searchColumn="title"
						data={notificationsQuery.data ?? []}
						columns={[
							{
								id: 'title',
								title: t('sysAdmin.notifications.title'),
							},
							{
								id: 'createdAt',
								title: t('sysAdmin.notifications.createdAt'),
								render: (row) => <TKTimeTooltip timestamp={row.createdAt} />,
								enableSorting: true,
							},
							{
								id: 'type',
								title: t('sysAdmin.notifications.type'),
								render: (row) => {
									return <span>{t(`common.${row.type}`)}</span>;
								},
							},
							{
								id: 'isGlobal',
								title: t('sysAdmin.notifications.global'),
								render: (row) => {
									return (
										<span>
											{row.isGlobal ? (
												t('sysAdmin.notifications.global')
											) : (
												<CollapsedBadges
													variant="secondary"
													items={row.companies.map((company) => ({
														id: company.id.toString(),
														name: company.name,
													}))}
												/>
											)}
										</span>
									);
								},
							},
							{
								id: 'expiresAt',
								title: t('sysAdmin.notifications.expiresAt'),
								render: (row) => {
									if (!row.expiresAt) return null;
									return (
										<span
											className={cn(
												new Date(row.expiresAt).getTime() > new Date().getTime()
													? 'text-green-500'
													: 'text-red-500',
											)}
										>
											<TKTimeTooltip
												timestamp={row.expiresAt}
												displayFormat="Pp"
											/>
										</span>
									);
								},
								enableSorting: true,
							},

							{
								id: 'id',
								title: '',
								size: 40,
								render: (row) => (
									<NotificationActionMenu
										onEdit={() => {
											setSelectedNotificationToUpdate(row);
											setEditDialogOpen(true);
										}}
										onDelete={() => {
											handleOpenDeleteDialog(row);
										}}
									/>
								),
							},
						]}
					/>
				</SysAdminSettingsLayout>
			</DashboardLayout>

			<NotificationFormDialog
				dialogType="create"
				open={createDialogOpen}
				setOpen={setCreateDialogOpen}
			/>
			{selectedNotificationToUpdate && (
				<NotificationFormDialog
					dialogType="update"
					open={editDialogOpen}
					setOpen={handleUpdateEditDialog}
					defaultValues={selectedNotificationToUpdate}
				/>
			)}
		</>
	);
}
interface NotificationActionMenuProps {
	onEdit: () => void;
	onDelete: () => void;
}
function NotificationActionMenu({
	onEdit,
	onDelete,
}: NotificationActionMenuProps): JSX.Element {
	const { t } = useTranslation();

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="ghost"
					size="icon"
					className="group opacity-0 transition-all group-hover/row:opacity-100"
				>
					<MoreHorizontal className="h-4 w-4" />
					<span className="sr-only">Open menu</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end">
				<DropdownMenuItem onClick={onEdit}>{t('common.edit')}</DropdownMenuItem>
				<DropdownMenuItem onClick={onDelete}>
					{t('common.delete')}
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}

export default SysAdminNotifications;
