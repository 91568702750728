import React from 'react';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import TKDataHandler from '@/components/Progress/TKDataHandler';
import { DataTable } from '@/components/Table/data-table';
import TKTypedLink from '@/components/Common/TKTypedLink';
import CompanyProjectActions from './CompanyProjectActions';

function CompanyProjectsTable() {
	const { companyId } = useAuth();
	const { t } = useTranslation();

	const {
		isLoading,
		data: projects = [],
		error,
	} = trpc.project.list.useQuery({ companyId });

	return (
		<TKDataHandler
			loading={isLoading}
			error={error}
			entryText={t('company.projects.base', { count: 2 })}
			data={projects}
		>
			<DataTable
				columns={[
					{
						id: 'name',
						title: t('validations.projectName.label'),
						render: (project) => (
							<TKTypedLink
								to="/company-settings/projects/:id/rules"
								params={{ id: project.id }}
								data-testid="project-rule-link"
							>
								{project.name}
							</TKTypedLink>
						),
						enableSorting: true,
					},
					{
						id: 'id',
						title: ' ',
						render: (project) => (
							<CompanyProjectActions
								project={{
									id: project.id,
									name: project.name,
								}}
							/>
						),
					},
				]}
				data={projects}
				isLoading={isLoading}
				error={error}
				searchColumn="name"
				defaultSortColumn="name"
				defaultSortOrder="asc"
				paginationPageSize={100}
			/>
		</TKDataHandler>
	);
}

export default CompanyProjectsTable;
