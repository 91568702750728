import { useEffect, useRef } from 'react';

/**
 * Wrapper hook to allow usage of setInterval as a react hook.
 *
 * @param {() => any} callback
 * @param {number} delay
 */
const useInterval = (callback: () => any, delay: number | null) => {
	const savedCallback = useRef<any>(null);

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			if (savedCallback.current) {
				//@ts-ignore-next-line
				savedCallback.current();
			}
		}
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
};

export { useInterval };
