import React from 'react';
import { MoreHorizontal } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { useDialog } from '@/contexts/Global/Dialog/DialogContext';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
interface Props {
	id: string;
	email: string;
	companyId: number;
}

function EmailInvitationActionsMenu({
	id,
	email,
	companyId,
}: Props): JSX.Element {
	const { t } = useTranslation();
	const dialog = useDialog();

	const revokeMutation = trpc.invitation.revokeById.useMutation({
		async onSuccess() {
			toast.success(
				t('common.api.delete.success', {
					type: t('company.users.invitations.base'),
				}),
			);
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	function handleShowRevokeInvitationDialog() {
		dialog({
			title: t('company.users.revokeInvitationDialog.title'),
			description: t('company.users.revokeInvitationDialog.description', {
				email,
			}),
			dialogType: 'confirm',
			dataTestConfirm: 'confirm-revoke-user-invitation',
		}).then(() => revokeMutation.mutate({ id, companyId }));
	}

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					variant="ghost"
					size="icon"
					className="group opacity-0 transition-all group-hover/row:opacity-100"
					aria-label="more"
					data-testid={`company-user-invitation-${email}-actions`}
				>
					<MoreHorizontal className="h-4 w-4" />
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent>
				<DropdownMenuItem
					onClick={handleShowRevokeInvitationDialog}
					data-testid={`company-user-invitation-options-${email}-revoke-menu`}
				>
					{t('company.users.revokeUserInvitation')}
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}

export default EmailInvitationActionsMenu;
