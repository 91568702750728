import { useAuth } from '@/contexts/Global/AuthContext';
import { trpc } from '@/api/trpc';

interface Args {
	dashboardId: number;
	isEditable: boolean;
	canMoveWidgets: boolean;
}
function useUpdateReportsPosition({
	dashboardId,
	isEditable,
	canMoveWidgets,
}: Args) {
	const { companyId } = useAuth();
	const trpcContext = trpc.useUtils();

	const reportsListParams = { companyId, dashboardId };
	const updateReportsPositionMutation =
		trpc.analysisReport.updateReportsPositions.useMutation({
			async onMutate(variables) {
				const newReportsPositions = new Map(
					variables.newReportsPositions.map((item) => [item.id, item]),
				);
				// Cancel any outgoing re-fetches (so they don't overwrite our optimistic update)
				await trpcContext.analysisReport.list.cancel(reportsListParams);

				const previous =
					trpcContext.analysisReport.list.getData(reportsListParams);

				// Optimistically update to the new value
				trpcContext.analysisReport.list.setData(
					{ companyId, dashboardId },
					(reports = []) =>
						reports.map((report) => {
							const newReportPosition = newReportsPositions.get(report.id);
							if (newReportPosition) {
								return {
									...report,
									widgetXPosition:
										newReportPosition.widgetXPosition ?? report.widgetXPosition,
									widgetYPosition:
										newReportPosition.widgetYPosition ?? report.widgetYPosition,
								};
							}
							return report;
						}),
				);
				return previous!;
			},
			// If the mutation fails, use the context returned from onMutate to roll back
			async onError(error, variables, context) {
				trpcContext.analysisReport.list.setData(
					reportsListParams,
					context as never,
				);
			},
		});

	function handleUpdateReportsPosition(newLayout: ReactGridLayout.Layout[]) {
		// Only allow update position when dashboard is editable and the grid is unlocked
		if (canMoveWidgets && isEditable) {
			updateReportsPositionMutation.mutate({
				companyId,
				dashboardId,
				newReportsPositions: newLayout.map((item) => {
					return {
						id: parseInt(item.i),
						widgetXPosition: item.x,
						widgetYPosition: item.y,
					};
				}),
			});
		}
	}
	return { handleUpdateReportsPosition };
}

export { useUpdateReportsPosition };
