import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ClockIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Collapsible, CollapsibleContent } from '@/components/ui/collapsible';
import DateFiltersCustom from './DateFiltersCustom';
import FilterBase, { FilterBaseVariant } from '../FilterBase';
import DateSettingsPresets from './DateFiltersPresets';
import DateFiltersInterval from './DateFiltersInterval';
import { FiltersConfig } from '@/types/analysis';
import { useDateFns } from '@/hooks/common/useDateFns';

export type DateFiltersValue = Pick<
	FiltersConfig,
	| 'dateType'
	| 'toTime'
	| 'fromTime'
	| 'presetType'
	| 'presetAmount'
	| 'interval'
>;

export interface DateFiltersProps extends DateFiltersValue {
	handleChange: (dateFilters: Partial<DateFiltersValue>) => void;
	showInterval?: boolean;
}

function useDateFiltersTitle({
	fromTime,
	toTime,
	presetType,
	dateType,
	presetAmount,
}: DateFiltersValue) {
	const { format } = useDateFns();
	const { t } = useTranslation();

	const title = useMemo(() => {
		let text = t('filters.date.text') as string;

		switch (dateType) {
			case 'custom':
				if (fromTime && toTime) {
					text = `${format(new Date(fromTime), 'PP')} - ${format(
						new Date(toTime),
						'PP',
					)}`;
				}
				break;
			case 'preset':
				if (presetType) {
					text = t(`filters.date.periodPresets.${presetType}`, {
						count: presetAmount || undefined,
					});
				}
				break;
		}
		return text;
	}, [dateType, format, fromTime, presetAmount, presetType, t, toTime]);

	return title;
}

function DateFilters({
	dateType,
	toTime,
	fromTime,
	presetType,
	presetAmount,
	interval,
	handleChange,
	variant,
	showInterval = false,
}: DateFiltersProps & FilterBaseVariant) {
	const { t } = useTranslation();
	const title = useDateFiltersTitle({
		dateType,
		fromTime,
		toTime,
		presetType,
		presetAmount,
		interval,
	});

	function handleToggleDateType() {
		handleChange({
			dateType: dateType === 'preset' ? 'custom' : 'preset',
			toTime,
			fromTime,
			presetType,
			presetAmount,
			interval,
		});
	}

	return (
		<FilterBase
			variant={variant}
			iconButton={ClockIcon}
			buttonText={title}
			dataTestButton="open-date-filters-button"
			dataTestContent="date-filters-content"
		>
			<div className={`min-w-[${showInterval ? '300px' : '150px'}]`}>
				<Collapsible open={dateType === 'custom'}>
					<CollapsibleContent>
						<div className="flex justify-between">
							<DateFiltersCustom
								fromTime={fromTime}
								toTime={toTime}
								handleChange={handleChange}
							/>
							{showInterval && (
								<DateFiltersInterval
									interval={interval}
									handleChange={handleChange}
								/>
							)}
						</div>
					</CollapsibleContent>
				</Collapsible>
				<Collapsible open={dateType === 'preset'}>
					<CollapsibleContent>
						<div className="flex justify-between">
							<DateSettingsPresets
								presetAmount={presetAmount}
								presetType={presetType}
								handleChange={handleChange}
							/>
							{showInterval && (
								<DateFiltersInterval
									interval={interval}
									handleChange={handleChange}
								/>
							)}
						</div>
					</CollapsibleContent>
				</Collapsible>
			</div>
			<div className="flex justify-center mt-2">
				<Button variant="link" onClick={handleToggleDateType} type="button">
					{t(`filters.date.views.${dateType}`)}
				</Button>
			</div>
		</FilterBase>
	);
}

export default memo(DateFilters);
