import React, { useState, useEffect } from 'react';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import TKDrawer from '@/components/Drawer/TKDrawer';
import AnalysisSidebar from './Sidebar/AnalysisSidebar';
import AnalysisDashboard from './ContentArea/AnalysisDashboard';
import AnalysisLayout from './AnalysisLayout';
import { DrawerProvider } from '@/contexts/Global/Drawer/DrawerContext';
import { useTranslation } from 'react-i18next';
import { useAnalysisFilters } from '@/hooks/analysis/useAnalysisFilters';
import { useRouter } from '@/hooks/common/useRouter';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import TKDataHandler from '@/components/Progress/TKDataHandler';
import { createUrl } from '@/routes/routesList';

function AnalysisPage(): JSX.Element {
	const router = useRouter();
	const { t } = useTranslation();

	const { companyId } = useAuth();
	const filters = useAnalysisFilters();
	const queryParamId = Number(router.query.id);
	const [activeDashboardId, setActiveDashboardId] = useState<number | null>(
		queryParamId,
	);

	const {
		data: activeDashboard,
		isLoading,
		error,
	} = trpc.analysis.byId.useQuery(
		{
			id: activeDashboardId!,
			companyId,
		},
		{
			enabled: !isNaN(Number(activeDashboardId)),
			retry: false,
			onError(error) {
				// Redirect to analysis page if dashboard was not found
				if (error.shape?.data.code === 'NOT_FOUND') {
					setActiveDashboardId(null);
					router.push('/analysis/:id?', { 'id?': '' });
				}
			},
		},
	);

	const [canMoveWidgets, setCanMoveWidgets] = useState(false);

	function changeActiveDashboardId(id: number | null) {
		setActiveDashboardId(id);
		setCanMoveWidgets(false);
	}

	const dashboards = trpc.analysis.list.useQuery({
		companyId,
	});

	// Set active dashboard to first dashboard in list if no dashboard is active
	useEffect(() => {
		if (dashboards.data && !activeDashboardId) {
			setActiveDashboardId(dashboards.data?.[0].id);
		}
	}, [dashboards, activeDashboardId, setActiveDashboardId]);

	// Update URL when active dashboard change
	useEffect(() => {
		window.history.replaceState(
			null,
			'',
			createUrl('/analysis/:id?', {
				'id?': activeDashboardId || '',
			}),
		);
	}, [activeDashboardId]);

	return (
		<>
			<DashboardLayout disablePadding>
				<DrawerProvider>
					<div className="flex h-full w-full overflow-hidden">
						<TKDrawer hideToggleOnDesktop>
							<AnalysisSidebar
								isLoading={dashboards.isLoading}
								activeDashboardId={activeDashboardId}
								setActiveDashboardId={changeActiveDashboardId}
								dashboards={dashboards.data}
							/>
						</TKDrawer>
						<div className="w-full">
							<AnalysisLayout
								filtersHook={filters}
								canMoveWidgets={canMoveWidgets}
								isEditable={activeDashboard?.isEditable || false}
								setCanMoveWidgets={setCanMoveWidgets}
								activeDashboardId={activeDashboardId}
							>
								<TKDataHandler
									loading={isLoading || dashboards.isLoading}
									data={activeDashboard}
									error={error || dashboards.error}
									entryText={t('analysis.base')}
								>
									<AnalysisDashboard
										activeDashboardId={activeDashboardId!}
										canMoveWidgets={canMoveWidgets}
										filters={filters.filters}
									/>
								</TKDataHandler>
							</AnalysisLayout>
						</div>
					</div>
				</DrawerProvider>
			</DashboardLayout>
		</>
	);
}

export default AnalysisPage;
