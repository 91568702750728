import React from 'react';
import TKTextWithLabel from '@/components/Common/TKTextWithLabel';
import { useTranslation } from 'react-i18next';

interface Props {
	drivingDistanceMeter?: number | null;
	sampledAt?: string | null;
}

function DistanceDrivenField({ drivingDistanceMeter, sampledAt }: Props) {
	const { t, i18n } = useTranslation();

	const formatText = (meter: number) => {
		return Math.floor(meter / 1000).toLocaleString(i18n.language);
	};

	const text =
		typeof drivingDistanceMeter === 'number'
			? `${formatText(drivingDistanceMeter)} ${t(
					'common.unit.kilometer.short',
				)}`
			: t('common.notAvailable');

	return (
		<TKTextWithLabel
			label={t('equipment.columns.distanceDriven')}
			timestamp={sampledAt}
			text={text}
		/>
	);
}

export default DistanceDrivenField;
