import React from 'react';
import { CheckCircle, AlertTriangle, XCircle, Circle } from 'lucide-react';
import { Spinner } from '@/components/ui/spinner';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';

interface Props {
	configId: number;
	enabled?: boolean;
}

function IntegrationStatusIcons({
	configId,
	enabled,
}: Props): JSX.Element | null {
	const { companyId } = useAuth();
	const statusQuery = trpc.integration.statusHistoryById.useQuery(
		{ configId, companyId },
		{
			enabled: !!enabled,
			staleTime: 5000,
		},
	);
	const statusHistory = statusQuery.data ?? [];

	const errorsCount = statusHistory.reduce((acc, value) => {
		return value.hasError ? acc + 1 : acc;
	}, 0);

	// Disabled icon
	if (!enabled) {
		return <Circle className="text-muted-foreground w-5 h-5 mr-1" />;
	} else if (statusHistory.length === 0 || statusQuery.isLoading) {
		// Loading
		return <Spinner className="w-5 h-5 mr-1" />;
	} else if (errorsCount === 0) {
		// Success icon
		return <CheckCircle className="w-5 h-5 mr-1 text-green-500" />;
	} else if (errorsCount === statusHistory.length || statusQuery.isError) {
		return <XCircle className="w-5 h-5 mr-1 text-red-500" />;
	} else if (errorsCount > 0) {
		return <AlertTriangle className="w-5 h-5 mr-1 text-yellow-500" />;
	} else {
		return null;
	}
}

export default IntegrationStatusIcons;
