import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import {
	Form,
	FormField,
	FormItem,
	FormLabel,
	FormControl,
	FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { EMAIL_REGEX, URL_REGEX } from '@/utils/common/validators';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import TKPhoneNumber from '@/components/FormControls/TKPhoneNumber';

interface CompanyForm {
	name: string;
	address: string | null;
	email: string | null;
	phone: string | null;
	prefix: string | null;
	website: string | null;
	contactPerson: string | null;
	organizationNo: string;
	isTemplateCompany: boolean;
	featureEnableProjects: boolean;
	featureEnableGroups: boolean;
}

interface Props {
	isOpen: boolean;
	setOpen: (open: boolean) => void;
}

function CreateCompanyFormDialog({ isOpen, setOpen }: Props): JSX.Element {
	const { t } = useTranslation();

	const form = useForm<CompanyForm>({
		defaultValues: {
			isTemplateCompany: false,
			featureEnableProjects: true,
			featureEnableGroups: true,
			prefix: '+47',
		},
	});
	const closeDialog = () => setOpen(false);

	const insertMutation = trpc.company.insert.useMutation({
		async onSuccess() {
			setOpen(false);
			toast.success(
				t('common.api.insert.success', { type: t('company.base') }),
			);
			form.reset();
		},
		onError(error) {
			toast.error(error?.message);
		},
	});

	return (
		<Dialog open={isOpen} onOpenChange={setOpen}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{t(`company.createCompanyDialog.title`)}</DialogTitle>
				</DialogHeader>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit((data) => {
							insertMutation.mutate({
								isTemplateCompany: data.isTemplateCompany,
								name: data.name,
								organizationNo: data.organizationNo,
								featureEnableProjects: data.featureEnableProjects,
								featureEnableGroups: data.featureEnableGroups,
							});
						})}
					>
						<div className="space-y-4">
							<FormField
								control={form.control}
								name="isTemplateCompany"
								render={({ field }) => (
									<FormItem className="flex flex-row items-start space-x-3 space-y-0">
										<FormControl>
											<Checkbox
												checked={field.value}
												onCheckedChange={field.onChange}
											/>
										</FormControl>
										<FormLabel>{t('company.templateCompany')}</FormLabel>
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="featureEnableProjects"
								render={({ field }) => (
									<FormItem className="flex flex-row items-start space-x-3 space-y-0">
										<FormControl>
											<Checkbox
												checked={field.value}
												onCheckedChange={field.onChange}
											/>
										</FormControl>
										<FormLabel>{t('company.projects.title')}</FormLabel>
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="featureEnableGroups"
								render={({ field }) => (
									<FormItem className="flex flex-row items-start space-x-3 space-y-0">
										<FormControl>
											<Checkbox
												checked={field.value}
												onCheckedChange={field.onChange}
											/>
										</FormControl>
										<FormLabel>{t('company.groups.title')}</FormLabel>
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="name"
								rules={{ required: t('validations.companyName.required') }}
								render={({ field }) => (
									<FormItem>
										<FormLabel>{t('validations.companyName.label')}</FormLabel>
										<FormControl>
											<Input {...field} autoComplete="off" />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="organizationNo"
								rules={{
									required: t('validations.organizationNumber.required'),
									pattern: {
										value: /^\S*$/,
										message: t('validations.organizationNumber.pattern'),
									},
								}}
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t('validations.organizationNumber.label')}
										</FormLabel>
										<FormControl>
											<Input {...field} autoComplete="off" />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="contactPerson"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t('validations.contactPerson.label')}
										</FormLabel>
										<FormControl>
											<Input
												{...field}
												data-testid="company-contact-person-input"
												autoComplete="name"
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<TKPhoneNumber
								phoneValue={form.getValues('phone')}
								prefixValue={form.getValues('prefix')}
								control={form.control}
								errors={form.formState.errors}
								phoneName="phone"
								prefixName="prefix"
								required={false}
							/>
							<FormField
								control={form.control}
								name="address"
								render={({ field }) => (
									<FormItem>
										<FormLabel>{t('validations.address.label')}</FormLabel>
										<FormControl>
											<Input {...field} autoComplete="address-line1" />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="website"
								rules={{
									pattern: {
										value: URL_REGEX,
										message: t('validations.website.pattern'),
									},
								}}
								render={({ field }) => (
									<FormItem>
										<FormLabel>{t('validations.website.label')}</FormLabel>
										<FormControl>
											<Input {...field} autoComplete="url" />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								control={form.control}
								name="email"
								rules={{
									pattern: {
										value: EMAIL_REGEX,
										message: t('validations.email.pattern'),
									},
								}}
								render={({ field }) => (
									<FormItem>
										<FormLabel>{t('validations.email.label')}</FormLabel>
										<FormControl>
											<Input {...field} autoComplete="email" />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<DialogFooter className="mt-6">
							<Button variant="outline" onClick={closeDialog}>
								{t('common.cancelText')}
							</Button>
							<TKConfirmButton
								dialogType="create"
								isLoading={insertMutation.isLoading}
								disabled={insertMutation.isLoading}
								data-testid="confirm-company-settings-button"
							/>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
}

export default CreateCompanyFormDialog;
