import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import EquipmentSharingAddDialog from '@/dialogs/equipment/EquipmentSharingAddDialog';
import EquipmentSharingEditDialog from '@/dialogs/equipment/EquipmentSharingEditDialog';
import EquipmentSharingTimeline from './EquipmentSharingTimeline';

type EquipmentSharingTabProps = {
	equipmentId: string;
};

const EquipmentSharingTab: React.FC<EquipmentSharingTabProps> = ({
	equipmentId,
}) => {
	const { t } = useTranslation();

	const [addDialogOpen, setAddDialogOpen] = useState(false);
	const [editSharingRuleId, setEditSharingRuleId] = useState<string | null>(
		null,
	);

	return (
		<>
			<div className="flex flex-col">
				<div className="flex justify-end mb-4">
					<Button variant="outline" onClick={() => setAddDialogOpen(true)}>
						{t('equipment.equipmentSharingTab.addNewSharingPeriod')}
					</Button>
				</div>
				<EquipmentSharingTimeline
					equipmentId={equipmentId}
					onSharingRuleClicked={(id) => setEditSharingRuleId(id)}
				/>
			</div>

			<EquipmentSharingAddDialog
				equipmentId={equipmentId}
				isOpen={addDialogOpen}
				closeDialog={() => setAddDialogOpen(false)}
			/>

			{editSharingRuleId && (
				<EquipmentSharingEditDialog
					sharingRuleId={editSharingRuleId}
					isOpen={!!editSharingRuleId}
					closeDialog={() => setEditSharingRuleId(null)}
				/>
			)}
		</>
	);
};

export default EquipmentSharingTab;
