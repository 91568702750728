import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouterOutputs, trpc } from '@/api/trpc';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';
import { Button } from '@/components/ui/button';
import { MoreHorizontal } from 'lucide-react';
import { SysAdminSettingsLayout } from '@/components/Layouts/DashboardLayout/SysAdminSettingsLayout';
import { DataTable } from '@/components/Table/data-table';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { CollapsedBadges } from '@/components/Badges/CollapsedBadges';
import RemoteConfigFormDialog from './RemoteConfigFormDialog';
type Config = RouterOutputs['remoteConfig']['sysAdminList'][number];

function SysAdminRemoteConfig() {
	const { t } = useTranslation();

	const remoteConfigQuery = trpc.remoteConfig.sysAdminList.useQuery();
	const [editConfigDialog, setEditConfigDialog] = useState<Config | undefined>(
		undefined,
	);

	function handleOnEdit(row: Config) {
		setEditConfigDialog(row);
	}
	function handleCloseEditConfigDialog() {
		setEditConfigDialog(undefined);
	}

	return (
		<>
			<DashboardLayout noScroll disablePadding>
				<SysAdminSettingsLayout>
					<TKPageAppBar
						title={t('routes.sysAdminSettingsRemoteConfig')}
					></TKPageAppBar>

					<DataTable
						isLoading={remoteConfigQuery.isLoading}
						error={remoteConfigQuery.error}
						defaultSortColumn="parameter"
						defaultSortOrder="asc"
						searchColumn="parameter"
						data={remoteConfigQuery.data ?? []}
						columns={[
							{
								id: 'parameter',
								title: t('sysAdmin.remoteConfig.parameter'),
								render: (row) =>
									t(`sysAdmin.remoteConfig.types.${row.parameter}`),
							},
							{
								id: 'companies',
								title: t('sysAdmin.remoteConfig.companies'),
								render: (row) => {
									if (row.companies.length === 0) return '-';
									return (
										<CollapsedBadges
											items={row.companies}
											collapseAt={4}
											variant="secondary"
										/>
									);
								},
								enableSorting: true,
							},
							{
								id: 'parameter',
								size: 40,
								title: '',
								render: (row) => (
									<DropdownMenu>
										<DropdownMenuTrigger asChild>
											<Button
												variant="ghost"
												size="icon"
												className="group opacity-0 transition-all group-hover/row:opacity-100"
											>
												<MoreHorizontal className="h-4 w-4" />
												<span className="sr-only">Open menu</span>
											</Button>
										</DropdownMenuTrigger>
										<DropdownMenuContent align="end">
											<DropdownMenuItem onClick={() => handleOnEdit(row)}>
												{t('common.edit')}
											</DropdownMenuItem>
										</DropdownMenuContent>
									</DropdownMenu>
								),
							},
						]}
					/>
				</SysAdminSettingsLayout>
			</DashboardLayout>

			{editConfigDialog && (
				<RemoteConfigFormDialog
					open={!!editConfigDialog}
					setOpen={handleCloseEditConfigDialog}
					values={editConfigDialog}
				/>
			)}
		</>
	);
}

export default SysAdminRemoteConfig;
