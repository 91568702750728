import { isAfter } from 'date-fns';
import { useDateFns } from '@/hooks/common/useDateFns';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useTranslation } from 'react-i18next';
import { useExportDataTable } from '@/hooks/common/useExportDataTable';
import TKWidgetHeader from '@/components/WidgetSystem/TKWidgetHeader';
import TKTypedLink from '@/components/Common/TKTypedLink';
import WidgetDataHandler from '@/components/WidgetSystem/WidgetDataHandler';
import HomepageWidgetHeader from '../HomepageWidgetHeader';
import TKCustomIcons from '@/components/Common/TKCustomIcons';
import TKChartContainer from '@/components/Charts/TKChartContainer';
import { DataTable } from '@/components/Table/data-table';
import { HomepageWidgetBaseCard } from '../HomepageWidgetBaseCard';

function SVNextPeriodicInspectionReport(): JSX.Element {
	const { companyId } = useAuth();
	const { t } = useTranslation();
	const { format } = useDateFns();

	const {
		data = [],
		isLoading,
		error,
	} = trpc.widget.executeNextPeriodicInspection.useQuery({ companyId });

	function formatDeadlineText(timestamp: string) {
		return format(new Date(timestamp), 'PPPP');
	}

	const title = t('analysis.reports.sv_next_periodic_inspection.title');
	const columnVehicleText = t(
		'analysis.reports.sv_next_periodic_inspection.equipment',
	);

	const { exportToCsv, exportToExcel } = useExportDataTable(
		data,
		[
			{
				id: 'displayName',
				title: columnVehicleText,
			},
			{
				id: 'nextInspectionDeadline',
				title: t('analysis.reports.sv_next_periodic_inspection.deadline'),
				render: (row) => format(new Date(row.nextInspectionDeadline), 'P'),
			},
		],
		`${title}_${format(new Date(), 'Pp')}`,
	);

	return (
		<HomepageWidgetBaseCard>
			<TKWidgetHeader title={title}>
				<HomepageWidgetHeader
					handleExportToCsv={exportToCsv}
					handleExportToExcel={exportToExcel}
					loading={isLoading}
				/>
			</TKWidgetHeader>

			<WidgetDataHandler data={data} loading={isLoading} error={error}>
				<TKChartContainer>
					<DataTable
						isLoading={isLoading}
						error={error}
						defaultSortColumn="nextInspectionDeadline"
						defaultSortOrder="asc"
						wrapperClassName="p-0 border-none"
						columns={[
							{
								id: 'displayName',
								title: columnVehicleText,
								render: (row) => (
									<div className="flex items-center gap-2">
										<TKCustomIcons iconType={row.type} />
										<TKTypedLink
											to="/equipment/:id"
											params={{ id: row.equipmentId }}
										>
											{row.displayName}
										</TKTypedLink>
									</div>
								),
							},
							{
								id: 'nextInspectionDeadline',
								title: t(
									'analysis.reports.sv_next_periodic_inspection.deadline',
								),
								enableSorting: true,
								render: (row) => {
									const deadline = new Date(row.nextInspectionDeadline);
									const now = new Date();
									const isOverDeadline = isAfter(now, deadline);
									const deadlineText = formatDeadlineText(
										row.nextInspectionDeadline,
									);
									return (
										<span
											className={
												isOverDeadline ? 'text-destructive' : 'text-success'
											}
										>
											{deadlineText}
										</span>
									);
								},
							},
						]}
						data={data}
					/>
				</TKChartContainer>
			</WidgetDataHandler>
		</HomepageWidgetBaseCard>
	);
}

export default SVNextPeriodicInspectionReport;
