import React from 'react';
import { useAuth } from '@/contexts/Global/AuthContext';
import { Route as RouteComponent, RouteProps } from 'react-router-dom';

import NotAllowed from '@/views/Common/NotAllowed';
import FullLoadingPage from '@/components/Progress/FullLoadingPage';

import { Route } from '@/types/common/routes';
import TKTypedRedirect from '@/components/Common/TKTypedRedirect';
import { useFirebaseChecks } from '@/hooks/auth/useFirebaseChecks';
import { useUserProfile } from '@/hooks/auth/useUserProfile';
import { Role } from '@/types/common/roles';
import { useDocumentTitle } from '@/hooks/common/useDocumentTitle';
import { useTranslation } from 'react-i18next';
import Maintenance from '@/views/Common/Maintenance';
import { trpc } from '@/api/trpc';
type TKRouteProps = RouteProps & Route;

/**
 * React component generated using routes configuration list
 */
const TKRoute: React.FC<TKRouteProps> = (page) => {
	const PageComponent = page.pageComponent;

	const healthzQuery = trpc.healthz.check.useQuery(undefined, {
		retry: 3,
	});
	const auth = useAuth();
	const { t } = useTranslation();

	// Update tab title
	useDocumentTitle(page.routeKey ? t(`routes.${page.routeKey}`) : null);
	const userProfile = useUserProfile();

	const isLoading =
		healthzQuery.isLoading || auth.isLoading || userProfile.isInitialLoading;

	const { isLogged, emailVerified, hasProfileFinished } = useFirebaseChecks();

	if (isLoading) {
		return <RouteComponent {...page} render={() => <FullLoadingPage />} />;
	} else if (healthzQuery.isError) {
		return <RouteComponent {...page} render={() => <Maintenance />} />;
	} else {
		const hasPermissionForPage = auth.hasPermissions(page.allowedRoles);
		const hasSuperuserPermissions =
			page.allowSuperuser && userProfile.data?.isSuperuser;
		const hasSuperuserIfCompanyPermissions =
			page.allowSuperuserIfCompany && !!auth?.companyId;
		const hasUnassignedPermissions =
			page.allowUnassignedRole && auth.defaultRole === Role.Unassigned;

		// Always render pages that do not require authentication
		if (page.allowNonAuthenticated) {
			return (
				<RouteComponent
					{...page}
					render={(props) => {
						return <PageComponent {...props} />;
					}}
				/>
			);
		}

		// Force redirect to login page if user is not logged in and page requires auth
		if (!isLogged && !page.allowNonAuthenticated) {
			return <TKTypedRedirect to="/auth-login" params={{}} />;
		}

		// Redirect to sign up page which includes email verification
		if (auth.user && !emailVerified) {
			return <TKTypedRedirect to="/auth-signup" params={{}} />;
		}

		// Redirect to profile page if user has not finished profile
		if (auth.user && emailVerified && !hasProfileFinished) {
			return <TKTypedRedirect to="/auth-finish-profile" params={{}} />;
		}

		const isAllowed =
			hasPermissionForPage ||
			hasSuperuserPermissions ||
			hasSuperuserIfCompanyPermissions ||
			hasUnassignedPermissions;

		// Is allowed to access TKD, but is not logged in to a company
		if (!isAllowed && !auth.companyId) {
			return <TKTypedRedirect to="/companies" params={{}} />;
		}

		// Is not allowed to access the page of the company he is logged in
		if (!isAllowed && auth.companyId) {
			return <NotAllowed />;
		}

		return (
			<RouteComponent
				{...page}
				render={(props) => {
					return <PageComponent {...props} />;
				}}
			/>
		);
	}
};

export default TKRoute;
