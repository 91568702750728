import React from 'react';
import { useTranslation } from 'react-i18next';
import TKTextWithLabel from '@/components/Common/TKTextWithLabel';
import { useDateFns } from '@/hooks/common/useDateFns';
import { CalendarCheck } from 'lucide-react';
import { useAuth } from '@/contexts/Global/AuthContext';
import { trpc } from '@/api/trpc';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

interface Props {
	equipmentId: string;
}

function PeriodicControlSection({ equipmentId }: Props) {
	const { t } = useTranslation();
	const { format } = useDateFns();
	const { companyId } = useAuth();

	const query = trpc.statensVegvesen.periodicInspection.useQuery({
		companyId,
		equipmentId,
	});
	const data = query.data;

	return (
		<Card>
			<CardHeader>
				<CardTitle className="flex items-center gap-2">
					<CalendarCheck />
					{t('equipment.columns.periodicVehicleControl')}
				</CardTitle>
			</CardHeader>
			<CardContent className="space-y-4 flex flex-col">
				{' '}
				<TKTextWithLabel
					label={t('equipment.columns.timeOfLastPKK')}
					text={
						data?.lastPkk
							? format(new Date(data?.lastPkk), 'P')
							: t('common.notAvailable')
					}
				/>
				<TKTextWithLabel
					label={t('equipment.columns.timeOfNextPKK')}
					text={
						data?.nextPkkDeadline
							? format(new Date(data?.nextPkkDeadline), 'P')
							: t('common.notAvailable')
					}
				/>
			</CardContent>
		</Card>
	);
}

export default PeriodicControlSection;
