import { useState } from 'react';

type UseLocalStorage = <TVal>(
	key: string,
	initialValue: TVal,
) => [TVal, (value: TVal | ((value: TVal) => void)) => void];

/**
 * Local storage hook wrapper.
 *
 * @template T
 * @param {string} key local storage key
 * @param {T} initialValue local storage initial value
 * @return {*}
 */
const useLocalStorage: UseLocalStorage = <TVal>(
	key: string,
	initialValue: TVal,
) => {
	// State to store our value

	// Pass initial state function to useState so logic is only executed once

	const [storedValue, setStoredValue] = useState(() => {
		try {
			// Get from local storage by key

			const item = window.localStorage.getItem(key);

			// Parse stored json or if none return initialValue

			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			// If error also return initialValue

			return initialValue;
		}
	});

	// Return a wrapped version of useState's setter function that ...

	// ... persists the new value to localStorage.

	const setValue = (value: TVal | ((value: TVal) => void)) => {
		try {
			// Allow value to be a function so we have same API as useState

			const valueToStore =
				value instanceof Function ? value(storedValue) : value;

			// Save state

			setStoredValue(valueToStore);

			// Save to local storage

			window.localStorage.setItem(key, JSON.stringify(valueToStore));
		} catch (error) {
			// A more advanced implementation would handle the error case
		}
	};

	return [storedValue, setValue];
};

export { useLocalStorage };
