import { trpc } from '@/api/trpc';
import { Button } from '@/components/ui/button';
import { TypographySmall } from '@/components/ui/typography';
import { cn } from '@/lib/utils';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { XIcon } from 'lucide-react';
import { AlertCircleIcon, AlertTriangleIcon, InfoIcon } from 'lucide-react';

export function NotificationBanner() {
	const [autoAnimateRef] = useAutoAnimate();
	const utils = trpc.useUtils();

	const ackNotification = trpc.notification.acknowledgeById.useMutation({
		// Optimistically update the cache
		onMutate: async (acknowledgedNotification) => {
			await utils.notification.listNonAcknowledged.cancel();
			const previousNotifications =
				utils.notification.listNonAcknowledged.getData();
			utils.notification.listNonAcknowledged.setData(undefined, (old) =>
				old
					? old.filter(
							(notification) => notification.id !== acknowledgedNotification.id,
						)
					: [],
			);
			return { previousNotifications };
		},
		onError: (_err, _newNotification, context) => {
			utils.notification.listNonAcknowledged.setData(
				undefined,
				context?.previousNotifications,
			);
		},
		onSettled: () => {
			utils.notification.listNonAcknowledged.invalidate();
		},
	});

	const notifications = trpc.notification.listNonAcknowledged.useQuery(
		undefined,
		{
			refetchInterval: 1000 * 60 * 5, // get latest notifications every 5 minutes
		},
	);
	const mostRecentNotification = notifications.data?.[0];

	return (
		<div ref={autoAnimateRef}>
			{mostRecentNotification && (
				<div
					key={mostRecentNotification.id}
					className={cn(
						'flex items-center justify-between p-2 border-b-2',
						mostRecentNotification.type === 'info' &&
							'bg-blue-100 text-blue-800 border-blue-200',
						mostRecentNotification.type === 'warn' &&
							'bg-yellow-100 text-yellow-800 border-yellow-200',
						mostRecentNotification.type === 'error' &&
							'bg-red-100 text-red-800 border-red-200',
					)}
				>
					<div className="flex items-center">
						{mostRecentNotification.type === 'info' && (
							<InfoIcon className="size-4 mr-2" />
						)}
						{mostRecentNotification.type === 'warn' && (
							<AlertTriangleIcon className="size-4 mr-2" />
						)}
						{mostRecentNotification.type === 'error' && (
							<AlertCircleIcon className="size-4 mr-2" />
						)}
						<TypographySmall>{mostRecentNotification.title}</TypographySmall>
					</div>
					<Button variant="link" size="icon" className="p-0.5 size-5">
						<XIcon
							className="size-5 cursor-pointer"
							onClick={() => {
								ackNotification.mutate({
									id: mostRecentNotification.id,
								});
							}}
						/>
					</Button>
				</div>
			)}
		</div>
	);
}
