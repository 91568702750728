import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import {
	Form,
	FormField,
	FormItem,
	FormLabel,
	FormControl,
	FormMessage,
	FormDescription,
} from '@/components/ui/form';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import TKDatePicker from '@/components/FormControls/TKDatePicker';
import { TimePicker } from '@/components/ui/time-picker-input';
import { endOfDay } from 'date-fns';
import { Switch } from '@/components/ui/switch';
import { SelectMultipleCompanies } from '@/components/FormControls/MultipleSelect';

interface Props {
	open: boolean;
	setOpen: (open: boolean) => void;
	dialogType: 'create' | 'update';
	defaultValues?: Notification;
}

interface Notification {
	id: string;
	title: string;
	companies: { id: number; name: string }[];
	isGlobal: boolean;
	type: 'info' | 'warn' | 'error';
	expiresAt: string | null;
}

interface NotificationFormData {
	id: string;
	title: string;
	companyIds: number[];
	isGlobal: boolean;
	type: 'info' | 'warn' | 'error';
	expiresAt: string | null;
}

function NotificationFormDialog({
	dialogType,
	defaultValues,
	open,
	setOpen,
}: Props) {
	const { t } = useTranslation();
	const form = useForm<NotificationFormData>({
		defaultValues: defaultValues ?? {
			type: 'info',
			isGlobal: true,
		},
		values: defaultValues
			? {
					...defaultValues,
					companyIds: defaultValues.companies.map((company) => company.id),
				}
			: undefined,
	});

	const createMutation = trpc.notification.insert.useMutation({
		onSuccess() {
			toast.success(
				t(`common.api.insert.success`, {
					type: t('sysAdmin.notifications.base'),
				}),
			);
			form.reset({
				id: '',
				title: '',
				companyIds: [],
				type: 'info',
				expiresAt: null,
			});
			setOpen(false);
		},
		onError(error) {
			toast.error(error.message);
		},
	});
	const updateMutation = trpc.notification.updateById.useMutation({
		onSuccess() {
			toast.success(
				t(`common.api.update.success`, {
					type: t('sysAdmin.notifications.base'),
				}),
			);
			form.reset({
				id: '',
				title: '',
				companyIds: [],
				type: 'info',
				expiresAt: null,
			});
			setOpen(false);
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	const isLoading = createMutation.isLoading || updateMutation.isLoading;

	return (
		<Dialog
			open={open}
			onOpenChange={(newOpenState) => {
				setOpen(false);
				if (!newOpenState) {
					form.reset({
						id: '',
						title: '',
						companyIds: [],
						type: 'info',
						expiresAt: null,
					});
				}
			}}
		>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{dialogType === 'create'
							? t('sysAdmin.notifications.createNewNotification')
							: t('sysAdmin.notifications.editNotification')}
					</DialogTitle>
				</DialogHeader>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit((data) => {
							if (dialogType === 'create') {
								createMutation.mutate({
									title: data.title,
									type: data.type,
									expiresAt: data.expiresAt,
									isGlobal: data.isGlobal,
									companyIds: data.companyIds,
								});
							} else if (dialogType === 'update' && defaultValues?.id) {
								updateMutation.mutate({
									id: defaultValues.id,
									title: data.title,
									type: data.type,
									expiresAt: data.expiresAt,
									isGlobal: data.isGlobal,
									companyIds: data.companyIds,
								});
							}
						})}
					>
						<div className="space-y-4">
							<FormField
								control={form.control}
								name="isGlobal"
								render={({ field }) => (
									<FormItem className="flex flex-row items-center justify-between">
										<div className="space-y-0.5">
											<FormLabel className="text-base">
												{t('sysAdmin.notifications.global')}
											</FormLabel>
											<FormDescription>
												{t('sysAdmin.notifications.globalDescription')}
											</FormDescription>
										</div>
										<FormControl>
											<Switch
												checked={field.value}
												onCheckedChange={field.onChange}
											/>
										</FormControl>
									</FormItem>
								)}
							/>

							{!form.watch('isGlobal') && (
								<FormField
									control={form.control}
									name="companyIds"
									rules={{
										required: t('validations.required'),
									}}
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t('sysAdmin.notifications.companies')}
											</FormLabel>
											<FormControl>
												<SelectMultipleCompanies
													onChange={(values) => {
														field.onChange(values);
													}}
													values={field.value ?? []}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							)}

							<FormField
								control={form.control}
								name="type"
								rules={{
									required: t('validations.required'),
								}}
								render={({ field }) => (
									<FormItem>
										<FormLabel>{t('sysAdmin.notifications.type')}</FormLabel>
										<Select
											onValueChange={field.onChange}
											defaultValue={field.value || 'info'}
										>
											<FormControl>
												<SelectTrigger>
													<SelectValue
														placeholder={t('sysAdmin.notifications.type')}
													/>
												</SelectTrigger>
											</FormControl>
											<SelectContent>
												<SelectItem value="info">{t('common.info')}</SelectItem>
												<SelectItem value="warn">{t('common.warn')}</SelectItem>
												<SelectItem value="error">
													{t('common.error')}
												</SelectItem>
											</SelectContent>
										</Select>
										<FormMessage />
									</FormItem>
								)}
							/>

							<FormField
								control={form.control}
								name="title"
								rules={{
									required: t('validations.title.required'),
									minLength: {
										value: 3,
										message: t('validations.title.minLength', {
											min: 3,
										}),
									},
									maxLength: {
										value: 500,
										message: t('validations.title.maxLength', {
											max: 500,
										}),
									},
								}}
								render={({ field }) => (
									<FormItem>
										<FormLabel>{t('validations.title.label')}</FormLabel>
										<FormControl>
											<Input
												{...field}
												data-testid="company-notification-name-input"
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>

							<FormField
								control={form.control}
								name="expiresAt"
								render={({ field }) => (
									<FormItem>
										<FormControl>
											<div className="flex items-end">
												<div className="flex-grow">
													<FormLabel>
														{t('sysAdmin.notifications.expiresAt')}
													</FormLabel>
													<TKDatePicker
														selectedDate={
															field.value ? new Date(field.value) : null
														}
														minDate={new Date()}
														handleDateChange={(date) => {
															if (date) {
																field.onChange(endOfDay(date));
															} else {
																field.onChange(null);
															}
														}}
													/>
												</div>
												{field.value && (
													<TimePicker
														date={field.value ? new Date(field.value) : null}
														setDate={(date) => {
															field.onChange(date);
														}}
													/>
												)}
											</div>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<DialogFooter className="mt-4">
							<Button
								type="button"
								variant="outline"
								onClick={() => {
									setOpen(false);
								}}
								disabled={isLoading}
							>
								{t('common.cancelText')}
							</Button>
							<TKConfirmButton
								dialogType={dialogType}
								isLoading={isLoading}
								disabled={isLoading}
								data-testid="confirm-company-notification"
							/>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
}

export default NotificationFormDialog;
