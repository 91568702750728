import React from 'react';
import AnalysisWidgets from './AnalysisWidgets';
import TKDataHandler from '@/components/Progress/TKDataHandler';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useTranslation } from 'react-i18next';
import { FiltersConfig } from '@/types/analysis';

interface Props {
	activeDashboardId: number;
	canMoveWidgets: boolean;
	filters: FiltersConfig;
}

function AnalysisDashboard({
	filters,
	activeDashboardId,
	canMoveWidgets,
}: Props): JSX.Element {
	const { t } = useTranslation();
	const { companyId } = useAuth();

	const query = trpc.analysis.byId.useQuery(
		{
			companyId,
			id: activeDashboardId!,
		},
		{
			enabled: activeDashboardId !== null,
			refetchOnMount: true,
			refetchOnWindowFocus: false,
		},
	);

	return (
		<TKDataHandler
			loading={query.isLoading}
			error={query.error}
			data={query.data}
			entryText={t('analysis.base')}
		>
			{query.data && (
				<AnalysisWidgets
					canMoveWidgets={canMoveWidgets}
					isEditable={query.data.isEditable}
					dashboardId={activeDashboardId}
					filters={filters}
				/>
			)}
		</TKDataHandler>
	);
}

export default AnalysisDashboard;
