import React from 'react';
import { useTranslation } from 'react-i18next';
import DescribeIcon from '@/components/Common/DescribeIcon';
import { DateFiltersProps } from './DateFilters';
import { ReportInterval } from '@/hooks/analysis/useReportsTemplatesList';
import { TypographySmall } from '@/components/ui/typography';
import { Button } from '@/components/ui/button';

const INTERVALS = ['day', 'week', 'month'] as const;

interface Props {
	handleChange: (dateFilters: Partial<DateFiltersProps>) => void;
	interval: ReportInterval | null;
}

function DateFiltersInterval({ interval, handleChange }: Props) {
	const { t } = useTranslation();

	const currentInterval = interval || null;

	const handleUpdateInterval = async (
		e: React.MouseEvent<HTMLButtonElement>,
	) => {
		const newInterval = e.currentTarget.dataset.interval as ReportInterval;
		handleChange({ interval: newInterval });
	};

	return (
		<div className="min-w-[180px]">
			<div className="flex justify-center items-center">
				<TypographySmall className="font-semibold">
					{t('filters.date.intervals.text')}
				</TypographySmall>
				<DescribeIcon>{t('filters.date.intervals.description')}</DescribeIcon>
			</div>
			<div className="flex flex-col mt-2">
				{INTERVALS.map((interval) => {
					const isActive = interval === currentInterval;
					return (
						<Button
							key={`interval_${interval}`}
							variant={isActive ? 'secondary' : 'ghost'}
							onClick={handleUpdateInterval}
							data-interval={interval}
							data-testid={`date-filter-interval-${interval}`}
							className="w-full justify-start text-left"
						>
							{t(`filters.date.intervals.${interval}`)}
						</Button>
					);
				})}
			</div>
		</div>
	);
}

export default DateFiltersInterval;
