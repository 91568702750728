import { useState } from 'react';
import { useInterval } from './useInterval';

type CountdownTimerStates = 'started' | 'stopped';

type CountdownTimerResult = {
	status: CountdownTimerStates;
	timerValues: { seconds: number; hours: number; minutes: number };
	secondsRemaining: number;
	/** Starts the timer  */
	handleStart: () => void;
	/** Resets the timer and starts it from the beginning   */
	handleReset: () => void;
	/** Stops(pauses) the timer.   */
	handleStop: () => void;
};

/**
 * Hook that generates a countdown timer, which ticks down one second, every second.
 * Use with caution and make sure the component that it is being used in does not have expensive children to render.
 *
 * @param {number} startCount the starting count (in seconds)
 * @return {*}
 */
const useCountdownTimer = (startCount: number): CountdownTimerResult => {
	const [secondsRemaining, setSecondsRemaining] = useState(startCount);
	const [status, setStatus] = useState<CountdownTimerStates>('stopped');

	const seconds = secondsRemaining % 60;
	const minutesRemaining = (secondsRemaining - seconds) / 60;
	const minutes = minutesRemaining % 60;
	const hours = (minutesRemaining - minutes) / 60;

	const handleStart = () => {
		setStatus('started');
	};
	const handleStop = () => {
		setStatus('stopped');
	};
	const handleReset = () => {
		setStatus('started');
		setSecondsRemaining(startCount);
	};
	useInterval(
		() => {
			if (secondsRemaining > 0) {
				setSecondsRemaining(secondsRemaining - 1);
			} else {
				setStatus('stopped');
			}
		},

		status === 'started' ? 1000 : null,
	);

	return {
		status,
		timerValues: { seconds, minutes, hours },
		secondsRemaining,
		handleStart,
		handleStop,
		handleReset,
	};
};

export { useCountdownTimer };
