import React from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { getCurrentStepTitle } from './multistepListCheckboxUtils';
import { CheckboxList } from '.';
import { ChevronLeft, Search, X } from 'lucide-react';
import DescribeIcon from '@/components/Common/DescribeIcon';
import { TypographySmall } from '@/components/ui/typography';

type MultistepHeaderProps = {
	data: CheckboxList;
	handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
	handleGoOneStepBehind: () => void;
	search: string;
	steps: number[];
	description?: string | React.ReactNode;
};

const MultistepHeader: React.FC<MultistepHeaderProps> = ({
	handleGoOneStepBehind,
	data,
	search,
	handleSearch,
	steps,
	description,
}) => {
	return (
		<div className="relative flex flex-col mb-4">
			<div className="w-8 absolute">
				{steps.length > 0 && (
					<Button variant="ghost" size="sm" onClick={handleGoOneStepBehind}>
						<ChevronLeft className="h-4 w-4" />
					</Button>
				)}
			</div>
			<div className="block mx-auto">
				<div className="flex items-center">
					<TypographySmall className="font-semibold">
						{getCurrentStepTitle(data, steps)}
					</TypographySmall>
					{description && <DescribeIcon>{description}</DescribeIcon>}
				</div>
			</div>

			<div className="relative mt-4">
				<Input
					value={search}
					className="h-9 pl-8 pr-8"
					onChange={handleSearch}
					id="search-multistep-list"
					placeholder="Search..."
					type="search"
				/>
				<Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-muted-foreground" />
				{search && (
					<Button
						variant="ghost"
						size="sm"
						className="absolute right-2 top-1/2 transform -translate-y-1/2 h-6 w-6 p-0"
						onClick={() =>
							handleSearch({
								target: { value: '' },
							} as React.ChangeEvent<HTMLInputElement>)
						}
					>
						<X className="h-4 w-4" />
					</Button>
				)}
			</div>
		</div>
	);
};

export default MultistepHeader;
