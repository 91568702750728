import React from 'react';
import { AlphaCountryCodes } from '@/lib/i18nInstance';
import { flagByCountryCode } from '@/utils/common/flagByCountryCode';
import { cn } from '@/lib/utils';

interface CountryImageFlagProps extends React.HTMLAttributes<HTMLDivElement> {
	alphaCode: AlphaCountryCodes;
	size?: 'sm' | 'md' | 'lg';
}

const CountryImageFlag: React.FC<CountryImageFlagProps> = ({
	alphaCode,
	size = 'sm',
	className,
	...props
}) => {
	const sizeClasses = {
		sm: 'w-5 h-auto',
		md: 'w-8 h-auto',
		lg: 'w-12 h-auto',
	};

	return (
		<div
			className={cn(
				'relative flex items-center justify-center',
				sizeClasses[size],
				className,
			)}
			{...props}
		>
			<img
				className="w-full h-auto"
				src={flagByCountryCode(alphaCode)}
				alt={alphaCode}
			/>
		</div>
	);
};

export default CountryImageFlag;
