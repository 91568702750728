import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import AppLayout from '@/components/Layouts/AppLayout';

// Context providers
import { AuthContextProvider } from '@/contexts/Global/AuthContext';
import { DialogProvider } from '@/contexts/Global/Dialog/DialogContext';
import { TRPCProvider } from '@/api/TRPCProvider';
import FirebaseAnalyticsPerformance from '@/components/Firebase/FirebaseAnalyticsPerformance';

import { endpoints } from '@/constants/configs';
import { useTranslation } from 'react-i18next';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import './styles.css';

/**
 * Bootstrap main application component.
 *
 * This is also the place where you should use global components and context providers.
 */
const App: React.FC = () => {
	const { i18n } = useTranslation([]);

	return (
		<TRPCProvider endpoint={endpoints.api} languageCode={i18n.language}>
			<DialogProvider>
				<BrowserRouter>
					<AuthContextProvider>
						<FirebaseAnalyticsPerformance />
						<AppLayout />
						{import.meta.env.DEV && (
							<div className="hidden md:block">
								<ReactQueryDevtools
									position="bottom-right"
									initialIsOpen={false}
								/>
							</div>
						)}
					</AuthContextProvider>
				</BrowserRouter>
			</DialogProvider>
		</TRPCProvider>
	);
};

export default App;
