import { useEffect, useRef } from 'react';

type UseEventListenerType = (
	eventName: keyof WindowEventMap,
	handler: (event: any) => void,
	element?: Window | HTMLElement | Element,
) => void;

/**
 * Hook to allow creation of any event handlers.
 * It defaults the listener to the default `window` object. Optionally, you can change it to instead listen to a custom `HTMLElement`
 *
 * @param {*} eventName the event type
 * @param {*} handler callback function to be executed when this event is triggered
 * @param {*} [element=window] element on which to listen for this event
 */
const useEventListener: UseEventListenerType = (
	eventName,
	handler,
	element = window,
) => {
	// Create a ref that stores handler
	const savedHandler = useRef<any>();
	// Update ref.current value if handler changes.
	// This allows our effect below to always get latest handler ...
	// ... without us needing to pass it in effect deps array ...
	// ... and potentially cause effect to re-run every render.

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(
		() => {
			// Make sure element supports addEventListener
			const isSupported = element && element?.addEventListener;
			if (!isSupported) {
				console.error(
					'[useEventListener.ts] Element does not support addEventListener',
					{
						elementUsed: element,
						eventName,
					},
				);
				return;
			}

			// Create event listener that calls handler function stored in ref
			const eventListener = (event: Event) => savedHandler.current(event);

			// Add event listener
			element.addEventListener(eventName, eventListener);

			// Remove event listener on cleanup
			return () => {
				element.removeEventListener(eventName, eventListener);
			};
		},

		[eventName, element], // Re-run if eventName or element changes
	);
};

export { useEventListener };
