import React from 'react';
import { useUserProfile } from '@/hooks/auth/useUserProfile';
import { Card, CardContent } from '@/components/ui/card';
import { TypographySmall } from '@/components/ui/typography';

interface Props {
	children: React.ReactNode | React.ReactNode[];
}

function SuperUserContainer({ children }: Props): JSX.Element | null {
	const { data } = useUserProfile();

	if (!data?.isSuperuser) return null;

	return (
		<Card className="relative border border-dashed border-tkd-brand">
			<div className="absolute -top-2 left-0 w-full flex justify-center">
				<TypographySmall className="font-bold text-tkd-brand bg-background px-2">
					SUPERUSER
				</TypographySmall>
			</div>
			<CardContent className="pt-2">{children}</CardContent>
		</Card>
	);
}

export default SuperUserContainer;
