import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Spinner } from '@/components/ui/spinner';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@/components/ui/form';
import { TypographyH4, TypographyP } from '@/components/ui/typography';

import { FirebaseActionQuery } from '.';
import {
	verifyPasswordResetCode,
	signInWithEmailAndPassword,
	confirmPasswordReset,
} from 'firebase/auth';
import { firebaseAuth } from '@/lib/firebaseClient';
import TKTypedLink from '@/components/Common/TKTypedLink';
import { useRouter } from '@/hooks/common/useRouter';
import { toast } from 'sonner';
import {
	MAX_PASSWORD_LENGTH,
	MIN_PASSWORD_LENGTH,
	PASSWORD_REGEX,
} from '@/utils/common/validators';

const ResetPasswordForm: React.FC<FirebaseActionQuery> = ({ oobCode }) => {
	const { t } = useTranslation();

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);
	const router = useRouter();

	const FormSchema = z
		.object({
			password: z
				.string()
				.min(MIN_PASSWORD_LENGTH)
				.max(MAX_PASSWORD_LENGTH)
				.regex(PASSWORD_REGEX),
			confirmPassword: z.string(),
		})
		.refine((data) => data.password === data.confirmPassword, {
			message: t('validations.confirmPassword.pattern'),
			path: ['confirmPassword'],
		});

	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			password: '',
			confirmPassword: '',
		},
	});

	/**
	 * Verifies the oobCode and resets their password to the new one.
	 *
	 * On success, it also logs the user in, as there is no point in making them re-type the password again just to login.
	 */
	const handleResetPassword = async ({
		password,
	}: z.infer<typeof FormSchema>) => {
		try {
			setLoading(true);
			const email = await verifyPasswordResetCode(firebaseAuth, oobCode);

			await confirmPasswordReset(firebaseAuth, oobCode, password);
			await signInWithEmailAndPassword(firebaseAuth, email, password);
			router.replace('/', {});

			toast.success(t('login.welcomeMessage'));
			setSuccess(true);
		} catch (error) {
			setError(true);
			setSuccess(false);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Form {...form}>
			<form
				onSubmit={form.handleSubmit(handleResetPassword)}
				className="space-y-4"
			>
				<TypographyH4>
					{t('login.firebaseAction.resetPassword.subtitle')}
				</TypographyH4>

				<FormField
					control={form.control}
					name="password"
					render={({ field }) => (
						<FormItem>
							<FormLabel>{t('validations.password.label')}</FormLabel>
							<FormControl>
								<Input type="password" disabled={loading} {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				<FormField
					control={form.control}
					name="confirmPassword"
					render={({ field }) => (
						<FormItem>
							<FormLabel>{t('validations.confirmPassword.label')}</FormLabel>
							<FormControl>
								<Input type="password" disabled={loading} {...field} />
							</FormControl>
							<FormMessage />
						</FormItem>
					)}
				/>

				{error && (
					<TypographyP className="text-destructive">
						<Trans
							i18nKey="login.firebaseAction.resetPassword.error"
							components={[
								<TKTypedLink
									to="/auth-reset-password"
									params={{}}
									key="reset-auth-page"
								/>,
							]}
						/>
					</TypographyP>
				)}

				<Button type="submit" disabled={loading || error}>
					{loading && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
					{success
						? t('login.firebaseAction.continue')
						: t('login.resetPassword.reset')}
				</Button>
			</form>
		</Form>
	);
};
export default ResetPasswordForm;
