import React from 'react';
type State = {
	hasError: boolean;
	error: Error | null;
};
type Props = {
	fallback: JSX.Element | React.ReactNode;
	children: React.ReactNode;
};

export default class ErrorBoundary extends React.Component<Props, State> {
	state = { hasError: false, error: null };
	static getDerivedStateFromError(error: Error) {
		return {
			hasError: true,
			error,
		};
	}
	render() {
		if (this.state.hasError) {
			return this.props.fallback;
		}
		return this.props.children;
	}
}
