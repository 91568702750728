import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';

export function useCompany() {
	const { companyId } = useAuth();

	return trpc.company.byId.useQuery(
		{ companyId },
		{
			enabled: !!companyId,
			staleTime: 1000 * 60 * 1,
		},
	);
}
