import React from 'react';
import DetailSection from './sections/DetailSection';
import StatusSection from './sections/StatusSection';
import EngineEmissionSection from './sections/EngineEmissionSection';
import RegistrationSection from './sections/RegistrationSection';
import PeriodicControlSection from './sections/PeriodicControlSection';

import { EquipmentById, EquipmentTypeEnum } from '@/types/equipment';

interface Props {
	equipment: EquipmentById;
}

function EquipmentDetailsContent({ equipment }: Props) {
	return (
		<div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
			<DetailSection equipment={equipment} />
			<StatusSection equipment={equipment} />
			{equipment.type === EquipmentTypeEnum.TRUCK && (
				<>
					<EngineEmissionSection equipmentId={equipment.id} />
					<PeriodicControlSection equipmentId={equipment.id} />
					<RegistrationSection equipmentId={equipment.id} />
				</>
			)}
		</div>
	);
}

export default EquipmentDetailsContent;
