import React from 'react';
import { useDrawer } from '@/contexts/Global/Drawer/DrawerContext';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

interface TKDrawerProps {
	hideToggleOnDesktop?: boolean;
	closeButton?: React.ReactNode;
	children: React.ReactNode;
}

const TKDrawer: React.FC<TKDrawerProps> = ({
	hideToggleOnDesktop,
	closeButton,
	children,
}) => {
	const { drawerOpen, setDrawerOpen, isMobile, shouldAnimate } = useDrawer();

	const ChevronIcon = drawerOpen ? ChevronLeft : ChevronRight;

	return (
		<>
			{isMobile && (
				<div
					className={cn(
						'fixed inset-0 bg-black/50 z-40',
						shouldAnimate && 'transition-opacity duration-300',
						drawerOpen ? 'opacity-100' : 'opacity-0 pointer-events-none',
					)}
					onClick={() => setDrawerOpen(false)}
				/>
			)}
			<div
				className={cn(
					'h-full bg-background shadow-md flex-shrink-0',
					shouldAnimate && 'transition-all duration-300 ease-in-out',
					isMobile ? 'fixed top-0 left-0 z-50' : 'relative',
					drawerOpen ? 'w-[280px] border-r border-border' : 'w-0',
					isMobile && !drawerOpen && '-translate-x-full',
				)}
			>
				<div
					className={cn(
						'h-full flex flex-col overflow-hidden',
						!drawerOpen && 'invisible',
					)}
				>
					{closeButton && (
						<div className="h-14 flex justify-end items-center px-2">
							{closeButton}
						</div>
					)}

					<div className="flex-grow overflow-y-auto px-2 py-2">{children}</div>
				</div>

				{(isMobile || (!hideToggleOnDesktop && !isMobile)) && (
					<Button
						variant="outline"
						size="icon"
						className={cn(
							'absolute top-0 z-30 w-8 h-14 rounded-l-none rounded-r-md border-l-0 hover:bg-accent',
							'right-0 translate-x-full',
							shouldAnimate && 'transition-transform duration-300',
						)}
						onClick={() => setDrawerOpen(!drawerOpen)}
					>
						<ChevronIcon className="h-4 w-4" />
					</Button>
				)}
			</div>
		</>
	);
};

export default TKDrawer;
