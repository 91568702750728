import React, { useMemo, useState } from 'react';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useUserProfile } from '@/hooks/auth/useUserProfile';
import { cn } from '@/lib/utils';
import { useNavbarRoutesList } from '@/hooks/common/useNavbarRoutesList';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useCompany } from '@/hooks/auth/useCompany';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { Button } from '@/components/ui/button';
import {
	LogOut,
	ChevronDown,
	User,
	ChevronsRight,
	ChevronsLeft,
	Settings,
	Building2,
} from 'lucide-react';
import { useAuthLogout } from '@/hooks/auth/useAuthLogout';
import { languages } from '@/lib/i18nInstance';
import CountryImageFlag from '@/components/Common/CountryImageFlag';
import TKTypedLink from '@/components/Common/TKTypedLink';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Separator } from '@/components/ui/separator';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { routesList } from '@/routes/routesList';
import { Route } from '@/types/common/routes';
import { TypographyMuted, TypographySmall } from '@/components/ui/typography';
import { TKLogoIcon } from '@/components/Common/TKCustomIcons';
import { useLocalStorage } from '@/hooks/common/useLocalStorage';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';

type NavigationProps = {
	hideMenu: boolean;
};

const profileRoute = routesList.find((route) => route.routeKey === 'profile')!;

const Navigation: React.FC<NavigationProps> = ({ hideMenu }) => {
	const { user, companyId } = useAuth();
	const { t, i18n } = useTranslation();
	const userProfile = useUserProfile();
	const navbarRoutesList = useNavbarRoutesList();
	const company = useCompany();
	const { logout } = useAuthLogout();
	const [isProfileOpen, setIsProfileOpen] = useState(false);
	const [isCollapsed, setIsCollapsed] = useLocalStorage('isCollapsed', false);

	const shouldShowLinks =
		!hideMenu && user && userProfile.data && typeof companyId === 'number';

	const handleChangeLanguage = async (newCode: string) => {
		await i18n.changeLanguage(newCode);
	};

	const languageOptions = languages.map((language) => ({
		value: language.code,
		label: language.code.toUpperCase(),
		fullLabel: language.fullName,
		flagCode: language.region,
	}));

	const selectedLanguage = languageOptions.find(
		(option) => option.value === i18n.language,
	);

	return (
		<>
			{/* Mobile Navigation */}
			<div className="md:hidden fixed bottom-0 left-0 right-0 h-16 bg-black z-30">
				<ScrollArea className="h-full w-full">
					<div className="flex flex-row items-center justify-start px-2">
						<ScrollBar orientation="horizontal" />
						{shouldShowLinks && (
							<>
								{navbarRoutesList.map((route) => (
									<MobileNavigationLink
										key={route.routeKey}
										route={route}
										className="w-20 flex-shrink-0"
									/>
								))}
							</>
						)}

						<MobileNavigationLink
							route={{
								routeKey: 'sysAdminSettings',
								path: '/sys-admin',
								icon: Settings,
								navLink: '/sys-admin',
							}}
							className="w-20 flex-shrink-0"
						/>
						<MobileNavigationLink
							route={{
								routeKey: 'chooseCompany',
								path: '/companies',
								icon: Building2,
								navLink: '/companies',
							}}
							className="w-20 flex-shrink-0"
							overrideTitle={company.data?.name}
						/>
						<MobileNavigationLink
							route={profileRoute}
							className="w-20 flex-shrink-0"
						/>

						<DropdownMenu>
							<DropdownMenuTrigger asChild>
								<Button
									variant="ghost"
									size="sm"
									className="w-20 h-full p-2 flex flex-col items-center justify-center text-white/80 hover:text-white hover:bg-secondary-foreground/20 flex-shrink-0"
								>
									{selectedLanguage && (
										<>
											<CountryImageFlag
												alphaCode={selectedLanguage.flagCode}
												className="size-5"
											/>
											<span className="text-[10px] mt-1">
												{selectedLanguage.flagCode}
											</span>
										</>
									)}
								</Button>
							</DropdownMenuTrigger>
							<DropdownMenuContent align="end" className="min-w-32 bg-white">
								{languageOptions.map((option) => (
									<DropdownMenuItem
										key={option.value}
										onSelect={() => handleChangeLanguage(option.value)}
										className="flex items-center space-x-2 px-2 py-1.5 text-foreground"
									>
										<CountryImageFlag
											alphaCode={option.flagCode}
											className="size-4"
										/>
										<span
											className={cn(
												option.value === i18n.language && 'font-bold',
											)}
										>
											{option.fullLabel}
										</span>
									</DropdownMenuItem>
								))}
							</DropdownMenuContent>
						</DropdownMenu>
						<Button
							variant="ghost"
							className="w-20 h-full p-2 flex flex-col items-center justify-center text-white/80 hover:text-white hover:bg-secondary-foreground/20 flex-shrink-0"
							onClick={logout}
							data-testid="user-logout-button"
						>
							<LogOut
								className="size-4"
								strokeWidth={2.2}
								absoluteStrokeWidth={false}
							/>
							<TypographyMuted className="text-[10px] mt-1 truncate max-w-full">
								{t('signup.logout')}
							</TypographyMuted>
						</Button>
					</div>
				</ScrollArea>
			</div>

			{/* Desktop Navigation */}
			<div
				className={cn(
					'hidden md:flex flex-col bg-black h-screen fixed left-0 top-0 z-50 transition-all duration-300 ease-in-out',
					isCollapsed ? 'w-20' : 'w-56',
				)}
			>
				<div
					className={cn(
						'flex items-center',
						isCollapsed ? 'justify-center' : 'justify-between',
						'p-4',
					)}
				>
					<div className="flex items-center space-x-2">
						<TKLogoIcon className="size-6 text-white" />
						{!isCollapsed && (
							<TypographySmall className="text-white text-nowrap overflow-hidden">
								TK DASHBOARD
							</TypographySmall>
						)}
					</div>
				</div>

				<Separator className="bg-white/10" />

				{shouldShowLinks && (
					<ScrollArea className="flex-grow w-full px-2">
						<div className="space-y-1 py-2 flex flex-col justify-center">
							{navbarRoutesList.map((route) => (
								<NavigationLink
									key={route.routeKey}
									route={route}
									isCollapsed={isCollapsed}
								/>
							))}
						</div>
					</ScrollArea>
				)}

				<Separator className="bg-white/10" />
				<div className="py-2 px-2">
					<NavigationLink
						route={{
							routeKey: 'chooseCompany',
							path: '/companies',
							icon: Building2,
							navLink: '/companies',
						}}
						isCollapsed={isCollapsed}
						overrideTitle={company.data?.name}
					/>
					{userProfile.data?.isSuperuser && (
						<NavigationLink
							route={{
								routeKey: 'sysAdminSettings',
								path: '/sys-admin',
								icon: Settings,
								navLink: '/sys-admin',
							}}
							isCollapsed={isCollapsed}
						/>
					)}
				</div>

				<Separator className="bg-white/10" />

				<div className="mt-auto w-full p-2 flex flex-col gap-y-1">
					<DropdownMenu open={isProfileOpen} onOpenChange={setIsProfileOpen}>
						<DropdownMenuTrigger asChild>
							<Button
								variant="ghost"
								className="w-full p-2 hover:bg-secondary-foreground/20 group"
								data-testid="navigation-user-profile-trigger"
							>
								<div
									className={cn(
										'flex items-center w-full',
										isCollapsed ? 'justify-center' : 'justify-between',
									)}
								>
									<div className="flex items-center space-x-2">
										<Avatar className="size-8">
											<AvatarImage
												alt={
													userProfile.data?.firstName &&
													userProfile.data?.lastName
														? `${userProfile.data?.firstName} ${userProfile.data?.lastName}`
														: ''
												}
											/>
											<AvatarFallback>
												{(userProfile.data?.firstName?.charAt(0) ?? '') +
													(userProfile.data?.lastName?.charAt(0) ?? '')}
											</AvatarFallback>
										</Avatar>
										{!isCollapsed && (
											<div className="flex flex-col items-start overflow-hidden">
												<span
													className="text-sm font-semibold text-white truncate max-w-[120px]"
													data-testid="navigation-user-name"
												>
													{userProfile.data?.firstName &&
													userProfile.data?.lastName
														? `${userProfile.data?.firstName} ${userProfile.data?.lastName}`
														: ''}
												</span>
												<span className="text-xs text-white/60 truncate max-w-[120px]">
													{userProfile.data?.email}
												</span>
											</div>
										)}
									</div>
									{!isCollapsed && (
										<ChevronDown className="h-4 w-4 text-white/60 opacity-0 group-hover:opacity-100 transition-opacity" />
									)}
								</div>
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent className="w-56" align="end">
							<DropdownMenuItem asChild>
								<TKTypedLink
									to="/profile"
									params={{}}
									className="m-0 p-0 hover:no-underline"
								>
									<Button
										variant="ghost"
										className="w-full justify-start px-2  py-1.5 hover:bg-secondary-foreground/20"
									>
										<User className="mr-2 h-4 w-4" />
										<span>{t('routes.profile')}</span>
									</Button>
								</TKTypedLink>
							</DropdownMenuItem>
							<DropdownMenu>
								<DropdownMenuTrigger asChild>
									<Button
										variant="ghost"
										className="w-full justify-between px-2 py-1.5 hover:bg-secondary-foreground/20"
									>
										<div className="flex items-center space-x-2">
											{selectedLanguage && (
												<CountryImageFlag
													alphaCode={selectedLanguage.flagCode}
													className="size-4"
												/>
											)}
											<span>{t('common.language')}</span>
										</div>
										<span className="font-bold text-xs">
											{selectedLanguage?.flagCode}
										</span>
									</Button>
								</DropdownMenuTrigger>
								<DropdownMenuContent align="end" className="min-w-32 bg-white">
									{languageOptions.map((option) => (
										<DropdownMenuItem
											key={option.value}
											onSelect={() => handleChangeLanguage(option.value)}
											className="flex items-center justify-between px-2 py-1.5 text-foreground"
										>
											<div className="flex items-center space-x-2">
												<CountryImageFlag
													alphaCode={option.flagCode}
													size="sm"
												/>
												<span
													className={cn(
														option.value === i18n.language && 'font-bold',
													)}
												>
													{option.fullLabel}
												</span>
											</div>
										</DropdownMenuItem>
									))}
								</DropdownMenuContent>
							</DropdownMenu>
							<DropdownMenuItem asChild>
								<Button
									variant="ghost"
									className="w-full justify-start cursor-pointer px-2 py-1.5 text-destructive hover:bg-secondary-foreground/20"
									onClick={logout}
									data-testid="user-logout-button"
								>
									<LogOut className="mr-2 h-4 w-4" />
									<span>{t('signup.logout')}</span>
								</Button>
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
				<Separator className="bg-white/10 my-2" />
				<Button
					variant="ghost"
					size="icon"
					onClick={() => setIsCollapsed(!isCollapsed)}
					className="mx-auto text-white w-full transition-colors duration-200 rounded-b-none"
				>
					{isCollapsed ? (
						<ChevronsRight
							className="size-5 mx-auto"
							absoluteStrokeWidth={false}
							strokeWidth={3}
						/>
					) : (
						<ChevronsLeft
							className="size-5 mx-auto"
							absoluteStrokeWidth={false}
							strokeWidth={3}
						/>
					)}
				</Button>
			</div>

			<div
				className={cn(
					'hidden md:block transition-all duration-300 ease-in-out',
					isCollapsed ? 'w-20' : 'w-56',
				)}
			/>

			{/* Spacer for mobile */}
			<div className="md:hidden h-16" />
		</>
	);
};

export default Navigation;

interface NavigationLinkProps {
	route: Pick<Route, 'routeKey' | 'path' | 'icon' | 'navLink'>;
	className?: string;
	isCollapsed?: boolean;
	overrideTitle?: string;
}

const NavigationLink: React.FC<NavigationLinkProps> = ({
	route,
	isCollapsed,
	overrideTitle,
}) => {
	const { t } = useTranslation();
	const Icon = route.icon;

	const pathname = route.navLink || route.path;
	const linkContent = useMemo(
		() => (
			<NavLink
				to={pathname}
				className={(isActive) =>
					cn(
						'flex items-center w-full p-2 h-12 rounded-md',
						'transition-colors duration-200',
						isActive && (pathname !== '/' || location.pathname === '/')
							? 'text-tkd-brand bg-white/10 hover:text-tkd-brand-light'
							: 'text-white/80 hover:text-white hover:bg-white/10',
						'focus:bg-white/10 focus:outline-none',
						isCollapsed ? 'justify-center' : 'space-x-3',
					)
				}
			>
				{Icon && (
					<Icon
						className={cn(isCollapsed ? 'size-5' : 'size-6')}
						strokeWidth={2.2}
						absoluteStrokeWidth={false}
					/>
				)}
				{!isCollapsed && (
					<span className="text-sm font-medium truncate">
						{overrideTitle || t(`routes.${route.routeKey}`)}
					</span>
				)}
			</NavLink>
		),
		[pathname, isCollapsed, Icon, overrideTitle, t, route.routeKey],
	);

	if (isCollapsed) {
		return (
			<TooltipProvider skipDelayDuration={50} delayDuration={100}>
				<Tooltip>
					<TooltipTrigger asChild>
						<div>{linkContent}</div>
					</TooltipTrigger>
					<TooltipContent
						side="right"
						className="bg-black text-white border-none"
					>
						<p>{overrideTitle || t(`routes.${route.routeKey}`)}</p>
					</TooltipContent>
				</Tooltip>
			</TooltipProvider>
		);
	}

	return linkContent;
};

interface MobileNavigationLinkProps {
	route: Pick<Route, 'routeKey' | 'path' | 'icon' | 'navLink'>;
	overrideTitle?: string;
	className?: string;
}

const MobileNavigationLink: React.FC<MobileNavigationLinkProps> = ({
	route,
	overrideTitle,
	className,
}) => {
	const { t } = useTranslation();
	const Icon = route.icon;

	const pathname = route.navLink || route.path;

	return (
		<NavLink
			to={pathname}
			className={(isActive) =>
				cn(
					'flex flex-col items-center justify-center rounded-sm w-16 h-full p-1',
					'transition-colors duration-200',
					isActive && (pathname !== '/' || location.pathname === '/')
						? 'text-tkd-brand bg-white/10 hover:text-tkd-brand-light'
						: 'text-white/80 hover:text-white hover:bg-white/10',
					'focus:bg-white/10 focus:outline-none',
					className,
				)
			}
		>
			{Icon && (
				<Icon
					className="size-4"
					strokeWidth={2.2}
					absoluteStrokeWidth={false}
				/>
			)}
			<TypographyMuted className="text-[10px] mt-1 truncate max-w-full">
				{overrideTitle || t(`routes.${route.routeKey}`)}
			</TypographyMuted>
		</NavLink>
	);
};
