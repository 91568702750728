import React from 'react';
import { cn } from '@/lib/utils';

import { ReactComponent as ContainerIcon } from '@/assets/images/equipment/container.svg';
import { ReactComponent as CarIcon } from '@/assets/images/equipment/car.svg';
import { ReactComponent as TrailerIcon } from '@/assets/images/equipment/trailer.svg';
import { ReactComponent as TruckIcon } from '@/assets/images/equipment/truck.svg';
import { ReactComponent as DriverIcon } from '@/assets/images/equipment/driver.svg';
import { ReactComponent as MachineIcon } from '@/assets/images/equipment/bulldoser.svg';
import { ReactComponent as TKIcon } from '@/assets/images/tklogowheel.svg';

const TKIcons = {
	TRUCK: TruckIcon,
	DRIVER: DriverIcon,
	MACHINE: MachineIcon,
	CAR: CarIcon,
	CONTAINER: ContainerIcon,
	TRAILER: TrailerIcon,
	TKLOGO: TKIcon,
} as const;

type TKCustomIconsProps = React.SVGProps<SVGSVGElement>;

const TKCustomIcons: React.FC<
	TKCustomIconsProps & { iconType: keyof typeof TKIcons }
> = ({ iconType, ...props }) => {
	const Icon = TKIcons[iconType];
	return <Icon className={cn('size-5', props.className)} {...props} />;
};

export const TKLogoIcon: React.FC<TKCustomIconsProps> = (props) => (
	<TKCustomIcons
		{...props}
		iconType="TKLOGO"
		className={cn('w-5 h-5', props.className)}
	/>
);

export const EquipmentIcon: React.FC<
	TKCustomIconsProps & { iconType: keyof typeof TKIcons }
> = (props) => <TKCustomIcons {...props} iconType={props.iconType} />;

export default TKCustomIcons;
