import React from 'react';
import TKTextWithLabel from '@/components/Common/TKTextWithLabel';
import { useTranslation } from 'react-i18next';

interface Props {
	weightKg?: number | null;
	sampledAt?: string | null;
}

function CurrentWeightField({ sampledAt, weightKg }: Props) {
	const { t, i18n } = useTranslation();

	const text =
		typeof weightKg === 'number'
			? `${weightKg.toLocaleString(i18n.language)} ${t('common.unit.kg.short')}`
			: t('common.notAvailable');

	return (
		<TKTextWithLabel
			label={t('equipment.columns.currentWeight')}
			timestamp={sampledAt}
			text={text}
		/>
	);
}

export default CurrentWeightField;
