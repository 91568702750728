import { useAuth } from '@/contexts/Global/AuthContext';
import { useUserProfile } from './useUserProfile';

type FirebaseChecks = {
	/** Checks if user has completed their profile page  */
	hasProfileFinished: boolean | undefined;

	/** Checks if user is logged in */
	isLogged: boolean;

	/** Checks if email is verified */
	emailVerified: boolean;

	/** The amount of providers available to the current user */
	providersAmount: number;
};

/**
 * Hook that abstracts away common checks useful for authentication-related redirects.
 */
const useFirebaseChecks = (): FirebaseChecks => {
	const { user } = useAuth();
	const userProfile = useUserProfile();
	const emailVerified = !!user?.emailVerified;
	const isLogged = !!user;

	const providersAmount = user?.providerData?.length || 0;

	const hasProfileFinished =
		!!userProfile?.data?.uid && !userProfile.error && !userProfile.isLoading;

	return {
		hasProfileFinished,
		isLogged,
		emailVerified,
		providersAmount,
	};
};

export { useFirebaseChecks };
