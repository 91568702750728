import React from 'react';
import { CardHeader, CardTitle } from '../ui/card';
import Nbsp from '../Common/Nbsp';

interface Props {
	title?: string;
	children?: React.ReactNode | React.ReactNode[];
}

/**
 * Provides a simple header layout for the top part used in widgets/reports.
 */
function TKWidgetHeader({ children, title }: Props) {
	return (
		<CardHeader className="flex justify-between overflow-auto flex-row items-center ml-1 draggableWidgetHandler p-1 h-14 overflow-y-hidden">
			{title ? (
				<div className="flex flex-col mr-4">
					<CardTitle>{title}</CardTitle>
				</div>
			) : (
				<Nbsp />
			)}

			<div className="flex">{children}</div>
		</CardHeader>
	);
}

export default TKWidgetHeader;
