import React from 'react';
import { useDialog } from '@/contexts/Global/Dialog/DialogContext';
import { useTranslation } from 'react-i18next';
import { MoreVertical } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ReportChartTypes } from '@/types/analysis';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';

interface Props {
	reportId: number;
	dashboardId: number;
	handleExportToCsv?: () => void;
	handleExportToExcel?: () => Promise<void>;
	name: string;
	canEditDashboard: boolean;
	chartType?: ReportChartTypes;
	loading?: boolean;
}

function ReportWidgetHeader({
	reportId,
	dashboardId,
	name,
	canEditDashboard,
	chartType,
	loading,
	handleExportToCsv,
	handleExportToExcel,
}: Props): JSX.Element {
	const { companyId } = useAuth();
	const trpcContext = trpc.useUtils();
	const { t } = useTranslation();
	const dialog = useDialog();

	const reportsListParams = { companyId, dashboardId };
	const deleteMutation = trpc.analysisReport.deleteById.useMutation({
		async onMutate() {
			// Cancel any outgoing re-fetches (so they don't overwrite our optimistic update)
			await trpcContext.analysisReport.list.cancel(reportsListParams);

			const previous =
				trpcContext.analysisReport.list.getData(reportsListParams);

			// Optimistically update to the new value
			trpcContext.analysisReport.list.setData(
				{ companyId, dashboardId },
				(reports = []) => reports.filter((report) => report.id !== reportId),
			);
			return previous!;
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		async onError(error, variables, context) {
			trpcContext.analysisReport.list.setData(
				reportsListParams,
				context as never,
			);
		},
	});

	function handleDelete() {
		dialog({
			dialogType: 'delete',
			title: t('analysis.reports.deleteReportDialog.title'),
			description: t('analysis.reports.deleteReportDialog.description'),
			dataTestConfirm: 'confirm-delete-report',
		}).then(() => deleteMutation.mutate({ id: reportId, companyId }));
	}

	async function handleExportToPng() {
		const chartElement = document.querySelector(
			`[data-chart="chart-${reportId}"]`,
		);
		if (chartElement) {
			try {
				const canvas = await html2canvas(chartElement as HTMLElement);
				canvas.toBlob((blob) => {
					if (blob) {
						FileSaver.saveAs(blob, `${name}.png`);
					}
				});
			} catch (error) {
				console.error('Error exporting chart to PNG:', error);
			}
		}
	}

	return (
		<div className="notDraggableWidget">
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button
						variant="ghost"
						size="icon"
						className="size-8"
						data-testid="open-widget-actions-menu"
					>
						<MoreVertical className="h-4 w-4" />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="end">
					{handleExportToExcel && (
						<DropdownMenuItem
							onClick={handleExportToExcel}
							data-testid="export-widget-to-excel"
						>
							{t('analysis.exportToExcel')}
						</DropdownMenuItem>
					)}
					{handleExportToCsv && (
						<DropdownMenuItem
							onClick={handleExportToCsv}
							data-testid="export-widget-to-csv"
						>
							{t('analysis.exportToCsv')}
						</DropdownMenuItem>
					)}
					{chartType === 'graph' && (
						<DropdownMenuItem
							onClick={handleExportToPng}
							data-testid="export-widget-to-png"
							disabled={!!loading}
						>
							{t('analysis.exportToSvg')}
						</DropdownMenuItem>
					)}
					{canEditDashboard && (
						<DropdownMenuItem
							onClick={handleDelete}
							data-testid="open-report-delete-dialog"
						>
							{t('analysis.deleteReport')}
						</DropdownMenuItem>
					)}
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
}
export default ReportWidgetHeader;
