import React, { useCallback, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpLink } from '@trpc/client/links/httpLink';
import { loggerLink } from '@trpc/client/links/loggerLink';
import { trpc } from './trpc';
import { firebaseAuth } from '@/lib/firebaseClient';
import { toast } from 'sonner';
import { TRPCClientError } from '@trpc/client';

interface Props {
	endpoint: string;
	languageCode: string;
	children: React.ReactNode;
}

const TrpcProvider = trpc.Provider;

const TRPCProvider: React.FC<Props> = ({
	children,
	endpoint,
	languageCode,
}) => {
	/** Generates a `react-query` instance when the components mounts. */
	const [queryClient] = useState(() => {
		return new QueryClient({
			defaultOptions: {
				queries: {
					retry(failureCount: number, error: unknown) {
						if (!(error instanceof TRPCClientError)) {
							return false;
						}

						const retryableCodes = new Set([
							'TIMEOUT',
							'INTERNAL_SERVER_ERROR',
							'TOO_MANY_REQUESTS',
						]);
						if (error?.data?.code && !retryableCodes.has(error.data.code)) {
							return false;
						}

						return failureCount < 3;
					},
				},
			},
		});
	});

	/**
	 * Generates a tRPC client for the given endpoint, using the given auth token and user's language.
	 */
	const instantiateTrpcClient = useCallback(
		(newLanguageCode: string) => {
			return trpc.createClient({
				links: [
					loggerLink({
						logger: (q) => {
							if (
								q.direction === 'down' &&
								q.result instanceof Error &&
								q.result?.message &&
								q.type === 'mutation'
							) {
								toast.error(q.result.message);
							}
						},
					}),
					httpLink({
						url: endpoint,
						headers: async () => {
							let authHeader = '';
							const token = await firebaseAuth.currentUser?.getIdToken();
							if (token) {
								authHeader = `Bearer: ${token}`;
							}
							return {
								authorization: authHeader,
								'x-tkd-language': newLanguageCode,
							};
						},
					}),
				],
			});
		},
		[endpoint],
	);

	/** Instantiate a tRPC client when components is initially loaded. */
	const [trpcClient, setTrpcClient] = useState(() =>
		instantiateTrpcClient(languageCode),
	);

	/**
	 * Re-instantiate a new tRPC client when the language changes.
	 */
	useEffect(() => {
		setTrpcClient(instantiateTrpcClient(languageCode));
	}, [languageCode, instantiateTrpcClient]);

	/**
	 * Re-instantiate a new tRPC client when the auth state changes.
	 * The auth token is used to authenticate the tRPC client.
	 */
	useEffect(() => {
		const unsubscribe = firebaseAuth.onAuthStateChanged(() => {
			setTrpcClient(instantiateTrpcClient(languageCode));
		});
		return () => unsubscribe();
	}, [instantiateTrpcClient, languageCode]);

	return (
		<TrpcProvider client={trpcClient} queryClient={queryClient}>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</TrpcProvider>
	);
};

export { TRPCProvider };
