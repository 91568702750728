import * as Sentry from '@sentry/react';

Sentry.init({
	dsn: 'https://b9015f62fc708fc89055ff427da92d25@o4507905649475584.ingest.de.sentry.io/4507921663524944',
	integrations: [Sentry.browserTracingIntegration()],
	// Set tracesSampleRate to 1.0 to capture 10%
	// of transactions for performance monitoring.
	tracesSampleRate: 0.1,
	// Capture Replay for 100% of sessions with an error
	replaysOnErrorSampleRate: 1.0,
	enabled: import.meta.env.VITE_ENVIRONMENT === 'PRODUCTION',
});
