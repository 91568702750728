import React, { useState } from 'react';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useTranslation } from 'react-i18next';
import TKWidgetHeader from '@/components/WidgetSystem/TKWidgetHeader';
import WidgetDataHandler from '@/components/WidgetSystem/WidgetDataHandler';
import { endOfDay, startOfDay, startOfMonth, subMonths } from 'date-fns';
import { useCompany } from '@/hooks/auth/useCompany';
import TKDatePicker from '@/components/FormControls/TKDatePicker';
import HomepageWidgetHeader from '../HomepageWidgetHeader';
import { useExportDataTable } from '@/hooks/common/useExportDataTable';
import { useDateFns } from '@/hooks/common/useDateFns';
import { dropUnitDecimals } from '@/utils/analysis/unitsConvertor';
import { ReportUnit } from '@/types/analysis';
import TKChartContainer from '@/components/Charts/TKChartContainer';
import { DataTable } from '@/components/Table/data-table';
import { Label } from '@/components/ui/label';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { HomepageWidgetBaseCard } from '../HomepageWidgetBaseCard';

function DriverScoreGradeReport(): JSX.Element {
	const { format } = useDateFns();
	const { companyId } = useAuth();
	const { data: company } = useCompany();
	const { t, i18n } = useTranslation();
	const [from, setFrom] = useState(() =>
		startOfMonth(subMonths(new Date(), 1)).toISOString(),
	);
	const [to, setTo] = useState(() => endOfDay(new Date()).toISOString());

	const {
		data = [],
		isLoading,
		error,
	} = trpc.driverScore.list.useQuery(
		{
			companyId,
			fromTime: from,
			toTime: to,
		},
		{
			select(data) {
				return data.map((item) => ({
					...item,
					fromTime: item.fromTime ? new Date(item.fromTime) : null,
					toTime: item.toTime ? new Date(item.toTime) : null,
				}));
			},
		},
	);
	const title = t('analysis.reports.driver_score.title');

	const { exportToCsv, exportToExcel } = useExportDataTable(
		data,
		[
			{
				id: 'driverFullname',
				title: t('equipment.columns.currentDriver'),
			},
			{
				id: 'type',
				title: t('analysis.reports.driver_score.type'),
			},
			{
				id: 'fromTime',
				title: t('analysis.reports.driver_score.from_time'),
				render: (row) => (row.fromTime ? format(row.fromTime, 'P') : null),
			},
			{
				id: 'toTime',
				title: t('analysis.reports.driver_score.to_time'),
				render: (row) => (row.toTime ? format(row.toTime, 'P') : null),
			},
			{
				id: 'drivingDistanceKm',
				title: t('analysis.reports.driver_score.distance_driven'),
				render: (row) =>
					row.drivingDistanceKm !== null
						? dropUnitDecimals(ReportUnit.Kilometer, row.drivingDistanceKm)
						: null,
			},
			{
				id: 'fuelIncludingIdlePerTenKmLiter',
				title: t('analysis.reports.driver_score.fuel_used'),
				render: (row) =>
					row.fuelIncludingIdlePerTenKmLiter !== null
						? dropUnitDecimals(
								ReportUnit.LiterPer10Km,
								row.fuelIncludingIdlePerTenKmLiter,
							)
						: null,
			},
			{
				id: 'scoreTotal',
				title: t('analysis.reports.driver_score.score'),
			},
			{
				id: 'grade',
				title: t('analysis.reports.driver_score.grade'),
			},
		],
		`${title}_${format(from, 'Pp')}_${format(to, 'Pp')}`,
	);

	return (
		<HomepageWidgetBaseCard>
			<TKWidgetHeader title={title}>
				<div className="flex items-center">
					<div className="overflow-auto min-w-[280px] max-w-full">
						<div className="flex items-center gap-4 pt-1 mr-2 min-w-[500px]">
							<div className="flex items-center gap-2">
								<Label>{t('analysis.reports.driver_score.from_time')}</Label>
								<TKDatePicker
									selectedDate={new Date(from)}
									handleDateChange={(date) =>
										date ? setFrom(startOfDay(date).toISOString()) : null
									}
									minDate={company?.createdAt}
									maxDate={endOfDay(new Date()).toISOString()}
								/>
							</div>
							<div className="flex items-center gap-2">
								<Label>{t('analysis.reports.driver_score.to_time')}</Label>
								<TKDatePicker
									selectedDate={new Date(to)}
									handleDateChange={(date) =>
										date ? setTo(endOfDay(date).toISOString()) : null
									}
									minDate={company?.createdAt}
									maxDate={endOfDay(new Date()).toISOString()}
								/>
							</div>
						</div>
					</div>

					<HomepageWidgetHeader
						handleExportToCsv={exportToCsv}
						handleExportToExcel={exportToExcel}
						loading={isLoading}
					/>
				</div>
			</TKWidgetHeader>
			<WidgetDataHandler data={data} loading={isLoading} error={error}>
				<TKChartContainer>
					<DataTable
						isLoading={isLoading}
						error={error}
						defaultSortColumn="scoreTotal"
						defaultSortOrder="desc"
						wrapperClassName="p-0 border-none"
						columns={[
							{
								id: 'driverFullname',
								title: t('equipment.columns.currentDriver'),
								render: (row) => {
									const names = row.driverFullname.split(' ');
									const firstName = names[0];
									const lastName = names.pop()!;
									return (
										<div className="flex items-center gap-2">
											<Avatar className="h-8 w-8">
												<AvatarFallback>
													{firstName[0]}
													{lastName[0]}
												</AvatarFallback>
											</Avatar>
											<span>{row.driverFullname}</span>
										</div>
									);
								},
							},
							{
								id: 'type',
								title: t('analysis.reports.driver_score.type'),
							},
							{
								id: 'fromTime',
								title: t('analysis.reports.driver_score.from_time'),
								enableSorting: true,
								render: (row) => {
									if (row.fromTime === null) return null;
									return format(row.fromTime, 'P');
								},
							},
							{
								id: 'toTime',
								title: t('analysis.reports.driver_score.to_time'),
								enableSorting: true,
								render: (row) => {
									if (row.toTime === null) return null;
									return format(row.toTime, 'P');
								},
							},
							{
								id: 'drivingDistanceKm',
								title: t('analysis.reports.driver_score.distance_driven'),
								enableSorting: true,
								render: (row) => {
									if (row.drivingDistanceKm === null) return null;
									const val = dropUnitDecimals(
										ReportUnit.Kilometer,
										row.drivingDistanceKm,
									);
									return t(`shared-reports.units.kilometer`, {
										amount: val.toLocaleString(i18n.language),
										count: val,
									});
								},
							},
							{
								id: 'fuelIncludingIdlePerTenKmLiter',
								title: t('analysis.reports.driver_score.fuel_used'),
								enableSorting: true,
								render: (row) => {
									if (row.fuelIncludingIdlePerTenKmLiter === null) return null;
									const val = dropUnitDecimals(
										ReportUnit.LiterPer10Km,
										row.fuelIncludingIdlePerTenKmLiter,
									);
									return t(`shared-reports.units.liter_per_10km`, {
										amount: val.toLocaleString(i18n.language),
										count: val,
									});
								},
							},
							{
								id: 'scoreTotal',
								title: t('analysis.reports.driver_score.score'),
								enableSorting: true,
							},
							{
								id: 'grade',
								title: t('analysis.reports.driver_score.grade'),
								enableSorting: true,
							},
						]}
						data={data}
					/>
				</TKChartContainer>
			</WidgetDataHandler>
		</HomepageWidgetBaseCard>
	);
}

export default DriverScoreGradeReport;
