import { useState, useCallback } from 'react';

type ReturnValue<T> = [T, React.Dispatch<React.SetStateAction<T>>];

const useSessionStorage = <T>(key: string, initialValue: T): ReturnValue<T> => {
	// State to store our value

	// Pass initial state function to useState so logic is only executed once

	const [storedValue, setStoredValue] = useState(() => {
		try {
			// Get from session storage by key

			const item = window.sessionStorage.getItem(key);
			// Parse stored json or if none return initialValue

			return item ? JSON.parse(item) : initialValue;
		} catch (error) {
			// If error also return initialValue

			return initialValue;
		}
	});

	const setValue = useCallback(
		(value: T | ((value: T) => void)) => {
			try {
				// Allow value to be a function so we have same API as useState

				const valueToStore =
					value instanceof Function ? value(storedValue) : value;

				// Save state
				setStoredValue({ ...valueToStore });

				// Save to session storage
				window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
			} catch (error) {
				// A more advanced implementation would handle the error case
			}
		},
		[key, storedValue],
	);

	return [storedValue, setValue];
};

export { useSessionStorage };
