import React from 'react';
import { ReportUnit } from '@/types/analysis';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useTranslation } from 'react-i18next';
import TKWidgetHeader from '@/components/WidgetSystem/TKWidgetHeader';
import TKTypedLink from '@/components/Common/TKTypedLink';
import WidgetDataHandler from '@/components/WidgetSystem/WidgetDataHandler';
import HomepageWidgetHeader from '../HomepageWidgetHeader';
import { meterToKilometer } from '@/utils/analysis/unitsConvertor';
import { useExportDataTable } from '@/hooks/common/useExportDataTable';
import { useDateFns } from '@/hooks/common/useDateFns';
import TKCustomIcons from '@/components/Common/TKCustomIcons';
import { DataTable } from '@/components/Table/data-table';
import TKChartContainer from '@/components/Charts/TKChartContainer';
import { HomepageWidgetBaseCard } from '../HomepageWidgetBaseCard';

function ServiceDistanceReport(): JSX.Element {
	const { format } = useDateFns();
	const { companyId } = useAuth();
	const { t, i18n } = useTranslation();

	const {
		data = [],
		isLoading,
		error,
	} = trpc.widget.executeServiceDistance.useQuery({ companyId });

	const formatValue = (meter: number | null) => {
		if (meter === null) {
			return t('common.noData');
		}
		if (meter <= 0) {
			return t('analysis.reports.service_distance.distance_reached');
		}

		const km = meterToKilometer(meter);
		return t(`shared-reports.units.${ReportUnit.Kilometer}`, {
			amount: km.toLocaleString(i18n.language),
			count: km,
		});
	};

	const title = t('analysis.reports.service_distance.title');

	const { exportToCsv, exportToExcel } = useExportDataTable(
		data,
		[
			{
				id: 'displayName',
				title: t('analysis.reports.service_distance.equipment'),
			},
			{
				id: 'distanceToNextServiceMeter',
				title: t('analysis.reports.service_distance.distance'),
				render: (row) => meterToKilometer(row.distanceToNextServiceMeter),
			},
		],
		`${title}_${format(new Date(), 'Pp')}`,
	);

	return (
		<HomepageWidgetBaseCard>
			<TKWidgetHeader title={title}>
				<HomepageWidgetHeader
					handleExportToCsv={exportToCsv}
					handleExportToExcel={exportToExcel}
					loading={isLoading}
				/>
			</TKWidgetHeader>
			<WidgetDataHandler data={data} loading={isLoading} error={error}>
				<TKChartContainer>
					<DataTable
						isLoading={isLoading}
						error={error}
						defaultSortColumn="distanceToNextServiceMeter"
						defaultSortOrder="asc"
						wrapperClassName="p-0 border-none"
						columns={[
							{
								id: 'displayName',
								title: t('analysis.reports.service_distance.equipment'),
								render: (row) => (
									<div className="flex items-center gap-2">
										<TKCustomIcons iconType={row.type} />
										<TKTypedLink
											to="/equipment/:id"
											params={{ id: row.equipmentId }}
										>
											{row.displayName}
										</TKTypedLink>
									</div>
								),
							},
							{
								id: 'distanceToNextServiceMeter',
								title: t('analysis.reports.service_distance.distance'),
								enableSorting: true,
								render: (row) => formatValue(row.distanceToNextServiceMeter),
							},
						]}
						data={data}
					/>
				</TKChartContainer>
			</WidgetDataHandler>
		</HomepageWidgetBaseCard>
	);
}

export default ServiceDistanceReport;
