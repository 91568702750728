export enum IntegrationTypeEnum {
	Cat = 'CAT',
	ScaniaFms = 'SCANIA_FMS',
	Tachoonline = 'TACHOONLINE',
	Telespor = 'TELESPOR',
	VolvoCaretrack = 'VOLVO_CARETRACK',
	VolvoCeAemp_2_0 = 'VOLVO_CE_AEMP_2_0',
	VolvoConnect = 'VOLVO_CONNECT',
	MercedesFleetboard = 'MERCEDES_FLEETBOARD',
	ManRio = 'MAN_RIO',
	Opter = 'OPTER',
}

export interface IntegrationConfig {
	clientId: string;
	companyId: number;
	enabled: boolean;
	id: number;
	name: string | null;
	secret: string;
	type: IntegrationTypeEnum;
}

export interface IntegrationConfigFormData extends IntegrationConfig {
	customFields?:
		| {
				username: string;
				password: string;
				'X-IBM-Client-Id': string;
		  }
		| any;
}

export interface IntegrationTypeItem {
	id: IntegrationTypeEnum;
	name: string;
	categoryId: string;
}
