import React from 'react';
import TKChartContainer from './TKChartContainer';
import { DataColumn, DataTable } from '@/components/Table/data-table';
import { ReportExecuteResult } from '@/types/analysis';

interface Props {
	data: ReportExecuteResult;
	columns: DataColumn<ReportExecuteResult[number]>[];
}
function TableChart({ data, columns }: Props) {
	return (
		<TKChartContainer>
			<div className="w-full h-full overflow-auto" data-testid="list-chart">
				<DataTable
					data={data}
					columns={columns}
					isLoading={false}
					error={null}
					defaultSortColumn={columns[0].id}
					defaultSortOrder="desc"
					wrapperClassName="p-0 border-none"
				/>
			</div>
		</TKChartContainer>
	);
}

export default TableChart;
