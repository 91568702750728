import { useTranslation } from 'react-i18next';
import { ReactComponent as NotAllowedImage } from '@/assets/images/undraw_cancel_u1it.svg';
import { Button } from '@/components/ui/button';
import { TypographyH3 } from '@/components/ui/typography';

function NotAllowed() {
	const { t } = useTranslation();

	const handleGoBack = () => {
		window.history.back();
	};

	return (
		<div className="flex items-center justify-center min-h-screen">
			<div className="w-full max-w-3xl">
				<div className="flex flex-col items-center space-y-6 p-6">
					<NotAllowedImage
						viewBox="0 0 1080.0487 748.00219"
						width="100%"
						height="auto"
						className="max-w-md"
					/>

					<TypographyH3 className="text-center">
						{t('commonPages.notAllowed.description')}
					</TypographyH3>

					<Button onClick={handleGoBack}>
						{t('commonPages.notAllowed.goBack')}
					</Button>
				</div>
			</div>
		</div>
	);
}

export default NotAllowed;
