import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from '@/hooks/common/useRouter';
import { useAuth } from '@/contexts/Global/AuthContext';

import DashboardLayout from '@/components/Layouts/DashboardLayout';
import CompanyProjectRuleAddDialog from '@/views/Company/CompanyProjectRules/CompanyProjectRuleAddDialog';
import { trpc } from '@/api/trpc';
import CompanyProjectRulesTable from './CompanyProjectRulesTable';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';

interface QueryParams {
	id: string;
}

function CompanyProjectRulesContainer() {
	const router = useRouter();
	const [createDialogOpen, setCreateDialogOpen] = useState(false);

	const { companyId } = useAuth();
	const { t } = useTranslation();

	const query: QueryParams = router.query;
	const project = trpc.project.byId.useQuery({ companyId, id: query.id });

	return (
		<>
			<DashboardLayout
				AppBarComponent={
					<TKPageAppBar
						onBackButtonClick={() =>
							router.push('/company-settings/projects', {})
						}
						title={t('company.projectRules.title', {
							name: project.data?.name ?? '',
						})}
					>
						<Button
							onClick={() => setCreateDialogOpen(true)}
							data-testid="open-create-project-equipment-dialog"
						>
							<Plus className="mr-2 h-4 w-4" />
							{t('company.projectRules.addNewProjectRule')}
						</Button>
					</TKPageAppBar>
				}
				noScroll
			>
				<CompanyProjectRulesTable projectId={query.id} />
			</DashboardLayout>
			<CompanyProjectRuleAddDialog
				projectId={query.id}
				isOpen={createDialogOpen}
				setOpen={setCreateDialogOpen}
			/>
		</>
	);
}

export default CompanyProjectRulesContainer;
