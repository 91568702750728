import React from 'react';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ProjectRuleForm } from '@/types/company/projects';
import { toast } from 'sonner';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import CompanyProjectRuleForm from './CompanyProjectRuleForm';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

interface Props {
	isOpen: boolean;
	setOpen: (value: boolean) => void;
	projectId: string;
}

function CompanyProjectRuleAddDialog({ isOpen, setOpen, projectId }: Props) {
	const { t } = useTranslation();
	const { companyId } = useAuth();

	const insertMutation = trpc.projectRule.insert.useMutation({
		onSuccess() {
			toast.success(
				t('common.api.insert.success', {
					type: t('company.projectRules.base'),
				}),
			);

			setOpen(false);
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	const form = useForm<ProjectRuleForm>({
		defaultValues: {
			equipmentIds: [],
			fromTimestamp: new Date().toISOString(),
			toTimestamp: new Date().toISOString(),
			weekdays: [],
			allDay: true,
		},
	});

	function handleCreateRule(data: ProjectRuleForm) {
		insertMutation.mutate({
			companyId,
			projectId,
			equipmentIds: data.equipmentIds || [],
			weekdays: data.weekdays || [],
			fromTimestamp: data.fromTimestamp,
			toTimestamp: data.toTimestamp,
		});
	}

	return (
		<Dialog open={isOpen} onOpenChange={setOpen}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{t('company.projectRules.addNewProjectRule')}
					</DialogTitle>
				</DialogHeader>
				<CompanyProjectRuleForm form={form} />
				<DialogFooter className="sm:justify-end">
					<Button
						type="button"
						variant="outline"
						onClick={() => setOpen(false)}
					>
						{t('common.cancelText')}
					</Button>
					<TKConfirmButton
						onClick={form.handleSubmit(handleCreateRule)}
						type="button"
						dialogType="create"
						data-testid="confirm-project-rule-create-button"
						isLoading={insertMutation.isLoading}
					/>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}

export default CompanyProjectRuleAddDialog;
