/**
 * Retrieve the flag image path for a given country code
 *
 * @param {(string | undefined)} flagCode the country/alpha code
 * @return {*} a string with the path to the image, or undefined
 */
const flagByCountryCode = (flagCode: string | undefined) => {
	if (!flagCode) return;
	return `/flags/${flagCode.toLowerCase()}.png`;
};
export { flagByCountryCode };
