import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isValid, isSameDay, parse } from 'date-fns';
import { CalendarIcon } from 'lucide-react';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar } from '@/components/ui/calendar';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '@/components/ui/popover';
import { useDateFns } from '@/hooks/common/useDateFns';
import { Input } from '@/components/ui/input';
import { useMaskito } from '@maskito/react';
import maskitoOptions from '@/lib/maskito';

interface Props {
	minDate?: string | null | Date;
	maxDate?: string | null | Date;
	selectedDate: Date | null;
	handleDateChange: (date: Date | null) => void;
	allowedDays?: string[];
	format?: string;
	disableAllDays?: boolean;
	disabled?: boolean;
	disableFuture?: boolean;
	open?: boolean;
	onOpen?: (value: boolean) => void;
	variant?: 'default' | 'transparent';
}

function TKDatePicker({
	handleDateChange,
	selectedDate,
	allowedDays,
	format: customFormat,
	disableFuture = false,
	disableAllDays = false,
	minDate = null,
	maxDate = null,
	open,
	onOpen,
	disabled,
	variant = 'default',
	...restProps
}: Props): JSX.Element {
	const { i18n } = useTranslation();
	const { format } = useDateFns();
	const maskedInputRef = useMaskito({
		options: maskitoOptions,
	});

	const dateFormat = customFormat || getDefaultDateFormat(i18n.language);

	const disabledDays = useCallback(
		(date: Date | null) => {
			if (disableAllDays) return true;
			if (disableFuture && date && date > new Date()) return true;
			if (!allowedDays) return false;
			if (date) {
				const allowedDaysPlusToday = [...allowedDays, new Date().toString()];
				return !allowedDaysPlusToday.some((day) =>
					isSameDay(new Date(day), new Date(date)),
				);
			}
			return true;
		},
		[allowedDays, disableAllDays, disableFuture],
	);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const inputDate = parse(e.target.value, dateFormat, new Date());
		if (isValid(inputDate)) {
			handleDateChange(inputDate);
		} else {
			handleDateChange(null);
		}
	};

	const [calendarDate, setCalendarDate] = useState<Date | undefined>(
		selectedDate || undefined,
	);

	useEffect(() => {
		if (selectedDate) {
			setCalendarDate(selectedDate);
		}
	}, [selectedDate]);

	const handleCalendarSelect = (date: Date | undefined) => {
		setCalendarDate(date);
		handleDateChange(date ?? null);
	};

	return (
		<Popover open={open} onOpenChange={onOpen}>
			<div
				className={cn(
					'relative inline-block',
					variant === 'transparent' ? 'w-auto' : 'w-full',
				)}
			>
				<Input
					value={selectedDate ? format(selectedDate, dateFormat) : ''}
					onInput={handleInputChange}
					ref={maskedInputRef}
					className={cn(
						'pr-8',
						variant === 'transparent' &&
							'bg-transparent border-none text-background',
					)}
				/>
				<PopoverTrigger asChild>
					<Button
						variant="ghost"
						className={cn(
							'absolute right-0 top-1/2 -translate-y-1/2 h-full aspect-square p-0',
							variant === 'transparent' &&
								'text-background hover:bg-transparent',
						)}
						disabled={disabled}
					>
						<CalendarIcon
							className={cn(
								'h-4 w-4',
								variant === 'default' ? 'text-dark-500' : 'text-background',
							)}
						/>
					</Button>
				</PopoverTrigger>
			</div>
			<PopoverContent className="w-auto p-0">
				<Calendar
					mode="single"
					selected={calendarDate}
					onSelect={handleCalendarSelect}
					disabled={disabledDays}
					initialFocus
					captionLayout="dropdown-buttons"
					fromDate={minDate ? new Date(minDate) : undefined}
					toDate={
						maxDate ? new Date(maxDate) : disableFuture ? new Date() : undefined
					}
					defaultMonth={calendarDate || undefined}
					{...restProps}
				/>
			</PopoverContent>
		</Popover>
	);
}

function getDefaultDateFormat(language: string): string {
	switch (language) {
		case 'nb-NO':
			return 'dd.MM.yyyy';
		case 'en-US':
			return 'MM/dd/yyyy';
		default:
			return 'dd/MM/yyyy';
	}
}

export default TKDatePicker;
