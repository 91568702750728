import React from 'react';
import TKDatePicker from '@/components/FormControls/TKDatePicker';
import { useDetermineInterval } from '@/hooks/filters/useDetermineInterval';
import { useTranslation } from 'react-i18next';
import { DateFiltersValue } from './DateFilters';
import { useCompany } from '@/hooks/auth/useCompany';
import { endOfDay, endOfYear } from 'date-fns';
import { TypographySmall } from '@/components/ui/typography';
import { Label } from '@/components/ui/label';
import DescribeIcon from '@/components/Common/DescribeIcon';

interface Props {
	handleChange: (dateFilters: DateFiltersValue) => void;
	fromTime: string | null;
	toTime: string | null;
}

function DateFiltersCustom({ handleChange, fromTime, toTime }: Props) {
	const { data: company } = useCompany();
	const { t } = useTranslation();
	const determineInterval = useDetermineInterval();

	function handleStartDateChange(date: Date | null) {
		const newPartialFilters: DateFiltersValue = {
			fromTime: date ? date.toISOString() : null,
			dateType: 'custom',
		} as DateFiltersValue;
		handleChange({
			...newPartialFilters,
			interval: determineInterval(newPartialFilters),
		});
	}

	function handleEndDateChange(date: Date | null) {
		const newPartialFilters: DateFiltersValue = {
			toTime: date ? date.toISOString() : null,
			dateType: 'custom',
		} as DateFiltersValue;

		handleChange({
			...newPartialFilters,
			interval: determineInterval(newPartialFilters),
		});
	}

	return (
		<div className="w-full flex items-center flex-col">
			<div className="flex justify-center items-center">
				<TypographySmall className="font-semibold">
					{t('filters.date.customDate.text')}
				</TypographySmall>
				<DescribeIcon>{t('filters.date.customDate.description')}</DescribeIcon>
			</div>
			<div className="flex flex-col space-y-4 pt-2 pr-2">
				<div>
					<Label>{t('filters.date.customDate.from')}</Label>
					<TKDatePicker
						selectedDate={fromTime ? new Date(fromTime) : null}
						handleDateChange={handleStartDateChange}
						minDate={company?.createdAt}
						maxDate={endOfDay(new Date())}
					/>
				</div>
				<div>
					<Label>{t('filters.date.customDate.to')}</Label>
					<TKDatePicker
						selectedDate={toTime ? new Date(toTime) : null}
						handleDateChange={handleEndDateChange}
						minDate={company?.createdAt}
						maxDate={endOfYear(new Date())}
					/>
				</div>
			</div>
		</div>
	);
}

export default DateFiltersCustom;
