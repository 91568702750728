import React, { forwardRef, ForwardedRef } from 'react';

interface Props {
	children: React.ReactNode | React.ReactNode[];
}

const TKChartContainer = forwardRef(
	({ children }: Props, ref: ForwardedRef<HTMLDivElement>) => {
		return (
			<div
				ref={ref}
				className="max-h-[90%] h-[90%] w-full overflow-hidden flex flex-col flex-1"
			>
				{children}
			</div>
		);
	},
);
TKChartContainer.displayName = 'TKChartContainer';

export default TKChartContainer;
