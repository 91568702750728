import React, { useMemo, useState } from 'react';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import TKDrawer from '@/components/Drawer/TKDrawer';
import { DrawerProvider } from '@/contexts/Global/Drawer/DrawerContext';
import CompanyEnvironmentReport from './reports/CompanyEnvironmentReport';
import ReportSidebar from './ReportSidebar';
import { useTranslation } from 'react-i18next';
import OpterDeliveredOrdersReport from './reports/OpterDeliveredOrdersReport';
import { RouterOutputs, trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';

type Report = {
	id: string;
	component: React.ComponentType;
	requiresRemoteConfig: keyof RouterOutputs['remoteConfig']['list'] | false;
};
const REPORTS = [
	{
		id: 'company_environment_report',
		component: CompanyEnvironmentReport,
		requiresRemoteConfig: false,
	},
	{
		id: 'opter_delivered_orders_report',
		component: OpterDeliveredOrdersReport,
		requiresRemoteConfig: 'ENABLE_OPTER_ORDER_REPORT',
	},
] as const satisfies Report[];

export default function ReportPage() {
	const { companyId } = useAuth();
	const { t } = useTranslation();
	const { data: remoteConfig } = trpc.remoteConfig.list.useQuery({
		companyId: companyId,
	});

	const reports = useMemo(() => {
		return REPORTS.filter((report) => {
			if (report.requiresRemoteConfig) {
				return remoteConfig?.[report.requiresRemoteConfig] === true;
			}
			return true;
		});
	}, [remoteConfig]);

	const [activeReport, setActiveReport] = useState<string | null>(
		REPORTS[0].id,
	);

	const ActiveReportComponent = activeReport
		? REPORTS.find((report) => report.id === activeReport)?.component
		: null;

	return (
		<DashboardLayout disablePadding>
			<DrawerProvider>
				<div className="flex h-full w-full overflow-hidden">
					<TKDrawer hideToggleOnDesktop>
						<ReportSidebar
							activeReportId={activeReport}
							setActiveReportId={setActiveReport}
							reports={reports.map((report) => ({
								id: report.id,
								name: t(`analysis.reports.${report.id}.title`),
							}))}
						/>
					</TKDrawer>
					<div className="flex-1 overflow-hidden">
						{!!ActiveReportComponent && <ActiveReportComponent />}
					</div>
				</div>
			</DrawerProvider>
		</DashboardLayout>
	);
}
