import { useState, useEffect } from 'react';

type UseDebounceHook = <T>(value: T, delay: number) => T;

/**
 * Debounces the given `value` by a given `delay` (in milliseconds).
 *
 * @param {*} value value to watch and debounce
 * @param {*} delay debounces the value by this amount
 * @return {*} the debounced `value`
 */
const useDebounce: UseDebounceHook = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
};
export { useDebounce };
