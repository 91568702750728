import React from 'react';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useTranslation } from 'react-i18next';
import CompanyProjectRuleForm from './CompanyProjectRuleForm';
import { useForm } from 'react-hook-form';
import { ProjectRuleForm } from '@/types/company/projects';
import { toast } from 'sonner';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { CenteredSpinner } from '@/components/ui/spinner';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';

interface Props {
	isOpen: boolean;
	setOpen: (value: boolean) => void;
	ruleId: string;
	projectId: string;
}

function CompanyProjectRuleUpdateDialog({ isOpen, setOpen, ruleId }: Props) {
	const { t } = useTranslation();
	const { companyId } = useAuth();

	const form = useForm<ProjectRuleForm>({
		defaultValues: {
			allDay: true,
		},
	});
	const updateMutation = trpc.projectRule.updateById.useMutation({
		onSuccess() {
			toast.success(
				t('common.api.update.success', {
					type: t('company.projectRules.base'),
				}),
			);

			form.reset();
			setOpen(false);
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	const { data } = trpc.projectRule.byId.useQuery(
		{ companyId, ruleId },
		{
			onSuccess: (data) => {
				form.reset({
					...data,
				});
			},
		},
	);

	function handleUpdateRule(data: ProjectRuleForm) {
		updateMutation.mutate({
			companyId,
			equipmentIds: data.equipmentIds || [],
			ruleId,
			fromTimestamp: data.fromTimestamp,
			toTimestamp: data.toTimestamp,
			weekdays: data.weekdays ?? [],
		});
	}

	return (
		<Dialog open={isOpen} onOpenChange={setOpen}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{t('company.projectRules.updateRule')}</DialogTitle>
				</DialogHeader>
				{data ? <CompanyProjectRuleForm form={form} /> : <CenteredSpinner />}
				<DialogFooter>
					<Button
						type="button"
						variant="outline"
						onClick={() => setOpen(false)}
					>
						{t('common.cancelText')}
					</Button>
					<TKConfirmButton
						onClick={form.handleSubmit(handleUpdateRule)}
						type="button"
						data-testid="confirm-project-rule-update-button"
						dialogType="update"
						isLoading={updateMutation.isLoading}
					/>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
}

export default CompanyProjectRuleUpdateDialog;
