import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../ui/button';

type DialogType = 'create' | 'update' | 'delete';
interface TKConfirmButtonProps
	extends React.ComponentPropsWithoutRef<typeof Button> {
	dialogType: DialogType | 'confirm' | 'import' | 'share';
}

function TKConfirmButton({
	onClick,
	disabled,
	isLoading,
	dialogType,
	...restProps
}: TKConfirmButtonProps) {
	const { t } = useTranslation();

	const translations = {
		choose: t('common.confirmSelectText'),
		create: t('common.confirmCreateText'),
		delete: t('common.confirmDeleteText'),
		update: t('common.confirmUpdateText'),
		confirm: t('common.confirmText'),
		import: t('common.confirmImportText'),
		share: t('common.shareText'),
	};
	const color = dialogType === 'delete' ? 'error' : 'primary';

	return (
		<Button
			{...restProps}
			color={color}
			onClick={onClick}
			disabled={disabled}
			isLoading={isLoading}
		>
			{translations[dialogType]}
		</Button>
	);
}
export default TKConfirmButton;
