import TKTextWithLabel from '@/components/Common/TKTextWithLabel';
import { useTranslation } from 'react-i18next';
import React from 'react';

interface Props {
	sampledAt?: string | null;
	kmH?: number | null;
}

function CurrentSpeedField({ sampledAt, kmH }: Props) {
	const { t } = useTranslation();

	const text =
		typeof kmH === 'number'
			? `${kmH} ${t('common.unit.km_h.short')}`
			: t('common.notAvailable');

	return (
		<TKTextWithLabel
			label={t('equipment.columns.currentSpeed')}
			timestamp={sampledAt}
			text={text}
		/>
	);
}

export default CurrentSpeedField;
