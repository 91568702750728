import type { MaskitoOptions } from '@maskito/core';
import {
	MaskitoDateMode,
	maskitoDateOptionsGenerator,
	maskitoWithPlaceholder,
} from '@maskito/kit';
import i18n from './i18nInstance';

const placeholder =
	i18n.language === 'en-US'
		? 'mm/dd/yyyy'
		: i18n.language === 'nb-NO'
			? 'dd.mm.yyyy'
			: 'dd/mm/yyyy';

const dateOptions = maskitoDateOptionsGenerator({
	mode: placeholder as MaskitoDateMode,
	separator: i18n.language === 'nb-NO' ? '.' : '/',
});

const { plugins, ...placeholderOptions } = maskitoWithPlaceholder(
	placeholder,
	true,
);

export default {
	...dateOptions,
	plugins: plugins.concat(dateOptions.plugins || []),
	preprocessors: [
		// Always put it BEFORE all other preprocessors
		...placeholderOptions.preprocessors,
		...dateOptions.preprocessors,
	],
	postprocessors: [
		...dateOptions.postprocessors,
		// Always put it AFTER all other postprocessors
		...placeholderOptions.postprocessors,
	],
} as Required<MaskitoOptions>;
