import React, { useMemo, memo } from 'react';
import FilterBase, { FilterBaseVariant } from '../FilterBase';
import MultistepListCheckbox, {
	CheckboxList,
	MultistepItem,
} from '@/components/FormControls/MultistepListCheckbox';
import { Users } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import GroupFiltersTitle from './GroupFiltersTitle';
import { useGroups } from '@/hooks/data/useGroups';

type GroupFilterType = string[] | null;

export interface GroupFiltersValue {
	groupIds: GroupFilterType;
	hideDataFromWithoutGroups: boolean;
}

interface GroupFiltersProps extends GroupFiltersValue {
	handleChange: (newValues: GroupFiltersValue) => void;
}

function GroupFilters({
	groupIds,
	hideDataFromWithoutGroups,
	handleChange,
	variant,
}: GroupFiltersProps & FilterBaseVariant): JSX.Element {
	const { t } = useTranslation();
	const { groups, isLoading } = useGroups();

	const allGroupsSelected = groupIds === null;
	const data: CheckboxList = useMemo(() => {
		const noGroupOption: MultistepItem = {
			active: !hideDataFromWithoutGroups,
			label: t('filters.groups.withoutGroups'),
			searchFields: [t('filters.groups.withoutGroups')],
			value: 'hideDataFromWithoutGroups',
			category: 'hideDataFromWithoutGroups',
		};
		const selectedGroups: MultistepItem[] = groups.map((group) => {
			const active = groupIds ? groupIds.includes(group.id) : false;

			return {
				label: group.name,
				searchFields: [group.name],
				value: group.id,
				active: active || allGroupsSelected,
				category: 'groupIds',
			};
		});
		selectedGroups.unshift(noGroupOption);

		return {
			title: t('filters.groups.title'),
			items: selectedGroups,
		};
	}, [hideDataFromWithoutGroups, allGroupsSelected, groupIds, groups, t]);

	const handleSelect = (item: MultistepItem) => {
		const groupFilters: GroupFiltersValue = {
			hideDataFromWithoutGroups,
			groupIds,
		};
		const allGroupIds = groups.map(({ id }) => id);

		if (item.category === 'hideDataFromWithoutGroups') {
			groupFilters.hideDataFromWithoutGroups =
				!groupFilters.hideDataFromWithoutGroups;
		}

		if (item.category === 'groupIds' && typeof item.value === 'string') {
			const listToAdd = allGroupsSelected ? allGroupIds : groupIds || [];
			const groupsSet = new Set<string>(listToAdd);

			if (groupsSet.has(item.value)) {
				groupsSet.delete(item.value);
			} else {
				groupsSet.add(item.value);
			}
			groupFilters.groupIds = [...groupsSet];
		}

		const hasAllSelected =
			groupFilters.groupIds?.length === groups.length ||
			groupFilters.groupIds === null;

		if (groupFilters.hideDataFromWithoutGroups && hasAllSelected) {
			groupFilters.groupIds = [...allGroupIds];
		}

		handleChange(groupFilters);
	};

	const handleSelectAll = () => {
		handleChange({
			groupIds: null,
			hideDataFromWithoutGroups: false,
		});
	};

	const handleDeselectAll = () => {
		handleChange({
			groupIds: [],
			hideDataFromWithoutGroups: true,
		});
	};

	return (
		<FilterBase
			variant={variant}
			iconButton={Users}
			buttonText={
				<GroupFiltersTitle
					isLoading={isLoading}
					hideDataFromWithoutGroups={hideDataFromWithoutGroups}
					groupIds={groupIds}
					dataLength={groups.length}
				/>
			}
			dataTestButton="open-groups-filters-button"
			dataTestContent="groups-filters-content"
		>
			<div className="w-[280px] h-full">
				<MultistepListCheckbox
					handleSelect={handleSelect}
					data={data}
					description={t('filters.groups.description')}
					handleSelectAll={handleSelectAll}
					handleDeselectAll={handleDeselectAll}
					noDataText={t('filters.groups.noData')}
					noDataWithSearchText={t('filters.groups.noDataWithSearch')}
				/>
			</div>
		</FilterBase>
	);
}

export default memo(GroupFilters);
