import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
	Dialog,
	DialogContent,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import { Role } from '@/types/common/roles';
import { CompanyUserFormData } from '@/types/company/users';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import {
	Form,
	FormField,
	FormItem,
	FormLabel,
	FormControl,
	FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import { EMAIL_REGEX } from '@/utils/common/validators';

const allowedRoles: Role[] = [Role.Owner, Role.Manager];

interface Props {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	companyId: number;
}

function CreateEmailInvitationFormDialog({ open, setOpen, companyId }: Props) {
	const { t } = useTranslation();

	const form = useForm<CompanyUserFormData>({
		defaultValues: { role: Role.Manager, email: '' },
	});

	const inviteMutation = trpc.invitation.send.useMutation({
		onSuccess() {
			toast.success(t('company.users.inviteSuccess'));
			form.reset();
			closeDialog();
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	function closeDialog() {
		setOpen(false);
	}

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent>
				<DialogTitle>{t('company.users.createUserDialog.title')}</DialogTitle>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit((data) => {
							inviteMutation.mutate({
								...data,
								companyId,
							});
						})}
						className="space-y-4"
					>
						<FormField
							control={form.control}
							name="email"
							rules={{
								required: {
									value: true,
									message: t('validations.email.required'),
								},
								pattern: {
									message: t('validations.email.pattern'),
									value: EMAIL_REGEX,
								},
							}}
							render={({ field }) => (
								<FormItem>
									<FormLabel>{t('validations.email.label')}</FormLabel>
									<FormControl>
										<Input {...field} type="email" data-testid="user-email" />
									</FormControl>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							control={form.control}
							name="role"
							rules={{ required: true }}
							render={({ field }) => (
								<FormItem>
									<FormLabel>{t('company.users.table.role')}</FormLabel>
									<Select
										onValueChange={field.onChange}
										defaultValue={field.value}
									>
										<FormControl>
											<SelectTrigger>
												<SelectValue
													placeholder={t('company.users.table.role')}
												/>
											</SelectTrigger>
										</FormControl>
										<SelectContent>
											{allowedRoles.map((role) => (
												<SelectItem value={role} key={`select_${role}`}>
													{t(`shared-roles.${role}`)}
												</SelectItem>
											))}
										</SelectContent>
									</Select>
									<FormMessage />
								</FormItem>
							)}
						/>
						<DialogFooter>
							<Button type="button" variant="outline" onClick={closeDialog}>
								{t('common.cancelText')}
							</Button>
							<TKConfirmButton
								isLoading={inviteMutation.isLoading}
								variant="default"
								dialogType="create"
								data-testid="confirm-user"
							/>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
}

export default CreateEmailInvitationFormDialog;
