import React, { memo } from 'react';

import TKWidgetHeader from '@/components/WidgetSystem/TKWidgetHeader';
import ReportChartChangeHeader from './Reports/ReportChartChangeHeader';
import ReportWidgetHeader from './Reports/ReportWidgetHeader';
import {
	ReportInterval,
	ReportTemplate,
} from '@/hooks/analysis/useReportsTemplatesList';
import { FiltersConfig, ReportChartTypes, ReportUnit } from '@/types/analysis';
import { useHandleChartType } from '@/hooks/analysis/useHandleChartType';
import { useReportsDataAsChartDatasets } from '@/hooks/analysis/useReportsDataAsChartDatasets';
import { getActiveComparison } from '@/components/FilterDropdowns/CompareFilters/getActiveComparison';
import { useExecuteReport } from '@/hooks/analysis/useExecuteReport';
import { useTranslation } from 'react-i18next';
import { useReportsColumns } from '@/hooks/analysis/useReportsColumns';
import { useExportDataTable } from '@/hooks/common/useExportDataTable';
import { LineChart } from '@/components/Charts/LineChart';
import WidgetDataHandler from '@/components/WidgetSystem/WidgetDataHandler';
import { AreaChart } from '@/components/Charts/AreaChart';
import { calculateFilterDates } from '@/utils/analysis/calculateFilterDates';
import TableChart from '@/components/Charts/TableChart';
import TextCardChart from '@/components/Charts/TextCardChart';

interface ReportWidgetTemplateProps {
	reportId: number;
	dashboardId: number;
	template: ReportTemplate;
	isEditable: boolean;
	filters: FiltersConfig;
}

function ReportWidgetTemplate({
	reportId,
	dashboardId,
	isEditable,
	template,
	filters,
}: ReportWidgetTemplateProps) {
	const { t, i18n } = useTranslation();

	const comparison = getActiveComparison(filters);
	const { fromTime, toTime } = calculateFilterDates(filters);

	const chart = useHandleChartType(reportId, comparison);
	const { chartType, handleChangeChartType } = chart;

	const filtersOverrides: Partial<FiltersConfig> = {
		interval:
			chartType === 'graph' ? filters.interval : ReportInterval.NoInterval,
	};

	const {
		data = [],
		isLoading,
		error,
	} = useExecuteReport({
		reportTypeId: template.reportTypeId,
		filters,
		filtersOverrides,
		isEnabled: true,
	});

	const aggregateValueText = getTranslatedUnit(template.unit, data?.[0]?.value);

	function getTranslatedUnit(unit: ReportUnit, amount: unknown): string | null {
		if (amount === undefined || amount === null) return null;

		const value = Number(amount) || 0;
		return t(`shared-reports.units.${unit}`, {
			amount: value.toLocaleString(i18n.language),
			count: value,
		});
	}

	function formatNumberByUnit(value: number | null) {
		return getTranslatedUnit(template.unit, value) ?? '';
	}

	const columns = useReportsColumns(template, comparison, formatNumberByUnit);
	const datasets = useReportsDataAsChartDatasets(
		data,
		filters,
		template.chartLabel,
	);

	const { exportToCsv, exportToExcel } = useExportDataTable(
		data,
		columns,
		template.name,
	);

	const showLineChart =
		chart.displayChartType === ReportChartTypes.Graph && comparison !== 'total';
	const showAreaChart =
		chart.displayChartType === ReportChartTypes.Graph && comparison === 'total';
	const showListChart = chart.displayChartType === ReportChartTypes.List;
	const showCardChart = chart.displayChartType === ReportChartTypes.Card;

	return (
		<div className="w-full h-full">
			<TKWidgetHeader title={template.name}>
				<ReportChartChangeHeader
					handleChangeChartType={handleChangeChartType}
					chartType={chartType}
					reportId={reportId}
				/>
				<ReportWidgetHeader
					dashboardId={dashboardId}
					name={template.name}
					loading={isLoading}
					chartType={chartType}
					canEditDashboard={isEditable}
					reportId={reportId}
					handleExportToCsv={exportToCsv}
					handleExportToExcel={exportToExcel}
				/>
			</TKWidgetHeader>
			<WidgetDataHandler
				loading={isLoading}
				data={data?.length ? data : undefined}
				error={error}
				enableErrorBoundary
			>
				{showLineChart && (
					<LineChart
						id={reportId}
						minTime={fromTime || undefined}
						maxTime={toTime || undefined}
						syncId="analysis-chart"
						datasets={datasets}
						timeUnit={filtersOverrides.interval ?? filters.interval}
						tooltipLabel={formatNumberByUnit}
					/>
				)}
				{showAreaChart && (
					<AreaChart
						id={reportId}
						minTime={fromTime || undefined}
						maxTime={toTime || undefined}
						syncId="analysis-chart"
						datasets={datasets}
						timeUnit={filtersOverrides.interval ?? filters.interval}
						tooltipLabel={formatNumberByUnit}
					/>
				)}
				{showListChart && <TableChart columns={columns} data={data} />}
				{showCardChart && <TextCardChart text={aggregateValueText} />}
			</WidgetDataHandler>
		</div>
	);
}

export default memo(ReportWidgetTemplate);
