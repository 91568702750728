import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterComparisons } from '@/components/FilterDropdowns/CompareFilters/getActiveComparison';
import { useDateFns } from '@/hooks/common/useDateFns';
import { ReportTemplate } from './useReportsTemplatesList';
import { DataColumn } from '@/components/Table/data-table';
import { ReportExecuteResult } from '@/types/analysis';

function useReportsColumns(
	template: ReportTemplate,
	comparison: FilterComparisons,
	formatNumberByUnit: (data: number | null) => string | null,
): DataColumn<ReportExecuteResult[number]>[] {
	const { format } = useDateFns();
	const { t } = useTranslation();

	return useMemo(() => {
		const cols: DataColumn<ReportExecuteResult[number]>[] = [
			{
				id: `equipmentLabel`,
				title: t(`analysis.chooseReport.equipmentFilter.label`),
				hidden: comparison !== 'equipment',
			},
			{
				id: `projectsLabel`,
				title: t(`analysis.chooseReport.projectFilter.label`),
				hidden: comparison !== 'projects',
			},
			{
				id: `driversLabel`,
				title: t(`analysis.chooseReport.driverFilter.label`),
				hidden: comparison !== 'drivers',
			},
			{
				id: 'driverIds',
				title: t('analysis.type'),
				hidden: comparison !== 'total',
				render: () => template.name,
			},

			{
				id: 'value',
				title: template.chartLabel,
				enableSorting: true,
				render: (data) => {
					return formatNumberByUnit(data.value);
				},
				accessorFn: (data) => data.value,
			},
			{
				id: 'periodStart',
				title: t('analysis.chooseReport.timestamp'),
				enableSorting: true,
				render: (data) => {
					return format(new Date(data.periodStart), 'P');
				},
			},
		];
		return cols;
	}, [
		comparison,
		format,
		formatNumberByUnit,
		t,
		template.chartLabel,
		template.name,
	]);
}
export { useReportsColumns };
