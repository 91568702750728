import React, { useEffect, useRef } from 'react';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { addSeconds, differenceInSeconds } from 'date-fns';
import { PackageOpen } from 'lucide-react';
import { useTranslation } from 'react-i18next';

export function useAutoPageRefresh() {
	const { t } = useTranslation();
	const lastDeploymentAt = trpc.healthz.lastDeploymentAt.useQuery(undefined, {
		refetchInterval: 1000 * 60 * 1,
	});

	const notificationInProgressRef = useRef(false);
	const backendDeploymentTimestamp = lastDeploymentAt.data?.timestamp;
	const currentFrontendDeploymentTimestamp = Number(
		import.meta.env.VITE_LAST_DEPLOYMENT_AT,
	);

	const isNewDeployment =
		currentFrontendDeploymentTimestamp &&
		backendDeploymentTimestamp &&
		backendDeploymentTimestamp !== currentFrontendDeploymentTimestamp;

	useEffect(() => {
		if (isNewDeployment && !notificationInProgressRef.current) {
			notificationInProgressRef.current = true;

			const startTime = Date.now();
			const toastId = toast.info(
				t('common.autoPageRefreshNewVersion', {
					seconds: 10,
				}),
				{
					duration: 10000,
					dismissible: false,
					important: true,
					icon: <PackageOpen className="size-5" />,
				},
			);
			const interval = setInterval(() => {
				const remainingTime = differenceInSeconds(
					addSeconds(startTime, 10),
					Date.now(),
				);
				toast.message(
					t('common.autoPageRefreshNewVersion', {
						seconds: remainingTime,
					}),
					{
						id: toastId,
						icon: <PackageOpen className="size-5" />,
					},
				);

				if (remainingTime < 1) {
					clearInterval(interval);
					window.location.reload();
				}
			}, 1000);
		}
	}, [isNewDeployment, t]);
}
