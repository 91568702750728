import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';

export interface UserProfile {
	address: string;
	city: string;
	email: string;
	emailInvitationsEnabled: boolean;
	firstName: string;
	gender: 'M' | 'F';
	hasConsentedPrivacyPolicy: boolean;
	hasConsentedTermsOfService: boolean;
	isSuperuser: boolean;
	lastName: string;
	phone: string;
	smsNotificationsEnabled: boolean;
	uid: string;
	zipCode: string;
}

export function useUserProfile() {
	const { user } = useAuth();

	const isProfileFinished = trpc.user.isProfileFinished.useQuery(undefined, {
		enabled: !!user,
		staleTime: 1000 * 60 * 5,
		retry: false,
		refetchOnWindowFocus: false,
	});

	const query = trpc.user.me.useQuery(undefined, {
		enabled: isProfileFinished.data?.hasUserProfile === true && !!user,
		staleTime: 1000 * 60 * 5,
		retry: false,
		refetchOnWindowFocus: false,
	});
	return {
		...query,
		enabled: !!user,
		isInitialLoading:
			isProfileFinished.isInitialLoading || query.isInitialLoading,
		isError: isProfileFinished.isError || query.isError,
		error: isProfileFinished.error || query.error,
	};
}
