import React, { useState } from 'react';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useTranslation } from 'react-i18next';
import TKWidgetHeader from '@/components/WidgetSystem/TKWidgetHeader';
import WidgetDataHandler from '@/components/WidgetSystem/WidgetDataHandler';
import {
	addDays,
	endOfDay,
	isSameDay,
	isToday,
	startOfDay,
	subDays,
} from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useCompany } from '@/hooks/auth/useCompany';
import TKDatePicker from '@/components/FormControls/TKDatePicker';
import { useExportDataTable } from '@/hooks/common/useExportDataTable';
import { useDateFns } from '@/hooks/common/useDateFns';
import TKChartContainer from '@/components/Charts/TKChartContainer';
import { DataTable } from '@/components/Table/data-table';
import { Button } from '@/components/ui/button';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { HomepageWidgetBaseCard } from '../HomepageWidgetBaseCard';
import HomepageWidgetHeader from '../HomepageWidgetHeader';

function DriverCardActivityReport(): JSX.Element {
	const { format } = useDateFns();
	const { companyId } = useAuth();
	const { data: company } = useCompany();
	const { t } = useTranslation();
	const [day, setDay] = useState(() => new Date());

	const fromTime = startOfDay(day).toISOString();
	const toTime = endOfDay(day).toISOString();
	const {
		data = [],
		isLoading,
		error,
	} = trpc.widget.executeDriverCardActivity.useQuery(
		{
			companyId,
			fromTime,
			toTime,
		},
		{
			select(data) {
				return data.map((item) => ({
					...item,
					fromTime: item.fromTime
						? new Date(item.fromTime).toISOString()
						: null,
					toTime: item.toTime ? new Date(item.toTime).toISOString() : null,
				}));
			},
		},
	);

	const title = t('analysis.reports.driver_card_activity.title');

	const { exportToCsv, exportToExcel } = useExportDataTable(
		data,
		[
			{
				id: 'driverFullname',
				title: t('equipment.columns.currentDriver'),
			},
			{
				id: 'fromTime',
				title: t('analysis.reports.driver_card_activity.firstCheckin'),
				render: (row) => {
					if (!row.fromTime) return '';
					return format(new Date(row.fromTime), 'Pp');
				},
			},
			{
				id: 'toTime',
				title: t('analysis.reports.driver_card_activity.lastCheckout'),
				render: (row) => {
					if (!row.toTime) return '';
					return format(new Date(row.toTime), 'Pp');
				},
			},
		],
		`${title}_${format(day, 'Pp')}`,
	);

	const isMaxDate = isToday(day);
	const isMinDate = isSameDay(day, new Date(company?.createdAt ?? Date.now()));

	function onNextDay() {
		// Prevent going to the future
		if (!isMaxDate) {
			setDay((prev) => addDays(prev, 1));
		}
	}
	function onPrevDay() {
		if (!isMinDate) {
			setDay((prev) => subDays(prev, 1));
		}
	}

	function changeDay(date: Date | null) {
		if (date) setDay(date);
	}

	return (
		<HomepageWidgetBaseCard>
			<TKWidgetHeader title={title}>
				<div className="flex items-center">
					<div className="flex items-center">
						<Button
							variant="ghost"
							size="icon"
							onClick={onPrevDay}
							disabled={isMinDate}
						>
							<ChevronLeft className="h-4 w-4" />
						</Button>
						<TKDatePicker
							handleDateChange={changeDay}
							selectedDate={day}
							minDate={company?.createdAt}
							maxDate={endOfDay(new Date()).toISOString()}
						/>
						<Button
							variant="ghost"
							size="icon"
							onClick={onNextDay}
							disabled={isMaxDate}
						>
							<ChevronRight className="h-4 w-4" />
						</Button>
					</div>

					<HomepageWidgetHeader
						handleExportToCsv={exportToCsv}
						handleExportToExcel={exportToExcel}
						loading={isLoading}
					/>
				</div>
			</TKWidgetHeader>
			<WidgetDataHandler data={data} loading={isLoading} error={error}>
				<TKChartContainer>
					<DataTable
						isLoading={isLoading}
						error={error}
						defaultSortColumn="fromTime"
						defaultSortOrder="asc"
						wrapperClassName="p-0 border-none"
						columns={[
							{
								id: 'driverFullname',
								title: t('equipment.columns.currentDriver'),
								render: (row) => {
									const names = row.driverFullname.split(' ');
									const firstName = names[0];
									const lastName = names.pop()!;

									return (
										<div className="flex items-center gap-2">
											<Avatar className="h-8 w-8">
												<AvatarFallback>
													{firstName[0]}
													{lastName[0]}
												</AvatarFallback>
											</Avatar>
											<span>{row.driverFullname}</span>
										</div>
									);
								},
							},
							{
								id: 'fromTime',
								title: t('analysis.reports.driver_card_activity.firstCheckin'),
								enableSorting: true,
								render: (row) => {
									if (!row.fromTime) return '';
									return format(new Date(row.fromTime), 'Pp');
								},
							},
							{
								id: 'toTime',
								title: t('analysis.reports.driver_card_activity.lastCheckout'),
								enableSorting: true,
								render: (row) => {
									if (!row.toTime) return '';
									return format(new Date(row.toTime), 'Pp');
								},
							},
						]}
						data={data}
					/>
				</TKChartContainer>
			</WidgetDataHandler>
		</HomepageWidgetBaseCard>
	);
}

export default DriverCardActivityReport;
