import React, { useState } from 'react';
import CompanyUsersTable from './CompanyUsersTable';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import CreateEmailInvitationFormDialog from './CreateEmailInvitationFormDialog';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/contexts/Global/AuthContext';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { CompanySettingsLayout } from '@/components/Layouts/DashboardLayout/CompanySettingsLayout';

function CompanyUsersContainer(): JSX.Element {
	const { companyId } = useAuth();
	const { t } = useTranslation();

	const [showCreateDialog, setShowCreateDialog] = useState(false);

	return (
		<CompanySettingsLayout>
			<DashboardLayout
				disablePadding
				AppBarComponent={
					<TKPageAppBar title={t('routes.companyUsers')}>
						<Button
							onClick={() => setShowCreateDialog(true)}
							data-testid="open-create-company-user-dialog"
						>
							<Plus className="mr-2 h-4 w-4" />
							{t('company.users.createNewUser')}
						</Button>
					</TKPageAppBar>
				}
				noScroll
			>
				<CompanyUsersTable />
			</DashboardLayout>
			<CreateEmailInvitationFormDialog
				open={showCreateDialog}
				setOpen={setShowCreateDialog}
				companyId={companyId}
			/>
		</CompanySettingsLayout>
	);
}

export default CompanyUsersContainer;
