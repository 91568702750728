import { FiltersConfig, ReportExecuteResult } from '@/types/analysis';
import { useMemo } from 'react';

export type DatasetGroups = {
	name: string;
	data: {
		y: number | null;
		x: number;
	}[];
}[];

export function useReportsDataAsChartDatasets(
	data: ReportExecuteResult,
	filters: FiltersConfig,
	defaultLabel: string,
) {
	/**
	 * Groups the datasets by driver, equipment and project
	 */
	return useMemo(() => {
		const fieldsToGroupBy: string[] = [];
		if (!filters.groupEquipment) {
			fieldsToGroupBy.push('equipmentIds');
		}
		if (!filters.groupDrivers) {
			fieldsToGroupBy.push('driverIds');
		}
		if (!filters.groupProjects) {
			fieldsToGroupBy.push('projectIds');
		}

		const groupedData = data.reduce(
			(groups, record) => {
				const groupKey = fieldsToGroupBy
					.map((field) => JSON.stringify(record[field as keyof typeof record]))
					.sort((a, b) => a.localeCompare(b))
					.join(',');

				if (!groups[groupKey]) {
					groups[groupKey] = [];
				}

				groups[groupKey].push(record);

				return groups;
			},
			{} as Record<string, ReportExecuteResult>,
		);

		const chartDatasets = Object.values(groupedData).map((group) => {
			let name = defaultLabel;
			if (!filters.groupDrivers) {
				name = group[0].driversLabel;
			}
			if (!filters.groupEquipment) {
				name = group[0].equipmentLabel;
			}
			if (!filters.groupProjects) {
				name = group[0].projectsLabel;
			}

			const data = group.map((record) => ({
				x: new Date(record.periodStart).getTime(),
				y: Number(record.value),
			}));

			return {
				name,
				data,
			};
		});

		return chartDatasets;
	}, [
		data,
		filters.groupDrivers,
		filters.groupEquipment,
		filters.groupProjects,
		defaultLabel,
	]);
}
