import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bell } from 'lucide-react';

import ChangePassword from './ChangePassword';
import { Card, CardHeader, CardContent, CardTitle } from '@/components/ui/card';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';
import { useUserProfile } from '@/hooks/auth/useUserProfile';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { TypographyBold } from '@/components/ui/typography';

const AccountSettings: React.FC = () => {
	const { t } = useTranslation();

	const trpcContext = trpc.useUtils();
	const userProfile = useUserProfile();

	const updateUserMutation = trpc.user.updatePreferences.useMutation({
		async onMutate() {
			await trpcContext.user.me.cancel();
			const prevUser = trpcContext.user.me.getData();

			trpcContext.user.me.setData(undefined, (data) => {
				if (!data) return undefined;
				return {
					...data,
					smsNotificationsEnabled: !userProfile.data?.smsNotificationsEnabled,
				};
			});

			toast.success(
				t('common.api.update.success', {
					type: t('userProfile.notificationSettings.base'),
				}),
			);
			return { prevUser };
		},
		onError(error, _, context) {
			toast.error(error.message);
			trpcContext.user.me.setData(undefined, (context as any)?.prevUser);
		},
		onSettled() {},
	});

	return (
		<Card>
			<CardHeader>
				<CardTitle>{t('userProfile.accountSettings.title')}</CardTitle>
			</CardHeader>
			<CardContent>
				<div className="space-y-6">
					<div>
						<TypographyBold>
							{t('userProfile.notificationSettings.title')}
						</TypographyBold>
						{userProfile.data && (
							<div className="flex items-center space-x-2">
								<Switch
									id="sms-notifications"
									checked={userProfile.data.smsNotificationsEnabled}
									onCheckedChange={(checked) =>
										updateUserMutation.mutate({
											uid: userProfile.data.uid,
											smsNotificationsEnabled: checked,
										})
									}
									data-testid="sms-notifications-switch"
								/>
								<Label
									htmlFor="sms-notifications"
									className="flex items-center space-x-2"
								>
									<Bell className="h-5 w-5" />
									<span>
										{t('userProfile.notificationSettings.optOutEmail')}
									</span>
								</Label>
							</div>
						)}
					</div>
					<ChangePassword />
				</div>
			</CardContent>
		</Card>
	);
};

export default AccountSettings;
