import React, { useMemo, memo } from 'react';
import FilterBase, {
	FilterBaseVariant,
} from '@/components/FilterDropdowns/FilterBase';
import { useTranslation } from 'react-i18next';
import { Truck } from 'lucide-react';
import MultistepListCheckbox, {
	CheckboxList,
	MultistepItem,
} from '@/components/FormControls/MultistepListCheckbox';
import { useAuth } from '@/contexts/Global/AuthContext';
import { trpc } from '@/api/trpc';
import { EquipmentTypeEnum } from '@/types/equipment';
import { endOfDay } from 'date-fns';
import { TypographyP } from '@/components/ui/typography';

type EquipmentFilterType = string[] | null;
interface EquipmentFiltersValue {
	equipmentIds: EquipmentFilterType;
}
interface EquipmentFiltersProps extends EquipmentFiltersValue {
	handleChange: (newValues: EquipmentFiltersValue) => void;
}

function useEquipmentFiltersTitle(equipmentIds: string[] | null) {
	const { t } = useTranslation();

	if (equipmentIds === null) {
		return t('filters.equipment.all');
	}

	const total = equipmentIds.length;
	return t('filters.equipment.base', { count: total });
}

function EquipmentFilters({
	equipmentIds,
	handleChange,
	variant,
}: EquipmentFiltersProps & FilterBaseVariant): JSX.Element {
	const { t } = useTranslation();
	const title = useEquipmentFiltersTitle(equipmentIds);
	const { companyId } = useAuth();

	const { data: rawEquipment } = trpc.equipment.list.useQuery(
		{
			companyId,
			types: [EquipmentTypeEnum.TRUCK, EquipmentTypeEnum.MACHINE],
			includeSold: endOfDay(new Date()).toISOString(),
		},
		{ enabled: !!companyId },
	);

	const equipmentData = useMemo(() => rawEquipment ?? [], [rawEquipment]);

	const allEquipmentIds = equipmentData?.map((equipment) => equipment.id);

	const allEquipmentSelected = equipmentIds === null;

	const equipment = useMemo(() => {
		const items: Record<string, MultistepItem[]> = {
			TRUCK: [],
			MACHINE: [],
		};

		// Categorize equipment by type
		equipmentData.forEach((equipment) => {
			const active = equipmentIds ? equipmentIds.includes(equipment.id) : false;

			const item: MultistepItem = {
				label: equipment.displayName || equipment.id,
				value: equipment.id,
				searchFields: [
					equipment.displayName || '',
					equipment?.registrationNumber || '',
					equipment.serialNumber,
				],
				active: active || allEquipmentSelected,
				category: 'equipmentIds',
			};

			items[equipment.type].push(item);
		});
		return items;
	}, [allEquipmentSelected, equipmentData, equipmentIds]);

	const data: CheckboxList = useMemo(() => {
		return {
			title: t('filters.equipment.title'),
			items: [
				{
					title: t('filters.equipment.vehicles'),
					label: t('filters.equipment.vehicles'),
					value: EquipmentTypeEnum.TRUCK,
					active: allEquipmentSelected,
					category: EquipmentTypeEnum.TRUCK,
					items: equipment.TRUCK,
				},

				{
					title: t('filters.equipment.machines'),
					label: t('filters.equipment.machines'),
					value: EquipmentTypeEnum.MACHINE,
					category: EquipmentTypeEnum.MACHINE,
					active: allEquipmentSelected,
					items: equipment.MACHINE,
				},
			].filter((item) => item.items.length > 0),
		};
	}, [allEquipmentSelected, equipment.MACHINE, equipment.TRUCK, t]);

	/**
	 * Handle selection of individual items
	 *
	 */
	const handleSelect = (item: MultistepItem) => {
		if (typeof item.value !== 'string') return;
		const currentSelected = equipmentIds || [];

		// In case the driverIds filter is null, that means every id is selected, so use the list of every equipment available in the company
		const listToAdd = allEquipmentSelected ? allEquipmentIds : currentSelected;

		// Using a Set to make sure every value is unique
		// Add every equipment id to the Set
		const ids = new Set<string>(listToAdd);

		// Remove element from the list if it exists, otherwise just remove it
		if (ids.has(item.value)) {
			ids.delete(item.value);
		} else {
			ids.add(item.value);
		}

		handleChange({
			equipmentIds: ids.size === allEquipmentIds.length ? null : [...ids],
		});
	};

	/**
	 * Select by category
	 *
	 * When the category has no items checked, it selects all of them (adds null)
	 */
	const handleSelectCategory = (
		category: string,
		hasCheckedSubItems: boolean,
	) => {
		if (category === 'TRUCK' || category === 'MACHINE') {
			const newIds = new Set<string>(equipmentIds || allEquipmentIds);
			if (hasCheckedSubItems) {
				equipment[category].forEach(({ value }) =>
					newIds.delete(value.toString()),
				);
			} else {
				equipment[category].forEach(({ value }) =>
					newIds.add(value.toString()),
				);
			}
			handleChange({
				equipmentIds:
					newIds.size === allEquipmentIds.length ? null : [...newIds],
			});
		}
	};

	// Selects all equipment (null values in filters)
	const handleSelectAll = () => {
		handleChange({ equipmentIds: null });
	};

	// Deselects all equipment (empty array in filters)
	const handleDeselectAll = () => {
		handleChange({ equipmentIds: [] });
	};

	return (
		<FilterBase
			variant={variant}
			iconButton={Truck}
			buttonText={title}
			dataTestButton="open-equipment-filters-button"
			dataTestContent="equipment-filters-content"
		>
			<div className="w-[280px] h-full">
				<MultistepListCheckbox
					handleSelect={handleSelect}
					data={data}
					handleDeselectAll={handleDeselectAll}
					handleSelectAll={handleSelectAll}
					handleSelectCategory={handleSelectCategory}
					description={
						<TypographyP>{t('filters.equipment.description')}</TypographyP>
					}
					noDataText={t('filters.equipment.noData')}
					noDataWithSearchText={t('filters.equipment.noDataWithSearch')}
				/>
			</div>
		</FilterBase>
	);
}

export default memo(EquipmentFilters);
