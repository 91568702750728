import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics, Analytics, isSupported } from 'firebase/analytics';
import { firebaseConfig } from '@/constants/configs';
import i18n, { fallbackLng } from './i18nInstance';
import LanguageDetector from 'i18next-browser-languagedetector';

/** Create Firebase instance. */
const firebaseApp = initializeApp(firebaseConfig);

/** Initialize auth instance. */
const firebaseAuth = getAuth(firebaseApp);

let firebaseAnalytics: Analytics | null;
/**  Initialize analytics only if it's supported in the current browser */
isSupported().then((isSupported) => {
	if (isSupported) {
		firebaseAnalytics = getAnalytics(firebaseApp);
	}
});

/**
 * Detects and sets the current application's languageCode in firebase/auth.
 *
 * This is needed because this languageCode determines which language is being used when sending SMS or emails to the user.
 */
const detectAndUpdateFirebaseLanguage = () => {
	// Create a new detector
	const detected = new LanguageDetector(i18n.services, {
		order: ['localStorage', 'navigator'],
		convertDetectedLanguage: (lng: string) => {
			if (lng === 'nn-NO') return 'no';
			if (lng === 'nb-NO') return 'no';
			return lng;
		},
	}).detect();

	// There might be multiple language codes found, in that case, we'll default to the first one
	// Order is based on the order array in `detectionOptions`
	const detectedLng = Array.isArray(detected) ? detected?.[0] : detected;

	firebaseAuth.languageCode = detectedLng || fallbackLng;

	// Watch for change and update accordingly.
	i18n.on('languageChanged', (newLang) => {
		firebaseAuth.languageCode = newLang;
	});
};

detectAndUpdateFirebaseLanguage();

export { firebaseAuth, firebaseAnalytics };
