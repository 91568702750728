import { Column } from '@tanstack/react-table';
import { cn } from '@/lib/utils';
import { Separator } from './separator';
import { Popover, PopoverTrigger, PopoverContent } from './popover';
import {
	Command,
	CommandInput,
	CommandList,
	CommandEmpty,
	CommandGroup,
	CommandItem,
} from './command';
import { Button } from './button';
import { Badge } from './badge';
import { useTranslation } from 'react-i18next';
import { CheckIcon, SlidersHorizontalIcon } from 'lucide-react';
import { useMemo } from 'react';

interface DataTableFacetedFilterProps<TData, TValue> {
	column: Column<TData, TValue>;
	title?: string;
	valueToLabel?: (value: string) => string;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
}

export function DataTableFacetedFilter<TData, TValue>({
	column,
	title,
	valueToLabel,
	onOpenChange,
	open,
}: DataTableFacetedFilterProps<TData, TValue>) {
	const { t } = useTranslation();

	const defaultValueToLabel = useMemo(
		() => (value: string | null) => {
			if (value === null) {
				return t('common.noFilterValue');
			}
			return value;
		},
		[t],
	);

	const uniqueValues: string[] = column.getFacetedUniqueValues()
		? [...column.getFacetedUniqueValues().keys()]
		: [];

	const selectedValues = new Set(column?.getFilterValue() as string[]);

	return (
		<Popover open={open} onOpenChange={onOpenChange}>
			<PopoverTrigger asChild>
				<Button
					variant="outline"
					size="sm"
					className="h-8 border-dashed"
					onClick={() => {
						onOpenChange?.(!open);
					}}
				>
					<SlidersHorizontalIcon className="mr-2 h-4 w-4" />
					{title}
					{selectedValues?.size > 0 && (
						<>
							<Separator orientation="vertical" className="mx-2 h-4" />
							<Badge
								variant="secondary"
								className="rounded-sm px-1 font-normal lg:hidden"
							>
								{selectedValues.size}
							</Badge>
							<div className="hidden space-x-1 lg:flex">
								{selectedValues.size > 2 ? (
									<Badge
										variant="secondary"
										className="rounded-sm px-1 font-normal"
									>
										{selectedValues.size} {t('common.selected')}
									</Badge>
								) : (
									uniqueValues
										.filter((value) => selectedValues.has(value))
										.map((value) => (
											<Badge
												variant="secondary"
												key={value}
												className="rounded-sm px-1 font-normal"
											>
												{valueToLabel
													? valueToLabel(value)
													: defaultValueToLabel(value)}
											</Badge>
										))
								)}
							</div>
						</>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-[220px] p-0" align="start">
				<Command>
					<CommandInput placeholder={title} />
					<CommandList>
						<CommandEmpty>{t('common.noData')}</CommandEmpty>
						<CommandGroup>
							{uniqueValues.map((value) => {
								const isSelected = selectedValues.has(value);
								return (
									<CommandItem
										key={value}
										onSelect={() => {
											if (isSelected) {
												selectedValues.delete(value);
											} else {
												selectedValues.add(value);
											}
											const filterValues = Array.from(selectedValues);
											column?.setFilterValue(
												filterValues.length ? filterValues : undefined,
											);
										}}
									>
										<div
											className={cn(
												'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
												isSelected
													? 'bg-primary text-primary-foreground'
													: 'opacity-50 [&_svg]:invisible',
											)}
										>
											<CheckIcon className={cn('h-4 w-4')} />
										</div>
										<span className="flex-shrink truncate pr-1">
											{valueToLabel
												? valueToLabel(value)
												: defaultValueToLabel(value)}
										</span>
										<span className="font-mono ml-auto flex h-4 w-4 items-center justify-center text-xs">
											{column.getFacetedUniqueValues().get(value)}
										</span>
									</CommandItem>
								);
							})}
						</CommandGroup>
					</CommandList>
					{selectedValues.size > 0 && (
						<>
							<Separator />
							<CommandGroup>
								<CommandItem
									onSelect={() => column?.setFilterValue(undefined)}
									className="justify-center text-center"
								>
									{t('common.reset')}
								</CommandItem>
							</CommandGroup>
						</>
					)}
				</Command>
			</PopoverContent>
		</Popover>
	);
}
