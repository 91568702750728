import React from 'react';
import { useTranslation } from 'react-i18next';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { Switch } from '@/components/ui/switch';
import { Label } from '@/components/ui/label';

interface Props {
	enabled: boolean;
	configId: number;
	companyId: number;
}

function IntegrationToggleSwitch({
	enabled,
	configId,
	companyId,
}: Props): JSX.Element {
	const trpcContext = trpc.useUtils();
	const { t } = useTranslation();

	const updateMutation = trpc.integration.updateEnabledById.useMutation({
		onMutate: async () => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			await trpcContext.integration.listConfig.cancel();

			// Snapshot the previous value
			const previousIntegrations = trpcContext.integration.listConfig.getData();

			// Optimistically update to the new value
			trpcContext.integration.listConfig.setData({ companyId }, (old = []) => {
				return old.map((cfg) => {
					if (cfg.id === configId) {
						return {
							...cfg,
							enabled: !enabled,
						};
					}
					return cfg;
				});
			});

			// Return a context object with the snapshotted value
			return { previousIntegrations };
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: async (error, _, context) => {
			trpcContext.integration.listConfig.setData(
				{ companyId },
				context?.previousIntegrations ?? [],
			);

			toast.error(error.message);
		},
		onSettled: () => {},
	});

	const integrationStatus = enabled ? 'enabled' : 'disabled';
	return (
		<div className="flex items-center space-x-2">
			<Switch
				id={`integration-status-switch-${integrationStatus}`}
				defaultChecked={enabled}
				onCheckedChange={() => {
					updateMutation.mutate({
						id: configId,
						enabled: !enabled,
						companyId,
					});
				}}
				data-testid={`integration-status-switch-${integrationStatus}`}
			/>
			<Label htmlFor={`integration-status-switch-${integrationStatus}`}>
				{enabled
					? t('company.integrations.activated')
					: t('company.integrations.deactivated')}
			</Label>
		</div>
	);
}
export default IntegrationToggleSwitch;
