import React, { memo, useEffect } from 'react';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useRouter } from '@/hooks/common/useRouter';

import { useTranslation } from 'react-i18next';
import { firebaseAnalytics } from '@/lib/firebaseClient';
import {
	setAnalyticsCollectionEnabled,
	setUserId,
	setUserProperties,
	logEvent,
	setCurrentScreen,
} from 'firebase/analytics';
import { useUserProfile } from '@/hooks/auth/useUserProfile';

/**
 * Enables firebase analytics and performance metrics.
 * Extra information such as user info will be sent only when a user is logged in, superusers will not send any extra info.
 */
const FirebaseAnalyticsPerformance: React.FC = () => {
	const { companyId, user, defaultRole } = useAuth();
	const userProfile = useUserProfile();

	const router = useRouter();
	const { i18n } = useTranslation();

	const profile = userProfile?.data;
	const gender = profile?.gender;
	const isSuperuser = profile?.isSuperuser;

	const uid = user?.uid;
	// Enable analytics if a user was found
	useEffect(() => {
		if (!firebaseAnalytics || isSuperuser) return;

		//  Track user id
		if (uid) {
			setUserId(firebaseAnalytics, uid);
		}
		// Enable analytics collection
		setAnalyticsCollectionEnabled(firebaseAnalytics, true);
	}, [isSuperuser, uid]);

	// Send custom properties to firebase analytics
	useEffect(() => {
		if (!firebaseAnalytics || isSuperuser) return;
		const userProperties = {
			user_gender: gender,
			user_company_id: companyId,
			user_company_role: defaultRole,
		};

		setUserProperties(firebaseAnalytics, {
			user_language: i18n.language,
			...userProperties,
		});
	}, [i18n.language, gender, companyId, isSuperuser, defaultRole]);

	// Log page view
	useEffect(() => {
		if (!firebaseAnalytics || isSuperuser) return;
		logEvent(firebaseAnalytics, 'page_view', { page_path: router.pathname });
	}, [isSuperuser, router.pathname]);

	// Log current screen
	useEffect(() => {
		if (!firebaseAnalytics || isSuperuser) return;
		setCurrentScreen(firebaseAnalytics, window.location.pathname);
	}, [isSuperuser, router.pathname]);

	return null;
};

export default memo(FirebaseAnalyticsPerformance);
