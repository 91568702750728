import React, { memo, Suspense } from 'react';
import TKNoDataFound from '@/components/Progress/TKNoDataFound';
import TKDataError from '@/components/Progress/TKDataError';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '@/components/Common/ErrorBoundary';
import { TRPCClientErrorLike } from '@trpc/client';
import type { AppRouter } from '@/api/trpc';
import { Spinner } from '../ui/spinner';
import { TypographySmall } from '../ui/typography';
export type TKDataHandlerImageProps = {
	imageWidth?: string;
	title?: string;
	children?: string | React.ReactNode;
};

export type TKDataHandlerProps = {
	error?: boolean | TRPCClientErrorLike<AppRouter> | null;
	loading: boolean;
	data: any;
	entryText: string;
	noDataDescription?: string;
	loadingDescription?: string;
	errorDescription?: string;
	size?: 'small' | 'medium' | 'large';
	showChildrenWhenNoData?: boolean;
	enableErrorBoundary?: boolean;
	children?: string | React.ReactNode | React.ReactNode[];
};

/**
 * This utility component has the purpose of eliminating nesting ternary statements inside components.
 * It handles error messages, loading state, no data found and displays the appropiate images and messages.
 *
 * Passing an entryText translation will use some default common translations.
 * To override a text for either of noData/error/loading, you can optionally pass in a full translation, which will take priority over the default translations.
 *
 *
 * It only renders the children JSX when there are items in the data list, and "error", "loading" states are false.
 *
 * Passing in `showChildrenWhenNoData` makes it so that the children will be displayed instead of the default "NO DATA FOUND" message
 */
const TKDataHandler = ({
	error,
	loading,
	data,
	children,
	entryText,
	noDataDescription,
	errorDescription,
	loadingDescription,
	size = 'medium',
	showChildrenWhenNoData = false,
	enableErrorBoundary = false,
}: TKDataHandlerProps) => {
	const { t } = useTranslation();

	const widths = {
		small: '15%',
		medium: '70%',
		large: '100%',
	};

	if (error) {
		const errorText =
			errorDescription || t('common.api.read.error', { type: entryText });
		return (
			<TKDataError imageWidth={widths[size]} title={errorText}>
				{errorText}
			</TKDataError>
		);
	}
	const loadingText =
		loadingDescription || t('common.api.read.loading', { type: entryText });

	const DescriptiveLoading = (
		<div className="flex flex-col items-center justify-center w-full h-full">
			<Spinner />
			<TypographySmall className="mt-4 text-sm">{loadingText}</TypographySmall>
		</div>
	);

	if (loading) {
		return DescriptiveLoading;
	}

	if (!showChildrenWhenNoData) {
		const noDataText =
			noDataDescription || t('common.api.read.noData', { type: entryText });
		if (
			data === undefined ||
			data === null ||
			(Array.isArray(data) && data.length === 0)
		) {
			return (
				<TKNoDataFound imageWidth={widths[size]} title={noDataText}>
					{noDataText}
				</TKNoDataFound>
			);
		}
	}

	const ChildrenComponent = (
		<Suspense fallback={DescriptiveLoading}>{children}</Suspense>
	);

	if (enableErrorBoundary) {
		const refreshErrorText =
			errorDescription ||
			t('common.api.read.exceptionError', { type: entryText });
		return (
			<ErrorBoundary
				fallback={
					<TKDataError imageWidth={widths[size]} title={refreshErrorText}>
						{refreshErrorText}
					</TKDataError>
				}
			>
				{ChildrenComponent}
			</ErrorBoundary>
		);
	}

	return ChildrenComponent;
};
export default memo(TKDataHandler);
