import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import {
	Form,
	FormField,
	FormItem,
	FormLabel,
	FormControl,
	FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { useAuth } from '@/contexts/Global/AuthContext';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';

interface CompanyGroupType {
	id: string;
	name: string;
}

interface InsertCompanyGroupType {
	name: string;
}

interface Props {
	initialValues?: CompanyGroupType;
	dialogType: 'create' | 'update';
	dialogOpen: boolean;
	setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function CompanyGroupsFormDialog({
	initialValues,
	dialogOpen,
	dialogType,
	setDialogOpen,
}: Props): JSX.Element {
	const { companyId } = useAuth();
	const { t } = useTranslation();
	const insertMutation = trpc.group.insert.useMutation({
		async onSuccess() {
			toast.success(
				t('common.api.insert.success', { type: t('company.base') }),
			);
			form.reset({
				name: '',
			});
			setDialogOpen(false);
		},
		onError(error) {
			toast.error(error.message);
		},
	});
	const updateMutation = trpc.group.update.useMutation({
		async onSuccess() {
			toast.success(
				t('common.api.update.success', { type: t('company.base') }),
			);
			form.reset({
				name: '',
			});
			setDialogOpen(false);
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	const form = useForm<InsertCompanyGroupType>({
		defaultValues: initialValues,
	});

	return (
		<Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{t(`company.groups.${dialogType}GroupsDialog.title`)}
					</DialogTitle>
				</DialogHeader>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit((data) => {
							if (dialogType === 'create') {
								insertMutation.mutate({ name: data.name, companyId });
							} else if (dialogType === 'update' && initialValues) {
								updateMutation.mutate({
									id: initialValues.id!,
									name: data.name,
									companyId,
								});
							}
						})}
					>
						<div className="space-y-4">
							<FormField
								control={form.control}
								name="name"
								rules={{
									required: t('validations.groupName.required'),
								}}
								render={({ field }) => (
									<FormItem>
										<FormLabel>{t('validations.groupName.label')}</FormLabel>
										<FormControl>
											<Input
												{...field}
												autoFocus
												autoComplete="off"
												data-testid="company-group-name-input"
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<DialogFooter className="mt-4">
							<Button
								variant="outline"
								type="button"
								onClick={() => setDialogOpen(false)}
							>
								{t('common.cancelText')}
							</Button>
							<TKConfirmButton
								isLoading={insertMutation.isLoading || updateMutation.isLoading}
								disabled={insertMutation.isLoading || updateMutation.isLoading}
								dialogType={dialogType}
								data-testid="confirm-group-button"
							/>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
}

export default CompanyGroupsFormDialog;
