import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from '@/contexts/Global/Dialog/DialogContext';
import { useRouter } from '@/hooks/common/useRouter';
import { useAuth } from '@/contexts/Global/AuthContext';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import NotFound from '@/views/Common/NotFound';
import EditEquipmentDialog from './EditEquipmentDialog';
import EquipmentDetailsContent from './EquipmentDetailsContent';
import EquipmentSharingTab from './EquipmentSharingTab';
import EquipmentServiceTab from './EquipmentServiceTab';
import { EquipmentTypeEnum } from '@/types/equipment';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';
import TKDataHandler from '@/components/Progress/TKDataHandler';

interface QueryParams {
	id: string;
}

function EquipmentDetailPage(): JSX.Element {
	const query: QueryParams = useRouter().query;
	const { companyId } = useAuth();
	const { t } = useTranslation();
	const router = useRouter();
	const dialog = useDialog();
	const [editDialogOpen, setEditDialogOpen] = useState(false);

	const equipmentQuery = trpc.equipment.byId.useQuery(
		{
			companyId,
			id: query.id,
		},
		{ retry: 0 },
	);

	const isCompanyOwnedEquipment = equipmentQuery.data?.companyId === companyId;

	const deleteMutation = trpc.equipment.deleteById.useMutation({
		onSuccess() {
			toast.success(
				t('common.api.delete.success', { type: t('equipment.equipment') }),
			);
			router.replace('/equipment', {});
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	// Assert as non-nullable because of the condition above
	const equipment = equipmentQuery.data!;

	function handleOpenDeleteDialog() {
		dialog({
			title: t('equipment.delete', {
				equipmentName: equipment.displayName,
			}),

			dataTestConfirm: 'confirm-delete-equipment',
			description: t('equipment.deleteDialog.description'),
			dialogType: 'delete',
		}).then(() =>
			deleteMutation.mutate({
				id: equipment.id,
				companyId,
			}),
		);
	}

	if (!query.id || (!equipmentQuery.isLoading && equipmentQuery.isError)) {
		return <NotFound />;
	}

	return (
		<DashboardLayout
			AppBarComponent={
				<TKPageAppBar
					title={equipment?.displayName}
					onBackButtonClick={() => router.push('/equipment', {})}
				>
					{isCompanyOwnedEquipment && (
						<>
							<Button
								onClick={() => setEditDialogOpen(true)}
								data-testid="open-update-equipment"
							>
								{t('equipment.edit')}
							</Button>
							<Button
								variant="destructive"
								onClick={handleOpenDeleteDialog}
								data-testid="open-delete-equipment"
							>
								{t('equipment.delete')}
							</Button>
						</>
					)}
				</TKPageAppBar>
			}
		>
			<TKDataHandler
				entryText={t('equipment.equipment')}
				loading={equipmentQuery.isLoading}
				error={equipmentQuery.error}
				data={equipment}
			>
				{equipment && (
					<Tabs defaultValue="overview">
						<TabsList>
							<TabsTrigger value="overview">
								{t('equipment.overview')}
							</TabsTrigger>
							{isCompanyOwnedEquipment && (
								<TabsTrigger value="sharing">
									{t('equipment.sharing')}
								</TabsTrigger>
							)}
							{equipment.type === EquipmentTypeEnum.TRUCK && (
								<TabsTrigger value="service">
									{t('equipment.service')}
								</TabsTrigger>
							)}
						</TabsList>
						<TabsContent value="overview" className="overflow-hidden">
							<EquipmentDetailsContent equipment={equipment} />
						</TabsContent>
						{isCompanyOwnedEquipment && (
							<TabsContent value="sharing">
								<EquipmentSharingTab equipmentId={equipment.id} />
							</TabsContent>
						)}
						{equipment.type === EquipmentTypeEnum.TRUCK && (
							<TabsContent value="service">
								<EquipmentServiceTab equipmentId={equipment.id} />
							</TabsContent>
						)}
					</Tabs>
				)}
			</TKDataHandler>

			{editDialogOpen && (
				<EditEquipmentDialog
					open={editDialogOpen}
					setOpen={setEditDialogOpen}
					initialValues={equipment}
				/>
			)}
		</DashboardLayout>
	);
}
export default EquipmentDetailPage;
