import { useMemo } from 'react';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';

export function useDrivers() {
	const { companyId } = useAuth();
	const { data, isLoading, error } = trpc.driver.list.useQuery(
		{
			companyId,
		},
		{
			staleTime: 1000 * 60 * 5, // 5 minutes
		},
	);

	return useMemo(
		() => ({
			drivers: data || [],
			driversMap: new Map(data?.map((e) => [e.id, e])),
			isLoading,
			error,
		}),
		[data, isLoading, error],
	);
}
