import { DataColumn } from '@/components/Table/data-table';
import FileSaver from 'file-saver';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

async function dataTablesToExcel<TData>(
	data: TData[],
	columns: DataColumn<TData>[],
): Promise<Blob> {
	const ExcelJS = await import('exceljs');
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet('Sheet1');

	// Set column headers
	const headerRow = worksheet.addRow(
		columns.filter((col) => !col?.hidden).map((column) => column.title),
	);
	headerRow.font = { bold: true };

	// Add data rows
	data.forEach((rowData) => {
		const row: any[] = [];
		columns.forEach((column) => {
			if (column.hidden) {
				return;
			}
			// First try to get value using accessorFn
			let value = column.accessorFn
				? column.accessorFn(rowData)
				: (rowData[column.id] as TData);

			// Only use render if we don't have an accessorFn
			if (!column.accessorFn && column.render) {
				const renderedValue = column.render(rowData);
				if (React.isValidElement(renderedValue)) {
					value = ReactDOMServer.renderToStaticMarkup(renderedValue) as TData;
				} else {
					value = renderedValue as TData;
				}
			}
			row.push(value);
		});
		worksheet.addRow(row);
	});

	// Generate buffer
	const buffer = await workbook.xlsx.writeBuffer();
	return new Blob([buffer], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	});
}

function dataTablesToCSV<TData>(
	data: TData[],
	columns: DataColumn<TData>[],
	delimiter = ',',
): Blob {
	const csvData: any[] = [];

	// Add header row
	const headerRow = columns
		.filter((col) => !col?.hidden)
		.map((column) => `"${column.title}"`);

	csvData.push(headerRow.join(delimiter));
	// Add data rows
	data.forEach((rowData) => {
		const row: any[] = [];
		columns.forEach((column) => {
			if (column.hidden) {
				return;
			}
			// First try to get value using accessorFn
			let value = column.accessorFn
				? column.accessorFn(rowData)
				: (rowData[column.id] as TData);

			// Only use render if we don't have an accessorFn
			if (!column.accessorFn && column.render) {
				const renderedValue = column.render(rowData);
				if (React.isValidElement(renderedValue)) {
					value = ReactDOMServer.renderToStaticMarkup(renderedValue) as TData;
				} else {
					value = renderedValue as TData;
				}
			}
			row.push(`"${value}"`);
		});
		csvData.push(row.join(delimiter));
	});

	const result = csvData.join('\n');
	return new Blob([result], { type: 'text/csv;charset=utf-8;' });
}

export function useExportDataTable<TData>(
	data: TData[],
	columns: DataColumn<TData>[],
	fileName: string,
) {
	function exportToCsv() {
		const csvBlob = dataTablesToCSV(data, columns);
		FileSaver.saveAs(csvBlob, `${fileName}.csv`);
	}
	async function exportToExcel() {
		const excelBlob = await dataTablesToExcel(data, columns);
		FileSaver.saveAs(excelBlob, `${fileName}.xlsx`);
	}
	return {
		exportToExcel,
		exportToCsv,
	};
}
