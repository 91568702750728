import React, { Suspense } from 'react';
import DashboardLayout from '@/components/Layouts/DashboardLayout';
import { DrawerProvider } from '@/contexts/Global/Drawer/DrawerContext';
import TKDescriptiveProgress from '@/components/Progress/TKDescriptiveProgress';

const MapboxWrapper = React.lazy(() =>
	import('./MapboxWrapper').then((module) => ({
		default: module.MapboxWrapper,
	})),
);

/**
 * Main component for Map, handling the layout of this page (drawer, position of actual map, etc)
 *
 */
function Map(): JSX.Element {
	return (
		<DrawerProvider>
			<DashboardLayout disablePadding>
				<Suspense fallback={<TKDescriptiveProgress />}>
					<MapboxWrapper />
				</Suspense>
			</DashboardLayout>
		</DrawerProvider>
	);
}

export default Map;
