import React, { useEffect, useState } from 'react';
import { useElementSize } from '@/hooks/common/useElementSize';
import { pdfjs, Page, Document } from 'react-pdf';
import { TRPCClientErrorLike } from '@/api/trpc';
import { cn } from '@/lib/utils';
import { CenteredSpinner } from '@/components/ui/spinner';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	'pdfjs-dist/build/pdf.worker.min.js',
	import.meta.url,
).toString();

interface Props {
	fileContent: string | undefined;
	isLoading: boolean;
	error: undefined | TRPCClientErrorLike | null;
}

function RenderPdf({ fileContent, isLoading }: Props) {
	const { ref, size } = useElementSize();

	const [numPages, setNumPages] = useState(0);

	useEffect(() => {
		if (isLoading && ref.current) {
			ref.current.scrollTo({ top: 0 });
		}
	}, [ref, isLoading]);

	return (
		<div
			ref={ref}
			className={cn(
				'flex justify-center items-center relative md:max-w-5xl mx-auto',
				'react-pdf__Page__textContent:h-full',
				isLoading && 'overflow-hidden bg-white',
			)}
		>
			{/* overlay */}
			{isLoading && (
				<div className="absolute inset-0 z-10 bg-black/10 flex flex-col justify-start items-center pt-8 h-full"></div>
			)}
			{/* loading */}
			{isLoading && (
				<div className="absolute inset-0 z-20 flex flex-col justify-start items-center pt-8 h-screen">
					<CenteredSpinner size="lg" />
				</div>
			)}
			<Document
				noData=""
				loading=""
				error=""
				options={{
					isEvalSupported: false,
				}}
				file={fileContent}
				onLoadSuccess={({ numPages }) => {
					setNumPages(numPages);
				}}
			>
				{Array.from({ length: numPages }, (_, i) => (
					<Page
						width={size.width}
						scale={1}
						key={`page-${i}`}
						pageNumber={i + 1}
					/>
				))}
			</Document>
		</div>
	);
}

export default RenderPdf;
