import React from 'react';
import { HelpCircle } from 'lucide-react';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { TypographyMuted } from '@/components/ui/typography';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

interface TKHelpTooltipProps {
	name: string;
	description: string;
	className?: string;
}

function TKHelpTooltip({
	name,
	description,
	className = '',
}: TKHelpTooltipProps): JSX.Element {
	return (
		<TooltipProvider>
			<Tooltip delayDuration={300}>
				<TooltipTrigger asChild>
					<div className="flex items-center space-x-2">
						<Badge variant="secondary">
							<HelpCircle
								className={cn(
									'h-4 w-4 text-muted-foreground cursor-help mr-1',
									className,
								)}
							/>
							{name}
						</Badge>
					</div>
				</TooltipTrigger>
				<TooltipContent className="max-w-xs">
					<TypographyMuted>{description}</TypographyMuted>
				</TooltipContent>
			</Tooltip>
		</TooltipProvider>
	);
}

export default TKHelpTooltip;
