import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DashboardLayout from '@/components/Layouts/DashboardLayout';
import TKDataHandler from '@/components/Progress/TKDataHandler';
import UserInvitationCard from './UserInvitationCard';
import UserCompanyCard from './UserCompanyCard';
import CreateCompanyFormDialog from '@/dialogs/company/CreateCompanyFormDialog';
import UpdateCompanyFormDialog from '@/dialogs/company/UpdateCompanyFormDialog';

import { useLoginIntoCompany } from '@/hooks/auth/useLoginIntoCompany';
import { toast } from 'sonner';
import { useRouter } from '@/hooks/common/useRouter';
import { trpc } from '@/api/trpc';
import { useUserProfile } from '@/hooks/auth/useUserProfile';
import { useDialog } from '@/contexts/Global/Dialog/DialogContext';
import { firebaseAuth } from '@/lib/firebaseClient';
import { EmailAuthProvider, signInWithCredential } from 'firebase/auth';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';

import { Button } from '@/components/ui/button';
import { TypographyP } from '@/components/ui/typography';

function ChooseCompany(): JSX.Element {
	const { t } = useTranslation();
	const router = useRouter();
	const { loginIntoCompany } = useLoginIntoCompany();

	const userProfile = useUserProfile();
	const isSuperUser = !!userProfile.data?.isSuperuser;

	const companiesQuery = trpc.company.listIds.useQuery();
	const invitationQuery = trpc.invitation.listIds.useQuery();
	const companyIds = companiesQuery.data ?? [];
	const invitationIds = invitationQuery.data ?? [];
	const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);
	const [selectedCompanyId, setSelectedCompanyId] = useState<number | null>(
		null,
	);

	const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

	const onSelectCompany = async (companyId: number): Promise<void> => {
		try {
			setSelectedCompanyId(companyId);
			await loginIntoCompany(companyId);
			router.push('/', {});
		} catch (error: any) {
			toast.error(error?.message);
		} finally {
			setSelectedCompanyId(null);
		}
	};

	const handleUpdateDialog = (open: boolean) => {
		if (!open) {
			setSelectedCompanyId(null);
		}
		setIsUpdateDialogOpen(open);
	};

	const onEditCompany = async (companyId: number) => {
		try {
			setSelectedCompanyId(companyId);
			await loginIntoCompany(companyId);
			handleUpdateDialog(true);
		} catch (error: any) {
			toast.error(error?.message);
			setSelectedCompanyId(null);
		}
	};

	async function reauthenticateUser() {
		if (firebaseAuth?.currentUser) {
			const user = firebaseAuth?.currentUser;
			const credential = await EmailAuthProvider.credential(
				user?.email ?? '',
				'password',
			);
			return signInWithCredential(firebaseAuth, credential);
		}
	}

	const deleteMutation = trpc.company.deleteById.useMutation({
		async onSuccess() {
			await reauthenticateUser();
		},
	});
	const deleteDialog = useDialog();

	async function onDeleteCompany(companyId: number, name: string) {
		try {
			setSelectedCompanyId(companyId);
			await loginIntoCompany(companyId);

			deleteDialog({
				dialogType: 'delete',
				title: 'Delete company?',
				description: 'Are you sure you want to delete this company?',
				catchOnCancel: true,
				withInputConfirm: {
					valueToType: name,
					descriptionComponent: (
						<TypographyP>
							This action is irreversible. Please type <strong>{name}</strong>{' '}
							to confirm.
						</TypographyP>
					),
				},
			})
				.then(() => deleteMutation.mutate({ companyId }))
				.catch(() => {
					setSelectedCompanyId(null);
				});
		} catch (error: any) {
			toast.error(error?.message);
			setSelectedCompanyId(null);
		}
	}

	const error = companiesQuery.error || invitationQuery.error;
	const isLoading = companiesQuery.isLoading || invitationQuery.isLoading;

	return (
		<>
			<DashboardLayout
				AppBarComponent={
					<TKPageAppBar title={t('companies.title')}>
						{isSuperUser && (
							<Button
								onClick={() => setIsCreateDialogOpen(true)}
								variant="default"
							>
								{t('companies.addCompany')}
							</Button>
						)}
					</TKPageAppBar>
				}
			>
				<TKDataHandler
					error={error}
					loading={isLoading}
					noDataDescription={t('companies.noCompaniesFound')}
					entryText={t('companies.base', { count: 2 })}
					data={[...invitationIds, ...companyIds]}
				>
					<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-4">
						{invitationIds.map((invitation) => (
							<UserInvitationCard
								companyId={invitation.companyId}
								key={`invitation_${invitation.id}`}
								id={invitation.id}
							/>
						))}
						{companyIds.map((company) => (
							<UserCompanyCard
								key={`company_${company.companyId}`}
								isLoginLoading={isLoading}
								companyId={company.companyId}
								onSelectCompany={onSelectCompany}
								selectedCompanyId={selectedCompanyId}
								onEditCompany={onEditCompany}
								onDeleteCompany={onDeleteCompany}
							/>
						))}
					</div>
				</TKDataHandler>
			</DashboardLayout>
			<UpdateCompanyFormDialog
				isOpen={isUpdateDialogOpen}
				setOpen={handleUpdateDialog}
				companyId={selectedCompanyId}
			/>
			<CreateCompanyFormDialog
				isOpen={isCreateDialogOpen}
				setOpen={setIsCreateDialogOpen}
			/>
		</>
	);
}

export default ChooseCompany;
