import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { Link } from 'react-router-dom';
import { createUrl, Path, PathParams } from '@/routes/routesList';
import { cn } from '@/lib/utils';

type FullLinkRouterProps<P extends Path> = {
	to: P;
	params: PathParams<P>;
	children?: React.ReactNode;
	title?: string;
	replace?: boolean;
	target?: '_blank';
	rel?: 'noopener noreferrer';
	className?: string;
	noDecoration?: boolean;
	style?: React.CSSProperties;
	onClick?: () => void;
};

/**
 * Type-safe version of `react-router-dom`'s Link component.
 */
const TKTypedLink = <P extends Path>({
	to,
	params,
	replace,
	children,
	noDecoration = false,
	onClick,
	style,
	className,
	...restProps
}: FullLinkRouterProps<P>) => {
	return (
		<Link
			{...restProps}
			to={createUrl(to, params)}
			replace={replace}
			onClick={onClick}
			style={style}
			className={cn(!noDecoration ? 'hover:underline' : undefined, className)}
		>
			{children}
		</Link>
	);
};
export default TKTypedLink;
