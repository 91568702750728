import React from 'react';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import { TypographySmall } from '@/components/ui/typography';
import { Spinner } from '@/components/ui/spinner';
import { useDateFns } from '@/hooks/common/useDateFns';
import { useTranslation } from 'react-i18next';

type TKTextWithLabelProps = {
	label: string;
	text?: string | React.ReactNode | JSX.Element;
	loading?: boolean;
	timestamp?: string | null;
};

const TKTextWithLabel: React.FC<TKTextWithLabelProps> = ({
	label,
	text,
	loading = false,
	timestamp,
	...restProps
}) => {
	const { t } = useTranslation();
	const { formatDistanceToNow } = useDateFns();

	const tooltipText = timestamp
		? `${t('common.last updated', {
				t: formatDistanceToNow(new Date(timestamp)),
			})}`
		: '';

	return (
		<>
			<TypographySmall className="text-muted-foreground whitespace-nowrap text-xs mr-2 uppercase">
				{label}
			</TypographySmall>
			{loading ? (
				<Spinner className="w-4 h-4" />
			) : (
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger asChild>
							<TypographySmall className="block !mt-0" {...restProps}>
								{text ?? t('common.notAvailable')}
							</TypographySmall>
						</TooltipTrigger>
						<TooltipContent>{tooltipText}</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			)}
		</>
	);
};

export default TKTextWithLabel;
