import React, { memo, useRef, useLayoutEffect } from 'react';
import { Button } from '@/components/ui/button';
import { ScrollArea } from '@/components/ui/scroll-area';
import AnalysisListItem from './AnalysisListItem';
import AnalysisCreateFormDialog from '@/dialogs/analysis/AnalysisCreateFormDialog';
import { PlusCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { cn } from '@/lib/utils';
import { TypographyH5, TypographySmall } from '@/components/ui/typography';
import { Separator } from '@/components/ui/separator';
import { CenteredSpinner } from '@/components/ui/spinner';

function createId(dashboardId: number) {
	return `dashboard_${dashboardId}`;
}

interface Dashboard {
	id: number;
	name: string;
	isTemplate: boolean;
	isShareable: boolean;
	isEditable: boolean;
}
interface Props {
	activeDashboardId: number | null;
	setActiveDashboardId: (id: number | null) => void;
	dashboards: Dashboard[] | undefined;
	isLoading: boolean;
}

function AnalysisSidebar({
	activeDashboardId,
	setActiveDashboardId,
	dashboards = [],
	isLoading,
}: Props): JSX.Element {
	const listRef = useRef<HTMLUListElement>(null);
	const { t } = useTranslation();
	const [dialogOpen, setDialogOpen] = React.useState(false);

	// Scroll dashboard item into view (in cases where the URL contains a dashboard link that's being rendered somewhere down in dashboards list)
	useLayoutEffect(() => {
		if (dashboards && activeDashboardId) {
			const el = listRef?.current?.querySelector?.(
				'#' + createId(activeDashboardId),
			);
			if (el) {
				el.scrollIntoView({
					behavior: 'smooth',
					inline: 'nearest',
					block: 'nearest',
				});
			}
		}
	}, [activeDashboardId, dashboards]);

	return (
		<div className="h-full flex flex-col">
			<div className="px-4 py-2">
				<TypographyH5>{t('analysis.analysis')}</TypographyH5>
			</div>
			<div className="flex-grow py-4 overflow-hidden">
				{isLoading ? (
					<CenteredSpinner />
				) : (
					<ScrollArea className="h-full">
						<ul ref={listRef} className="space-y-1">
							{dashboards?.map((dashboard) => {
								const isActive = dashboard.id === activeDashboardId;
								return (
									<li
										key={dashboard.id}
										id={createId(dashboard.id)}
										className={cn('px-2 py-1 rounded-md cursor-pointer')}
										onClick={() => setActiveDashboardId(dashboard.id)}
										data-dashboard-id={dashboard.id}
										data-testid="analysis-dashboard-list-item"
									>
										<AnalysisListItem
											handleChangeActiveDashboard={setActiveDashboardId}
											isActive={isActive}
											id={dashboard.id}
											name={dashboard.name}
											isEditable={dashboard.isEditable}
											isTemplate={dashboard.isTemplate}
										/>
									</li>
								);
							})}
						</ul>
					</ScrollArea>
				)}
			</div>

			<Separator className="mb-2" />
			<Button
				variant="ghost"
				className="w-full py-4"
				onClick={() => setDialogOpen(true)}
				data-testid="open-create-dashboard-dialog"
			>
				<PlusCircle className="mr-2 h-4 w-4" />
				<TypographySmall>{t('analysis.createNew')}</TypographySmall>
			</Button>
			<AnalysisCreateFormDialog
				open={dialogOpen}
				setOpen={setDialogOpen}
				onInsert={setActiveDashboardId}
			/>
		</div>
	);
}

export default memo(AnalysisSidebar);
