import { useAuth } from '@/contexts/Global/AuthContext';
import { FiltersConfig, ReportExecuteResult } from '@/types/analysis';
import { trpc } from '@/api/trpc';
import { calculateFilterDates } from '@/utils/analysis/calculateFilterDates';
import { dropUnitDecimals } from '@/utils/analysis/unitsConvertor';

interface Args {
	reportTypeId: string;
	filters: FiltersConfig;
	filtersOverrides?: Partial<FiltersConfig>;
	isEnabled?: boolean;
}
function useExecuteReport({
	reportTypeId,
	filters,
	filtersOverrides,
	isEnabled,
}: Args) {
	const { companyId } = useAuth();
	const timeRange = calculateFilterDates(filters);

	const reportFilters = {
		...filters,
		companyId,
		reportTypeId,
		fromTime: timeRange.fromTime,
		toTime: timeRange.toTime,
		interval: filters.interval,
		hideDataFromUnknownDrivers: filters?.hideDataFromUnknownDrivers ?? null,
		hideDataFromUnknownProjects: filters?.hideDataFromUnknownProjects ?? null,
		groupIds: filters?.groupIds ?? null,
		equipmentIds: filters?.equipmentIds ?? null,
		groupDrivers: filters?.groupDrivers ?? null,
		groupEquipment: filters?.groupEquipment ?? null,
		groupProjects: filters?.groupProjects ?? null,
		projectIds: filters?.projectIds ?? null,
		driverIds: filters.driverIds ?? null,
		...filtersOverrides,
	};

	return trpc.analysisReport.execute.useQuery(reportFilters, {
		enabled: isEnabled,
		select(data) {
			return data.map((item) => {
				const value = dropUnitDecimals(item.unit, item.value);

				return {
					...item,
					value,
				} as ReportExecuteResult[number];
			});
		},
	});
}

export { useExecuteReport };
