import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useRouter } from '@/hooks/common/useRouter';
import { useAuth } from '@/contexts/Global/AuthContext';
import AuthLayout from '@/components/Layouts/AuthLayout';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
	createUserWithEmailAndPassword,
	sendEmailVerification,
	User,
} from 'firebase/auth';
import { firebaseAuth } from '@/lib/firebaseClient';
import { createUrl } from '@/routes/routesList';
import { useFirebaseAuthUtils } from '@/hooks/auth/useFirebaseAuthUtils';
import { endpoints } from '@/constants/configs';
import { useCountdownTimer } from '@/hooks/common/useCountdownTimer';
import { useFirebaseChecks } from '@/hooks/auth/useFirebaseChecks';
import { useAuthLogout } from '@/hooks/auth/useAuthLogout';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Spinner } from '@/components/ui/spinner';
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@/components/ui/form';
import TKTypedLink from '@/components/Common/TKTypedLink';
import {
	TypographyH4,
	TypographyMuted,
	TypographyP,
} from '@/components/ui/typography';

interface QueryParams {
	prefillEmail: string | undefined;
}

const AuthSignup: React.FC = (): JSX.Element => {
	const router = useRouter();
	const auth = useAuth();
	const { notifyEmailSignupError } = useFirebaseAuthUtils();
	const { t } = useTranslation();
	const { status, handleReset, timerValues } = useCountdownTimer(60);
	const { hasProfileFinished, isLogged, emailVerified } = useFirebaseChecks();
	const { prefillEmail }: QueryParams = router.query;
	const { logout } = useAuthLogout();

	const FormSchema = useMemo(
		() =>
			z
				.object({
					email: z.string().email(),
					password: z.string().min(8).max(100),
					confirmPassword: z.string(),
				})
				.refine((data) => data.password === data.confirmPassword, {
					message: t('validations.confirmPassword.pattern'),
					path: ['confirmPassword'],
				}),
		[t],
	);

	const form = useForm<z.infer<typeof FormSchema>>({
		resolver: zodResolver(FormSchema),
		defaultValues: {
			email: prefillEmail || '',
			password: '',
			confirmPassword: '',
		},
	});

	const handleSendVerificationEmail = async (user?: User) => {
		const userObject = user || auth.user;
		try {
			setLoading(true);
			handleReset();
			if (!userObject) throw new Error();

			const returnUrl =
				endpoints.domain + createUrl('/auth-finish-profile', {});
			await sendEmailVerification(userObject, {
				url: returnUrl,
			});
		} catch (error) {
			notifyEmailSignupError(error);
		} finally {
			setLoading(false);
		}
	};

	const handleSignup = async ({
		email,
		password,
	}: z.infer<typeof FormSchema>) => {
		try {
			setLoading(true);
			const signup = await createUserWithEmailAndPassword(
				firebaseAuth,
				email,
				password,
			);

			await handleSendVerificationEmail(signup.user);
		} catch (error) {
			notifyEmailSignupError(error);
		} finally {
			setLoading(false);
		}
	};

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (isLogged && emailVerified && !hasProfileFinished) {
			router.replace('/auth-finish-profile', {});
		}

		if (isLogged && hasProfileFinished) {
			router.replace('/', {});
		}
	}, [emailVerified, hasProfileFinished, isLogged, router]);

	return (
		<AuthLayout>
			<div className="flex flex-col space-y-6 w-full max-w-md">
				<div className="flex flex-col space-y-2 text-center">
					<TypographyH4>{t('signup.welcomeMessage')}</TypographyH4>
				</div>

				{!auth.user && (
					<div className={cn('grid gap-6')}>
						<Form {...form}>
							<form
								onSubmit={form.handleSubmit(handleSignup)}
								className="space-y-4"
							>
								<FormField
									control={form.control}
									name="email"
									render={({ field }) => (
										<FormItem>
											<FormLabel>{t('validations.email.label')}</FormLabel>
											<FormControl>
												<Input
													placeholder="name@example.com"
													type="email"
													autoCapitalize="none"
													autoComplete="email"
													autoCorrect="off"
													disabled={loading}
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="password"
									render={({ field }) => (
										<FormItem>
											<FormLabel>{t('validations.password.label')}</FormLabel>
											<FormControl>
												<Input
													type="password"
													autoCapitalize="none"
													autoComplete="new-password"
													autoCorrect="off"
													disabled={loading}
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<FormField
									control={form.control}
									name="confirmPassword"
									render={({ field }) => (
										<FormItem>
											<FormLabel>
												{t('validations.confirmPassword.label')}
											</FormLabel>
											<FormControl>
												<Input
													type="password"
													autoCapitalize="none"
													autoComplete="new-password"
													autoCorrect="off"
													disabled={loading}
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								<Button type="submit" isLoading={loading}>
									{t('signup.signup')}
								</Button>
							</form>
						</Form>
						<Button variant="outline" asChild>
							<TKTypedLink to="/auth-login" params={{}}>
								{t('signup.goToLogin')}
							</TKTypedLink>
						</Button>
					</div>
				)}

				{auth.user && (
					<div className="space-y-4">
						<div className="space-y-2">
							<TypographyP>
								<Trans
									values={{
										email: form.watch('email') || auth.user?.email || '',
									}}
									i18nKey="login.enforceEmailAuth.weHaveSentEmail"
									components={{ bold: <strong /> }}
								/>
							</TypographyP>

							<TypographyMuted>
								{t('login.enforceEmailAuth.emailNotReceived')}
								{timerValues && timerValues.seconds > 0 && (
									<span className="ml-1">
										({timerValues.seconds.toString().padStart(2, '0')})
									</span>
								)}
							</TypographyMuted>
						</div>
						<div className="flex space-x-4">
							<Button
								onClick={() => handleSendVerificationEmail()}
								disabled={status === 'started' || loading}
							>
								{loading && <Spinner className="mr-2 h-4 w-4 animate-spin" />}
								{t('login.enforceEmailAuth.resendVerification')}
							</Button>
							<Button variant="outline" onClick={logout}>
								{t('signup.logout')}
							</Button>
						</div>
					</div>
				)}
			</div>
		</AuthLayout>
	);
};

export default AuthSignup;
