import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import {
	Form,
	FormField,
	FormItem,
	FormLabel,
	FormControl,
	FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Checkbox } from '@/components/ui/checkbox';
import { EMAIL_REGEX, URL_REGEX } from '@/utils/common/validators';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import SuperUserContainer from '@/components/Common/SuperUserContainer';
import TKPhoneNumber from '@/components/FormControls/TKPhoneNumber';
import { useUserProfile } from '@/hooks/auth/useUserProfile';

interface CompanyForm {
	name: string;
	address: string | null;
	email: string | null;
	phone: string | null;
	prefix: string | null;
	website: string | null;
	contactPerson: string | null;
	isTemplateCompany: boolean;
	featureEnableProjects: boolean;
	featureEnableGroups: boolean;
	organizationNo: string;
}

interface Props {
	companyId: number | null;
	isOpen: boolean;
	setOpen: (open: boolean) => void;
}

function UpdateCompanyFormDialog({ companyId, isOpen, setOpen }: Props) {
	const companyQuery = trpc.company.byId.useQuery(
		{ companyId: companyId! },
		{
			enabled: !!companyId,
			staleTime: Infinity,
			refetchOnWindowFocus: false,
		},
	);
	if (!companyQuery.data) return null;
	return (
		<WrappedForm
			companyId={companyId}
			defaultValues={companyQuery.data}
			isOpen={isOpen}
			setOpen={setOpen}
		/>
	);
}

function WrappedForm({
	isOpen,
	setOpen,
	companyId,
	defaultValues,
}: Props & { defaultValues: CompanyForm }): JSX.Element | null {
	const { data: userProfile } = useUserProfile();
	const { t } = useTranslation();

	const form = useForm<CompanyForm>({ defaultValues });

	const closeDialog = () => setOpen(false);

	const updateMutation = trpc.company.update.useMutation({
		onSuccess() {
			toast.success(
				t('common.api.update.success', { type: t('company.base') }),
			);
			form.reset();
			closeDialog();
		},
		onError(error) {
			toast.error(error?.message);
		},
	});

	const updateSettingsMutation = trpc.company.updateSettings.useMutation({
		async onSuccess() {
			form.reset();
			closeDialog();
		},
		onError(error) {
			toast.error(error?.message);
		},
	});

	const isSuperUser = !!userProfile?.isSuperuser;

	const onSubmit = async (data: CompanyForm) => {
		if (companyId) {
			// Update company metadata
			updateMutation.mutate({
				companyId: companyId,
				address: data.address || null,
				phone: data.phone || null,
				prefix: data.prefix || null,
				website: data.website || null,
				email: data.email || null,
				contactPerson: data.contactPerson || null,
				organizationNo: data.organizationNo,
			});

			if (isSuperUser) {
				updateSettingsMutation.mutate({
					companyId: companyId,
					name: data.name,
					featureEnableGroups: data.featureEnableGroups,
					featureEnableProjects: data.featureEnableProjects,
				});
			}
		}
	};

	return (
		<Dialog open={isOpen} onOpenChange={setOpen}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{t(`company.updateCompanyDialog.title`)}</DialogTitle>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div className="space-y-4">
							<SuperUserContainer>
								<div className="space-y-4">
									<FormField
										control={form.control}
										name="isTemplateCompany"
										render={({ field }) => (
											<FormItem className="flex flex-row items-start space-x-3 space-y-0">
												<FormControl>
													<Checkbox
														checked={field.value}
														onCheckedChange={field.onChange}
													/>
												</FormControl>
												<FormLabel>{t('company.templateCompany')}</FormLabel>
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="featureEnableProjects"
										render={({ field }) => (
											<FormItem className="flex flex-row items-start space-x-3 space-y-0">
												<FormControl>
													<Checkbox
														checked={field.value}
														onCheckedChange={field.onChange}
													/>
												</FormControl>
												<FormLabel>{t('company.projects.title')}</FormLabel>
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="featureEnableGroups"
										render={({ field }) => (
											<FormItem className="flex flex-row items-start space-x-3 space-y-0">
												<FormControl>
													<Checkbox
														checked={field.value}
														onCheckedChange={field.onChange}
													/>
												</FormControl>
												<FormLabel>{t('company.groups.title')}</FormLabel>
											</FormItem>
										)}
									/>
									<FormField
										control={form.control}
										name="name"
										rules={{ required: t('validations.companyName.required') }}
										render={({ field }) => (
											<FormItem>
												<FormLabel>
													{t('validations.companyName.label')}
												</FormLabel>
												<FormControl>
													<Input {...field} autoComplete="off" />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
							</SuperUserContainer>

							<FormField
								control={form.control}
								name="organizationNo"
								rules={{
									required: t('validations.organizationNumber.required'),
									pattern: {
										value: /^\S*$/,
										message: t('validations.organizationNumber.pattern'),
									},
								}}
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t('validations.organizationNumber.label')}
										</FormLabel>
										<FormControl>
											<Input {...field} autoComplete="off" />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>

							<FormField
								control={form.control}
								name="contactPerson"
								render={({ field }) => (
									<FormItem>
										<FormLabel>
											{t('validations.contactPerson.label')}
										</FormLabel>
										<FormControl>
											<Input
												{...field}
												data-testid="company-contact-person-input"
												autoComplete="name"
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>

							<TKPhoneNumber
								phoneValue={form.getValues('phone')}
								prefixValue={form.getValues('prefix')}
								control={form.control}
								errors={form.formState.errors}
								phoneName="phone"
								prefixName="prefix"
								required={false}
							/>

							<FormField
								control={form.control}
								name="address"
								render={({ field }) => (
									<FormItem>
										<FormLabel>{t('validations.address.label')}</FormLabel>
										<FormControl>
											<Input {...field} autoComplete="address-line1" />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>

							<FormField
								control={form.control}
								name="website"
								rules={{
									pattern: {
										value: URL_REGEX,
										message: t('validations.website.pattern'),
									},
								}}
								render={({ field }) => (
									<FormItem>
										<FormLabel>{t('validations.website.label')}</FormLabel>
										<FormControl>
											<Input {...field} autoComplete="url" />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>

							<FormField
								control={form.control}
								name="email"
								rules={{
									pattern: {
										value: EMAIL_REGEX,
										message: t('validations.email.pattern'),
									},
								}}
								render={({ field }) => (
									<FormItem>
										<FormLabel>{t('validations.email.label')}</FormLabel>
										<FormControl>
											<Input {...field} autoComplete="email" />
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<DialogFooter className="mt-6">
							<Button variant="outline" onClick={closeDialog}>
								{t('common.cancelText')}
							</Button>
							<TKConfirmButton
								dialogType="update"
								isLoading={
									updateMutation.isLoading || updateSettingsMutation.isLoading
								}
								disabled={
									updateMutation.isLoading || updateSettingsMutation.isLoading
								}
								data-testid="confirm-company-settings-button"
							/>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
}

export default UpdateCompanyFormDialog;
