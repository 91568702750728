import React from 'react';
import { cn } from '@/lib/utils';

type DashboardLayoutProps = {
	disablePadding?: boolean;
	className?: string;
	children: React.ReactNode | React.ReactNode[];
	AppBarComponent?: React.ReactNode;
	noScroll?: boolean;
};

/**
 * Layout wrapper to handle different layouts for TK Dashboard.
 */
const DashboardLayout = ({
	disablePadding = false,
	className,
	children,
	AppBarComponent,
	noScroll = false,
}: DashboardLayoutProps) => {
	return (
		<div className="h-full flex w-full flex-col flex-grow">
			{AppBarComponent}

			<div
				className={cn(
					'h-full flex-grow flex-1',
					noScroll ? 'overflow-hidden' : 'overflow-y-auto',
					disablePadding ? '' : 'py-2 px-4',
					className,
				)}
			>
				{noScroll ? (
					<div className="flex-1 h-full flex flex-col overflow-hidden">
						{children}
					</div>
				) : (
					children
				)}
			</div>
		</div>
	);
};

export default DashboardLayout;
