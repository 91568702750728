import { trpc } from '@/api/trpc';
import { Button } from '@/components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from '@/components/ui/dialog';
import { Spinner } from '@/components/ui/spinner';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';
import { useAuth } from '@/contexts/Global/AuthContext';
import {
	addSeconds,
	differenceInSeconds,
	endOfDay,
	startOfDay,
} from 'date-fns';
import { Trash2 } from 'lucide-react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, UseFormReturn } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { Form } from '@/components/ui/form';
import EquipmentSharingForm, {
	EquipmentSharingFormValues,
} from './EquipmentSharingForm';
import upperFirst from 'lodash/upperFirst';

type EquipmentSharingEditDialogProps = {
	isOpen: boolean;
	closeDialog: () => void;
	sharingRuleId: string;
};

const formSchema = z.object({
	startDate: z.date(),
	endDate: z.date(),
	weekdays: z.array(z.number().min(1).max(7)).min(1),
	allDay: z.boolean(),
	startTime: z.date(),
	endTime: z.date(),
	company: z.object({
		organizationNumber: z.string().optional(),
		name: z.string().optional(),
	}),
});

type FormValues = z.infer<typeof formSchema>;

const EquipmentSharingEditDialog: React.FC<EquipmentSharingEditDialogProps> = ({
	isOpen,
	closeDialog,
	sharingRuleId,
}) => {
	const { t } = useTranslation();
	const auth = useAuth();

	const updateRule = trpc.equipmentSharing.updateById.useMutation();
	const deleteRule = trpc.equipmentSharing.deleteById.useMutation();

	const sharingRule = trpc.equipmentSharing.byId.useQuery({
		companyId: auth.companyId,
		id: sharingRuleId,
	});

	const form = useForm<FormValues>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			startDate: new Date(),
			endDate: new Date(),
			weekdays: [1, 2, 3, 4, 5, 6, 7],
			allDay: true,
			startTime: startOfDay(new Date()),
			endTime: endOfDay(new Date()),
			company: {},
		},
	});

	useEffect(() => {
		if (!sharingRule.data) return;
		form.reset({
			startDate: new Date(sharingRule.data.period.startDate),
			endDate: new Date(sharingRule.data.period.endDate),
			weekdays: sharingRule.data.period.weekdays,
			company: {
				name: sharingRule.data.shareWithCompanyName,
			},
			allDay: !sharingRule.data.period.timeOfDay,
			startTime: sharingRule.data.period.timeOfDay
				? addSeconds(
						startOfDay(new Date()),
						sharingRule.data.period.timeOfDay.startTime,
					)
				: startOfDay(new Date()),
			endTime: sharingRule.data.period.timeOfDay
				? addSeconds(
						startOfDay(new Date()),
						sharingRule.data.period.timeOfDay.endTime,
					)
				: endOfDay(new Date()),
		});
	}, [sharingRule.data, form]);

	const onUpdate = (values: FormValues) => {
		updateRule.mutate(
			{
				companyId: auth.companyId,
				id: sharingRuleId,
				period: {
					startDate: values.startDate.toISOString(),
					endDate: values.endDate.toISOString(),
					timeOfDay: values.allDay
						? null
						: {
								startTime: differenceInSeconds(
									values.startTime,
									startOfDay(values.startTime),
								),
								endTime: differenceInSeconds(
									values.endTime,
									startOfDay(values.endTime),
								),
							},
					weekdays: values.weekdays,
				},
			},
			{
				onSuccess: () => {
					closeDialog();
				},
			},
		);
	};

	const onDelete = () => {
		deleteRule.mutate(
			{
				companyId: auth.companyId,
				id: sharingRuleId,
			},
			{
				onSuccess: () => {
					closeDialog();
				},
			},
		);
	};

	return (
		<Dialog open={isOpen} onOpenChange={closeDialog}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{t('equipment.EquipmentSharingEditDialog.title')}
					</DialogTitle>
				</DialogHeader>
				{sharingRule.data ? (
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onUpdate)} className="space-y-4">
							<EquipmentSharingForm
								form={
									form as unknown as UseFormReturn<EquipmentSharingFormValues>
								}
								readOnlyCompany
							/>
							<DialogFooter className="flex justify-between items-center">
								<Button
									type="button"
									variant="destructive"
									onClick={onDelete}
									className="flex items-center"
								>
									<Trash2 className="mr-2 h-4 w-4" />
									{upperFirst(t('equipment.EquipmentSharingEditDialog.delete'))}
								</Button>
								<div className="flex space-x-2">
									<Button
										type="button"
										variant="secondary"
										onClick={closeDialog}
									>
										{t('common.cancelText')}
									</Button>
									<TKConfirmButton
										dialogType="update"
										isLoading={updateRule.isLoading}
										onClick={form.handleSubmit(onUpdate)}
										disabled={form.watch('weekdays').length === 0}
									/>
								</div>
							</DialogFooter>
						</form>
					</Form>
				) : (
					<div className="flex justify-center items-center my-8">
						<Spinner />
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default EquipmentSharingEditDialog;
