import React from 'react';
import { useTranslation } from 'react-i18next';
import CompanyGroupsActions from './CompanyGroupsActions';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { Role } from '@/types/common/roles';
import { DataTable } from '@/components/Table/data-table';

function CompanyGroupsTable(): JSX.Element {
	const { t } = useTranslation();
	const { hasPermissions, companyId } = useAuth();
	const query = trpc.group.list.useQuery({ companyId });
	const canUpdateAndDelete = hasPermissions([Role.Owner]);

	return (
		<DataTable
			columns={[
				{
					id: 'name',
					title: t('company.groups.table.groupName'),
					enableSorting: true,
				},
				{
					id: 'id',
					title: ' ',
					size: 40,
					render: (row) =>
						canUpdateAndDelete ? (
							<CompanyGroupsActions id={row.id} companyId={companyId} />
						) : null,
				},
			]}
			data={query.data ?? []}
			isLoading={query.isLoading}
			error={query.error}
			searchColumn="name"
			defaultSortColumn="name"
			defaultSortOrder="asc"
			paginationPageSize={100}
		/>
	);
}

export default CompanyGroupsTable;
