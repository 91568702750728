//Homepage
import Homepage from '@/views/Homepage';

// Auth
import AuthLogin from '@/views/Auth/Login';
import AuthSignup from '@/views/Auth/Signup';
import AuthFinishSignUpProfile from '@/views/Auth/FinishSignupProfile';

// Companies
import ChooseCompany from '@/views/ChooseCompany';

// Equipment
import Map from '@/views/Map/MapPage';
import Equipment from '@/views/EquipmentPage/EquipmentPage';

// Administration
import DriversPage from '@/views/DriversPage/DriversPage';

// User profile
import UserProfile from '@/views/UserProfile';

// Company
import CompanyUsers from '@/views/Company/CompanyUsers/CompanyUsers';
import CompanyIntegrations from '@/views/Company/CompanyIntegrations/CompanyIntegrations';
import CompanyProjectsContainer from '@/views/Company/CompanyProjects/CompanyProjectsContainer';
import CompanyProjectRulesContainer from '@/views/Company/CompanyProjectRules/CompanyProjectRulesContainer';
import CompanyGroupsContainer from '@/views/Company/CompanyGroups/CompanyGroupsContainer';

import {
	UserCircle,
	Unlock,
	BarChart3,
	Home,
	Map as MapIcon,
	LineChart,
	Truck,
	ShipWheelIcon,
	Settings,
	CircuitBoard,
	Building,
} from 'lucide-react';
import { Route } from '@/types/common/routes';
import { Role } from '@/types/common/roles';
import AuthResetPassword from '@/views/Auth/ResetPassword';
import AuthFirebaseAction from '@/views/Auth/FirebaseAction';
import EquipmentDetailPage from '@/views/EquipmentDetailPage/EquipmentDetailPage';
import AnalysisPage from '@/views/AnalysisPage/AnalysisPage';
import ReportPage from '@/views/ReportPage';
import TKTypedRedirect from '@/components/Common/TKTypedRedirect';
import SysAdminNotifications from '@/views/SysAdmin/SysAdminNotifications/SysAdminNotifications';
import SysAdminRemoteConfig from '@/views/SysAdmin/SysAdminRemoteConfig/SysAdminRemoteConfig';

const routes = [
	{
		routeKey: 'homepage',
		categoryBaseUrl: '/homepage',
		path: '/',
		navLink: '/',
		allowedRoles: [Role.Owner, Role.Manager],
		allowNonAuthenticated: false,
		allowUnassignedRole: false,
		pageComponent: Homepage,
		showInNavbar: true,
		exact: true,
		icon: Home,
	},
	{
		routeKey: 'map',
		categoryBaseUrl: '/map',
		path: '/map/:equipmentId?',
		navLink: '/map',
		allowedRoles: [Role.Owner, Role.Manager],
		allowNonAuthenticated: false,
		allowUnassignedRole: false,
		pageComponent: Map,
		showInNavbar: true,
		exact: true,
		icon: MapIcon,
	},
	{
		routeKey: 'analysis',
		categoryBaseUrl: '/analysis',
		path: '/analysis/:id?',
		navLink: '/analysis',
		allowedRoles: [Role.Owner, Role.Manager],
		allowNonAuthenticated: false,
		allowUnassignedRole: false,
		pageComponent: AnalysisPage,
		showInNavbar: true,
		exact: true,
		icon: BarChart3,
	},
	{
		routeKey: 'report',
		categoryBaseUrl: '/report',
		path: '/report',
		navLink: '/report',
		allowedRoles: [Role.Owner, Role.Manager],
		allowNonAuthenticated: false,
		showInNavbar: true,
		exact: true,
		icon: LineChart,
		allowUnassignedRole: false,
		pageComponent: ReportPage,
	},
	{
		routeKey: 'equipment',
		categoryBaseUrl: '/equipment',
		path: '/equipment',
		allowedRoles: [Role.Owner, Role.Manager],
		allowNonAuthenticated: false,
		allowUnassignedRole: false,
		pageComponent: Equipment,
		showInNavbar: true,
		exact: true,
		icon: Truck,
	},
	{
		routeKey: 'equipmentDetails',
		categoryBaseUrl: '/equipment',
		path: '/equipment/:id',
		allowedRoles: [Role.Owner, Role.Manager],
		allowNonAuthenticated: false,
		allowUnassignedRole: false,
		pageComponent: EquipmentDetailPage,
		showInNavbar: false,
		exact: false,
	},
	{
		routeKey: 'driver',
		categoryBaseUrl: '/driver',
		navLink: '/driver',
		path: '/driver/:editId?',
		allowedRoles: [Role.Owner, Role.Manager],
		allowNonAuthenticated: false,
		allowUnassignedRole: false,
		pageComponent: DriversPage,
		showInNavbar: true,
		exact: true,
		icon: ShipWheelIcon,
	},
	{
		routeKey: 'chooseCompany',
		categoryBaseUrl: '/companies',
		path: '/companies',
		allowedRoles: [Role.Owner, Role.Manager],
		allowNonAuthenticated: false,
		allowUnassignedRole: true,
		pageComponent: ChooseCompany,
		showInNavbar: false,
		exact: true,
	},

	{
		routeKey: 'login',
		categoryBaseUrl: '/auth-login',
		path: '/auth-login',
		allowedRoles: [],
		allowNonAuthenticated: true,
		pageComponent: AuthLogin,
		showInNavbar: false,
		exact: false,
	},
	{
		routeKey: 'resetPassword',
		categoryBaseUrl: '/auth-reset-password',
		path: '/auth-reset-password',
		allowedRoles: [],
		allowNonAuthenticated: true,
		pageComponent: AuthResetPassword,
		showInNavbar: false,
		exact: true,
	},
	{
		routeKey: 'signup',
		categoryBaseUrl: '/auth-signup',
		path: '/auth-signup',
		allowedRoles: [],
		allowNonAuthenticated: true,
		pageComponent: AuthSignup,
		showInNavbar: false,
		exact: false,
	},
	{
		routeKey: 'signup',
		categoryBaseUrl: '/auth-finish-profile',
		path: '/auth-finish-profile',
		allowedRoles: [],
		allowUnassignedRole: true,
		allowNonAuthenticated: true,
		pageComponent: AuthFinishSignUpProfile,
		showInNavbar: false,
		exact: true,
	},
	{
		routeKey: 'auth',
		categoryBaseUrl: '/auth-action',
		path: '/auth-action',
		allowedRoles: [Role.Owner, Role.Manager],
		allowNonAuthenticated: true,
		allowUnassignedRole: true,
		pageComponent: AuthFirebaseAction,
		showInNavbar: false,
		exact: true,
	},
	{
		routeKey: 'companySettings',
		categoryBaseUrl: '/company-settings',
		path: '/company-settings',
		allowedRoles: [Role.Owner, Role.Manager],
		allowNonAuthenticated: false,
		allowUnassignedRole: false,
		pageComponent: () => (
			<TKTypedRedirect to="/company-settings/projects" params={{}} />
		),
		showInNavbar: true,
		exact: true,
		icon: Building,
	},
	{
		routeKey: 'companyProjects',
		categoryBaseUrl: '/company-settings',
		path: '/company-settings/projects',
		allowedRoles: [Role.Owner, Role.Manager],
		allowNonAuthenticated: false,
		pageComponent: CompanyProjectsContainer,
		showInNavbar: false,
		exact: true,
	},
	{
		routeKey: 'companyProjectRules',
		categoryBaseUrl: '/company-settings',
		path: '/company-settings/projects/:id/rules',
		allowedRoles: [Role.Owner, Role.Manager],
		allowNonAuthenticated: false,
		allowUnassignedRole: false,
		pageComponent: CompanyProjectRulesContainer,
		showInNavbar: false,
		exact: false,
	},
	{
		routeKey: 'companyGroups',
		categoryBaseUrl: '/company-settings',
		path: '/company-settings/groups',
		allowedRoles: [Role.Owner, Role.Manager],
		allowNonAuthenticated: false,
		allowUnassignedRole: false,
		pageComponent: CompanyGroupsContainer,
		showInNavbar: false,
		exact: true,
	},
	{
		routeKey: 'companyUsers',
		categoryBaseUrl: '/company-settings',
		path: '/company-settings/users',
		allowedRoles: [Role.Owner],
		allowNonAuthenticated: false,
		allowUnassignedRole: false,
		pageComponent: CompanyUsers,
		showInNavbar: false,
		exact: true,
		icon: Unlock,
	},
	{
		routeKey: 'integrations',
		categoryBaseUrl: '/company-settings',
		path: '/company-settings/integrations',
		allowedRoles: [],
		allowNonAuthenticated: false,
		allowUnassignedRole: false,
		allowSuperuser: true,
		pageComponent: CompanyIntegrations,
		showInNavbar: false,
		exact: true,
		icon: CircuitBoard,
	},
	{
		routeKey: 'sysAdminSettings',
		categoryBaseUrl: '/sys-admin',
		path: '/sys-admin',
		navLink: '/sys-admin',
		allowedRoles: [Role.Unassigned],
		allowNonAuthenticated: false,
		allowUnassignedRole: true,
		allowSuperuser: true,
		pageComponent: () => (
			<TKTypedRedirect to="/sys-admin/notifications" params={{}} />
		),
		showInNavbar: false,
		exact: true,
		icon: Settings,
	},

	{
		routeKey: 'sysAdminSettingsNotifications',
		categoryBaseUrl: '/sys-admin',
		path: '/sys-admin/notifications',
		allowedRoles: [],
		allowNonAuthenticated: false,
		allowUnassignedRole: false,
		allowSuperuser: true,
		allowSuperuserIfCompany: true,
		pageComponent: SysAdminNotifications,
		showInNavbar: false,
		exact: true,
	},
	{
		routeKey: 'sysAdminSettingsRemoteConfig',
		categoryBaseUrl: '/sys-admin',
		path: '/sys-admin/remote-config',
		allowedRoles: [],
		allowNonAuthenticated: false,
		allowUnassignedRole: false,
		allowSuperuser: true,
		allowSuperuserIfCompany: true,
		pageComponent: SysAdminRemoteConfig,
		showInNavbar: false,
		exact: true,
	},

	{
		routeKey: 'profile',
		categoryBaseUrl: '/profile',
		path: '/profile',
		allowedRoles: [Role.Manager, Role.Owner],
		allowUnassignedRole: true,
		allowNonAuthenticated: false,
		pageComponent: UserProfile,
		showInNavbar: false,
		exact: true,
		icon: UserCircle,
	},
] as const;

export type Path = (typeof routes)[number]['path'] | '/';
export type RouteKeys = (typeof routes)[number]['routeKey'];

type ExtractRouteParams<T> = string extends T
	? Record<string, string>
	: // eslint-disable-next-line @typescript-eslint/no-unused-vars
		T extends `${infer _Start}:${infer Param}/${infer Rest}`
		? { [k in Param | keyof ExtractRouteParams<Rest>]: string | number }
		: // eslint-disable-next-line @typescript-eslint/no-unused-vars
			T extends `${infer _Start}:${infer Param}`
			? { [k in Param]: string | number }
			: Record<string, never>;

// Object which has matching parameter keys for a path.
export type PathParams<P extends Path> = ExtractRouteParams<P>;

/**
 * Build an url with a path and its parameters.
 *
 * If the route path contains optional params (these params have a trailing `?`), you can pass in an empty string if you don't want to use that parameter.
 *
 * @example
 * createUrl(
 *   '/foo/bar/:required/:optional?',
 *   { required: 'some-required-value', 'optional?': '' },
 * ) // returns '/foo/bar/some-required-value'
 * @param path target path.
 * @param params parameters.
 */
export const createUrl = <P extends Path>(
	path: P,
	params: PathParams<P>,
): string => {
	let finalPath: string = path;

	// Upcast `params` to be used in string replacement.
	const paramObj: Record<string, string | number> = params || {};

	for (const key of Object.keys(paramObj)) {
		finalPath = finalPath.replace(`:${key}`, paramObj[key].toString());
	}

	return (
		finalPath
			// Replace consecutive slashes with a single slash
			.replace(/\/+/g, '/')
			// Remove trailing slashes
			.replace(/\/+$/, '')
	);
};
export const routesList = routes as unknown as Route[];
