import React, { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreHorizontal } from 'lucide-react';
import { Project } from '@/types/company/projects';
import { useDialog } from '@/contexts/Global/Dialog/DialogContext';
import { toast } from 'sonner';
import { useAuth } from '@/contexts/Global/AuthContext';
import { trpc } from '@/api/trpc';
import CompanyProjectFormDialog from './CompanyProjectFormDialog';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

interface Props {
	project: Project;
}

function CompanyProjectActions({ project }: Props) {
	const dialog = useDialog();
	const { companyId } = useAuth();

	const { t } = useTranslation();

	const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

	const deleteMutation = trpc.project.deleteById.useMutation({
		onSuccess() {
			toast.success(
				t('common.api.delete.success', { type: t('company.projects.base') }),
			);
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	function handleOpenDeleteDialog() {
		dialog({
			dialogType: 'delete',
			title: t('company.projects.deleteProjectsDialog.title'),
			description: t('company.projects.deleteProjectsDialog.description', {
				name: project.name,
			}),
			dataTestConfirm: 'confirm-delete-company-project',
		}).then(() => deleteMutation.mutate({ id: project.id, companyId }));
	}

	return (
		<>
			<div className="w-full flex justify-end">
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button
							variant="ghost"
							size="icon"
							className="group opacity-0 transition-all group-hover/row:opacity-100"
							data-testid="company-project-actions-menu"
						>
							<MoreHorizontal className="h-4 w-4" />
						</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent>
						<DropdownMenuItem
							onClick={() => setUpdateDialogOpen(true)}
							data-testid="company-project-update-menu"
						>
							{t('common.edit')}
						</DropdownMenuItem>
						<DropdownMenuItem
							onClick={handleOpenDeleteDialog}
							data-testid="company-project-delete-menu"
						>
							{t('common.delete')}
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
			{updateDialogOpen && (
				<CompanyProjectFormDialog
					open={updateDialogOpen}
					setOpen={setUpdateDialogOpen}
					dialogType="update"
					initialValues={project}
				/>
			)}
		</>
	);
}

export default memo(CompanyProjectActions);
