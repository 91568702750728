import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Project } from '@/types/company/projects';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import { toast } from 'sonner';

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import {
	Form,
	FormField,
	FormItem,
	FormLabel,
	FormControl,
	FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import TKConfirmButton from '@/components/TranslatedComponents/TKConfirmButton';

interface Props {
	initialValues?: Project;
	dialogType: 'create' | 'update';
	open: boolean;
	setOpen: (value: boolean) => void;
}

function CompanyProjectFormDialog({
	initialValues,
	open,
	setOpen,
	dialogType,
}: Props) {
	const { companyId } = useAuth();
	const { t } = useTranslation();

	const form = useForm<Project>({
		values: initialValues,
	});

	const insertMutation = trpc.project.insert.useMutation({
		async onSuccess() {
			toast.success(
				t('common.api.insert.success', { type: t('company.projects.base') }),
			);
			form.reset({ name: '' });
			setOpen(false);
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	const updateMutation = trpc.project.updateById.useMutation({
		async onSuccess() {
			toast.success(
				t('common.api.update.success', { type: t('company.projects.base') }),
			);
			form.reset({ name: '' });
			setOpen(false);
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{t(`company.projects.${dialogType}ProjectsDialog.title`)}
					</DialogTitle>
				</DialogHeader>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit((data) => {
							if (dialogType === 'create') {
								insertMutation.mutate({
									name: data.name,
									companyId,
								});
							} else if (dialogType === 'update' && initialValues) {
								updateMutation.mutate({
									id: initialValues.id,
									name: data.name,
									companyId,
								});
							}
						})}
					>
						<div className="space-y-4">
							<FormField
								control={form.control}
								name="name"
								rules={{ required: t('validations.projectName.required') }}
								render={({ field }) => (
									<FormItem>
										<FormLabel>{t('validations.projectName.label')}</FormLabel>
										<FormControl>
											<Input
												{...field}
												data-testid="company-project-name-input"
												autoComplete="off"
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
						</div>
						<DialogFooter className="mt-4">
							<Button
								type="button"
								variant="outline"
								onClick={() => setOpen(false)}
							>
								{t('common.cancelText')}
							</Button>
							<TKConfirmButton
								dialogType={dialogType}
								isLoading={insertMutation.isLoading || updateMutation.isLoading}
								disabled={insertMutation.isLoading || updateMutation.isLoading}
								data-testid={`confirm-project-${dialogType}-button`}
							/>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
}
export default CompanyProjectFormDialog;
