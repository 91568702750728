import * as React from 'react';
import { DayPicker, DropdownProps } from 'react-day-picker';
import { ChevronLeftIcon, ChevronRightIcon } from 'lucide-react';

import { cn } from '@/lib/utils';
import { buttonVariants } from '@/components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger } from './select';
import { ScrollArea } from './scroll-area';
import { useDateFns } from '@/hooks/common/useDateFns';

const MemoizedDropdown = React.memo(
	({ value, onChange, children }: DropdownProps) => {
		const options = React.Children.toArray(children) as React.ReactElement<
			React.HTMLProps<HTMLOptionElement>
		>[];
		const selected = options.find((child) => child.props.value === value);
		const handleChange = React.useCallback(
			(newValue: string) => {
				const changeEvent = {
					target: { value: newValue },
				} as React.ChangeEvent<HTMLSelectElement>;
				onChange?.(changeEvent);
			},
			[onChange],
		);

		return (
			<Select value={value?.toString()} onValueChange={handleChange}>
				<SelectTrigger className="h-7 w-auto max-w-20 overflow-hidden pr-1.5 focus:ring-0">
					<span className="truncate">{selected?.props?.children}</span>
				</SelectTrigger>
				<SelectContent position="popper">
					<ScrollArea className="">
						{options.map((option, id: number) => (
							<SelectItem
								key={`${option.props.value}-${id}`}
								value={option.props.value?.toString() ?? ''}
							>
								{option.props.children}
							</SelectItem>
						))}
					</ScrollArea>
				</SelectContent>
			</Select>
		);
	},
);

MemoizedDropdown.displayName = 'CustomDropdown';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
	className,
	classNames,
	showOutsideDays = true,
	...props
}: CalendarProps) {
	const { locale } = useDateFns();
	return (
		<DayPicker
			showOutsideDays={showOutsideDays}
			className={cn('p-3', className)}
			locale={locale}
			classNames={{
				months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
				month: 'space-y-4',
				caption: 'flex justify-between items-center',
				caption_label: cn(
					'text-sm font-medium',
					props.captionLayout === 'buttons' ? 'inline' : 'hidden',
					props.captionLayout === 'dropdown-buttons' &&
						(props.fromDate || props.fromMonth || props.fromYear) &&
						(props.toDate || props.toMonth || props.toYear)
						? 'hidden'
						: 'inline',
				),
				nav: 'flex',
				nav_button: cn(
					buttonVariants({ variant: 'outline' }),
					'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
				),
				caption_dropdowns: 'flex gap-x-1 flex-row-reverse',
				table: 'w-full border-collapse space-y-1',
				head_row: 'flex',
				head_cell:
					'text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]',
				row: 'flex w-full mt-2',
				cell: cn(
					'relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent',
					props.mode === 'range'
						? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
						: '[&:has([aria-selected])]:rounded-md',
				),
				day: cn(
					buttonVariants({ variant: 'ghost' }),
					'h-8 w-8 p-0 font-normal aria-selected:opacity-100',
				),
				day_range_start: 'day-range-start',
				day_range_end: 'day-range-end',
				day_selected:
					'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
				day_today: 'bg-accent text-accent-foreground',
				day_outside: 'text-muted-foreground opacity-50',
				day_disabled: 'text-muted-foreground opacity-50',
				day_range_middle:
					'aria-selected:bg-accent aria-selected:text-accent-foreground',
				day_hidden: 'invisible',
				...classNames,
			}}
			components={{
				Dropdown: MemoizedDropdown as (
					props: DropdownProps,
				) => React.ReactElement, // memoizing dropdown component, otherwise the select keeps closing whenever any state/prop changes
				IconLeft: () => <ChevronLeftIcon className="h-4 w-4" />,
				IconRight: () => <ChevronRightIcon className="h-4 w-4" />,
			}}
			{...props}
		/>
	);
}
Calendar.displayName = 'Calendar';

export { Calendar };
