import React, { useState } from 'react';
import { Button } from '@/components/ui/button';
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from '@/components/ui/tooltip';
import AnalysisUpdateFormDialog from '@/dialogs/analysis/AnalysisUpdateFormDialog';
import { useTranslation } from 'react-i18next';
import { TKLogoIcon } from '@/components/Common/TKCustomIcons';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import { useAuth } from '@/contexts/Global/AuthContext';
import { useDialog } from '@/contexts/Global/Dialog/DialogContext';
import { UserIcon, MoreVertical } from 'lucide-react';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { cn } from '@/lib/utils';
import { TypographyP } from '@/components/ui/typography';

interface Props {
	id: number;
	name: string;
	isTemplate: boolean;
	isEditable: boolean;
	isActive: boolean;
	handleChangeActiveDashboard: (id: number | null) => void;
}

function AnalysisListItem({
	id,
	name,
	isTemplate,
	isActive,
	isEditable,
	handleChangeActiveDashboard,
}: Props) {
	const dialog = useDialog();
	const { t } = useTranslation();

	const { companyId } = useAuth();

	const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false);

	const deleteMutation = trpc.analysis.deleteById.useMutation({
		onSuccess() {
			toast.success(
				t('common.api.delete.success', { type: t('analysis.base') }),
			);
			handleChangeActiveDashboard(null);
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	function handleOpenDeleteDialog() {
		dialog({
			title: t('analysis.deleteDashboard'),
			description: t('analysis.deleteDashboardDialog.description'),
			dialogType: 'confirm',
			dataTestConfirm: 'confirm-delete-dashboard',
		}).then(() =>
			deleteMutation.mutate({
				id,
				companyId,
			}),
		);
	}

	return (
		<Button
			variant="link"
			className={cn(
				'w-full justify-between px-4 py-2 group h-10 hover:no-underline hover:bg-accent',
				isActive && 'bg-accent text-accent-foreground',
			)}
			data-testid="dashboard-list-item-text"
		>
			<TypographyP className={cn('truncate', isActive && 'font-medium')}>
				{name}
			</TypographyP>
			<div className="flex items-center">
				{isTemplate && (
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant="ghost"
									size="icon"
									className="h-10 w-10 hover:bg-transparent"
								>
									<TKLogoIcon className="size-5" />
								</Button>
							</TooltipTrigger>
							<TooltipContent>{t('analysis.presetDashboard')}</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				)}
				{isEditable && !isActive && (
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant="ghost"
									size="icon"
									className="h-10 w-10 hover:bg-transparent"
								>
									<UserIcon />
								</Button>
							</TooltipTrigger>
							<TooltipContent>{t('analysis.youHaveEditAccess')}</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				)}
				{isEditable && isActive && (
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button
								variant="ghost"
								size="icon"
								className="h-10 w-10 hover:bg-primary/10"
								data-testid="dashboard-list-menu-item-actions"
							>
								<MoreVertical className="h-4 w-4" />
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent align="end">
							<DropdownMenuItem
								onClick={() => setIsUpdateDialogOpen(true)}
								data-testid="open-dashboard-list-menu-item-actions-update"
							>
								{t('analysis.editDashboard')}
							</DropdownMenuItem>
							<DropdownMenuItem
								onClick={handleOpenDeleteDialog}
								data-testid="open-dashboard-list-menu-item-actions-delete"
							>
								{t('analysis.deleteDashboard')}
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				)}
			</div>
			{isUpdateDialogOpen && (
				<AnalysisUpdateFormDialog
					id={id}
					open={isUpdateDialogOpen}
					setOpen={setIsUpdateDialogOpen}
				/>
			)}
		</Button>
	);
}
export default AnalysisListItem;
