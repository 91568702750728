import { useAuth } from '@/contexts/Global/AuthContext';
import { endOfDay, startOfDay, subMonths } from 'date-fns';
import { useSessionStorage } from '@/hooks/common/useSessionStorage';
import { DEFAULT_INTERVAL_FILTER } from '@/hooks/filters/useDetermineInterval';
import { useCallback } from 'react';
import { FiltersConfig } from '@/types/analysis';

function getDefaultFilters(): FiltersConfig {
	return {
		dateType: 'preset',
		interval: DEFAULT_INTERVAL_FILTER,
		fromTime: startOfDay(subMonths(new Date(), 3)).toISOString(),
		toTime: endOfDay(new Date()).toISOString(),
		presetAmount: 6,
		presetType: 'lastMonths',

		// Groups
		groupIds: null,
		hideDataFromWithoutGroups: false,

		// Projects
		projectIds: null,
		groupProjects: true,
		hideDataFromUnknownProjects: false,

		// Drivers
		driverIds: null,
		groupDrivers: true,
		hideDataFromUnknownDrivers: false,

		// Equipment
		equipmentIds: null,
		groupEquipment: true,
	};
}
const useAnalysisFilters = () => {
	const { companyId } = useAuth();

	const [filters, _setFilters] = useSessionStorage<FiltersConfig>(
		`analysisFiltersCompany${companyId}`,
		getDefaultFilters(),
	);

	/**
	 * Merges the new filters with the existing ones
	 */
	const setFilters = useCallback(
		(newFilters: Partial<FiltersConfig>) => {
			_setFilters((x) => ({
				...x,
				...newFilters,
			}));
		},
		[_setFilters],
	);

	return {
		filters,
		setFilters,
	};
};

export { useAnalysisFilters };
