import React from 'react';
import { TypographyP } from '@/components/ui/typography';

type MultistepNoDataFoundProps = {
	noDataText?: string;
	noDataWithSearchText?: string;
	search: string;
};

const MultistepNoDataFound: React.FC<MultistepNoDataFoundProps> = ({
	noDataWithSearchText,
	noDataText,
	search,
}) => {
	if (!noDataWithSearchText || !noDataText) return null;

	return (
		<div className="p-8">
			<TypographyP className="text-center">
				{search ? noDataWithSearchText : noDataText}
			</TypographyP>
		</div>
	);
};

export default MultistepNoDataFound;
