import React from 'react';
import { useTranslation } from 'react-i18next';

import AuthLayout from '@/components/Layouts/AuthLayout';
import { useRouter } from '@/hooks/common/useRouter';
import ResetPasswordForm from './ResetPasswordForm';
import VerifyEmailForm from './VerifyEmailForm';
import { TypographyH4 } from '@/components/ui/typography';

// Not using recoverEmail currently, we can safely disable it
const MODES = ['resetPassword', 'verifyEmail'] as const;
export interface FirebaseActionQuery {
	/** Types of auth action modes. */
	mode: (typeof MODES)[number];
	/** A one-time code, used to identify and verify a request */
	oobCode: string;
	/** Firebase project's API key, provided for convenience */
	apiKey: string;
	/** The return URL where the user will get redirected after completing a firebase action successfully. */
	continueUrl: string;
	/** Language <code></code>. */
	lang: string;
}

const authActionComponents: Record<
	FirebaseActionQuery['mode'],
	React.FC<FirebaseActionQuery>
> = {
	resetPassword: ResetPasswordForm,
	verifyEmail: VerifyEmailForm,
};

/**
 * Main entry point that enables custom firebase URL actions for various authentication helper methods
 *
 * Implementation based on https://firebase.google.com/docs/auth/custom-email-handler
 *
 * @return {*}
 */
const AuthFirebaseAction: React.FC = () => {
	const { t } = useTranslation();

	const query: FirebaseActionQuery = useRouter().query;

	const router = useRouter();
	const hasValidActionMode = MODES.includes(query.mode);
	if (!query.oobCode || !hasValidActionMode) router.replace('/', {});

	const AuthComponent = authActionComponents?.[query.mode] || null;
	return (
		<AuthLayout>
			<div className="flex flex-col space-y-6 w-full max-w-md">
				<div className="flex flex-col space-y-2 text-center">
					<TypographyH4>{t('login.welcomeMessage')}</TypographyH4>
				</div>
				{AuthComponent ? <AuthComponent {...query} /> : null}
			</div>
		</AuthLayout>
	);
};

export default AuthFirebaseAction;
