import React from 'react';
import { useTranslation } from 'react-i18next';
import { MoreHorizontal } from 'lucide-react';
import { trpc } from '@/api/trpc';
import { useRouter } from '@/hooks/common/useRouter';
import { useDialog } from '@/contexts/Global/Dialog/DialogContext';
import { useAuth } from '@/contexts/Global/AuthContext';
import CompanyProjectRuleUpdateDialog from '@/views/Company/CompanyProjectRules/CompanyProjectRuleUpdateDialog';
import { toast } from 'sonner';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';

interface Props {
	ruleId: string;
	projectId: string;
}

function CompanyProjectRulesActions({ ruleId, projectId }: Props): JSX.Element {
	const dialog = useDialog();
	const router = useRouter();

	const { companyId } = useAuth();
	const { t } = useTranslation();

	const [updateDialogOpen, setUpdateDialogOpen] = React.useState(false);

	const deleteMutation = trpc.projectRule.deleteById.useMutation({
		onSuccess() {
			toast.success(
				t('common.api.delete.success', {
					type: t('company.projectRules.base'),
				}),
			);

			router.replace('/company-settings/projects/:id/rules', {
				id: projectId,
			});
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	function handleDeleteRule() {
		dialog({
			dialogType: 'delete',
			title: t('company.projectRules.deleteRulesDialog.title'),
			description: t('company.projectRules.deleteRulesDialog.description'),
			dataTestConfirm: 'confirm-delete-project-rule',
		}).then(() => deleteMutation.mutate({ companyId, ruleId }));
	}

	return (
		<>
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<Button
						variant="ghost"
						size="icon"
						className="group opacity-0 transition-all group-hover/row:opacity-100"
						data-testid="project-rule-actions-menu"
					>
						<MoreHorizontal className="h-4 w-4" />
					</Button>
				</DropdownMenuTrigger>
				<DropdownMenuContent>
					<DropdownMenuItem
						onClick={() => setUpdateDialogOpen(true)}
						data-testid="project-rule-options-update-menu"
					>
						{t('company.projectRules.updateRule')}
					</DropdownMenuItem>
					<DropdownMenuItem
						onClick={handleDeleteRule}
						data-testid="project-rule-options-delete-menu"
					>
						{t('company.projectRules.deleteRule')}
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
			<CompanyProjectRuleUpdateDialog
				isOpen={updateDialogOpen}
				ruleId={ruleId}
				projectId={projectId}
				setOpen={setUpdateDialogOpen}
			/>
		</>
	);
}

export default CompanyProjectRulesActions;
