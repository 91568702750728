import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDateFns } from '@/hooks/common/useDateFns';
import { trpc } from '@/api/trpc';
import { useAuth } from '@/contexts/Global/AuthContext';
import TKDataHandler from '@/components/Progress/TKDataHandler';
import CompanyProjectRulesActions from './CompanyProjectRulesActions';
import { DataTable } from '@/components/Table/data-table';
import TKTimeTooltip from '@/components/Common/TKTimeTooltip';

interface Props {
	projectId: string;
}

function CompanyProjectRulesTable({ projectId }: Props): JSX.Element {
	const { companyId } = useAuth();
	const { t } = useTranslation();
	const { weekdaysOptions } = useDateFns();
	const {
		data: rules = [],
		isLoading,
		error,
	} = trpc.projectRule.list.useQuery({
		companyId,
		projectId,
	});

	const tableRules = rules.map((rule) => {
		const weekdaysText = rule.weekdays
			?.map((dayIndex) => {
				return weekdaysOptions.find((opt) => opt.value === dayIndex)?.label;
			})
			.join(', ');

		return {
			...rule,
			equipmentIds: rule.equipmentIds?.length ?? 0,
			weekdays: rule.weekdays?.length ? weekdaysText : '-',
		};
	});

	return (
		<TKDataHandler
			loading={isLoading}
			error={error}
			entryText={t('company.projectRules.base', { count: 2 })}
			data={rules}
		>
			<DataTable
				data={tableRules}
				columns={[
					{
						id: 'equipmentIds',
						title: t('company.projectRules.table.equipment'),
						render: (row) => (
							<span>
								{`${row.equipmentIds} ${t(
									'company.projectRules.table.equipment',
								)}`.toLowerCase()}
							</span>
						),
					},
					{
						id: 'fromTimestamp',
						title: t('company.projectRules.table.fromTimestamp'),
						render: (row) =>
							row.fromTimestamp ? (
								<TKTimeTooltip
									timestamp={row.fromTimestamp}
									tooltipFormat="P"
								/>
							) : (
								<span>-</span>
							),
					},
					{
						id: 'toTimestamp',
						title: t('company.projectRules.table.toTimestamp'),
						render: (row) =>
							row.toTimestamp ? (
								<TKTimeTooltip timestamp={row.toTimestamp} tooltipFormat="P" />
							) : (
								<span>-</span>
							),
					},
					{
						id: 'weekdays',
						title: t('company.projectRules.table.weekdays'),
						enableSorting: false,
					},
					{
						id: 'id',
						title: '',
						size: 40,
						render: (row) => (
							<CompanyProjectRulesActions
								ruleId={row.id}
								projectId={projectId}
							/>
						),
					},
				]}
				searchColumn="weekdays"
				defaultSortColumn="fromTimestamp"
				isLoading={isLoading}
				error={error}
				paginationPageSize={100}
			/>
		</TKDataHandler>
	);
}

export default CompanyProjectRulesTable;
