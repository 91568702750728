import React from 'react';
import { CenteredSpinner } from '@/components/ui/spinner';

/**
 * Displays a fully-sized page loading indicator.
 *
 */
const FullLoadingPage: React.FC = () => {
	return (
		<div className="h-full flex justify-center items-center flex-grow">
			<CenteredSpinner size={'lg'} />
		</div>
	);
};
export default FullLoadingPage;
