/* eslint-disable no-restricted-imports */

import { useMemo } from 'react';
import {
	useParams,
	useLocation,
	useHistory,
	useRouteMatch,
} from 'react-router-dom';

import queryString from 'query-string';
import { createUrl, Path, PathParams } from '@/routes/routesList';

/**
 * Bootstraps `react-router-dom` hooks and unifies them into a single hook, to reduce boilerplate code in the application.
 *
 */
const useRouter = () => {
	const params = useParams();

	const location = useLocation();

	const history = useHistory();

	const match = useRouteMatch();

	// Return our custom router object

	// Memoize so that a new object is only returned if something changes

	return useMemo(() => {
		// Router history functions overrides functions, uses a different API that enables type-safety for route paths.
		const historyOverrides = {
			push: <P extends Path>(path: P, params: PathParams<P>) => {
				history.push(createUrl(path, params));
			},

			replace: <P extends Path>(path: P, params: PathParams<P>) => {
				history.replace(createUrl(path, params));
			},
		};

		return {
			createUrl,
			// Show push and replace functions within the main object
			...historyOverrides,

			pathname: location.pathname,

			// Merge params and parsed query string into single "query" object

			// so that they can be used interchangeably.

			// Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }

			query: {
				...queryString.parse(location.search), // Convert string to object

				...params,
			} as any,

			// Include match, location, history objects so we have

			// access to extra React Router functionality if needed.

			match,

			location,

			history: {
				...history,
				historyOverrides,
			},
		};
	}, [params, match, location, history]);
};

export { useRouter };
