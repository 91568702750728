import React from 'react';
import TKTextWithLabel from '@/components/Common/TKTextWithLabel';
import { useTranslation } from 'react-i18next';

interface Props {
	defLevelPercentage?: number | null;
	sampledAt?: string | null;
}

function DefLevelField({ defLevelPercentage, sampledAt }: Props): JSX.Element {
	const { t } = useTranslation();

	const text =
		typeof defLevelPercentage === 'number'
			? `${defLevelPercentage}%`
			: t('common.notAvailable');

	return (
		<TKTextWithLabel
			label={t('equipment.columns.defLevel')}
			timestamp={sampledAt}
			text={text}
		/>
	);
}

export default DefLevelField;
