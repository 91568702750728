import debounce from 'lodash/debounce';
import { useLayoutEffect, useRef, useState } from 'react';
import { useEventListener } from './useEventListener';

type Size = {
	width: number | undefined;
	height: number | undefined;
};
/**
 * Hook that retrieves the size (width/height) of an element on page load.
 * This does not re-trigger on window resize.
 *
 */
const useElementSize = () => {
	const ref = useRef<HTMLDivElement>(null);
	const [size, setSize] = useState<Size>({
		width: undefined,
		height: undefined,
	});
	const debouncedSaveElementSize = debounce(() => {
		const el = ref.current;
		if (!el?.getBoundingClientRect) return;
		const { width, height } = el.getBoundingClientRect();
		setSize({ width, height });
	}, 50);

	useEventListener('resize', debouncedSaveElementSize);

	useLayoutEffect(() => {
		debouncedSaveElementSize();
	}, [ref, debouncedSaveElementSize]);

	return { ref, size };
};

export { useElementSize };
