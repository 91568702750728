/* eslint-disable @typescript-eslint/no-explicit-any */
import { RowData, memo } from '@tanstack/react-table';
import type { Row, Table as TableType } from '@tanstack/react-table';

export function getClientFacetedUniqueValues<TData extends RowData>(): (
	table: TableType<TData>,
	columnId: string,
) => () => Map<any, number> {
	return (table, columnId) =>
		memo(
			() => [table.getColumn(columnId)?.getFacetedRowModel()],
			(facetedRowModel) => {
				if (!facetedRowModel) return new Map();

				const facetedUniqueValues = new Map<string, number>();

				const initialFilters = table.getColumn(columnId)?.columnDef.meta;
				if (Array.isArray(initialFilters)) {
					for (const filter of initialFilters) {
						if (filter?.name) {
							facetedUniqueValues.set(filter.name, 0);
						}
					}
				}

				for (let i = 0; i < facetedRowModel.flatRows.length; i++) {
					const rowValue = facetedRowModel.flatRows[i]!.getValue(columnId)!;
					const values: any = Array.isArray(rowValue)
						? (rowValue as string[])
						: [rowValue];
					for (const val of values) {
						if (facetedUniqueValues.has(val)) {
							facetedUniqueValues.set(
								val,
								(facetedUniqueValues.get(val) ?? 0) + 1,
							);
						} else {
							facetedUniqueValues.set(val, 1);
						}
					}
				}

				// Sort the values so that nulls appear first
				const sortedEntries = Array.from(facetedUniqueValues.entries()).sort(
					(a, b) => {
						if (a[0] === null) return -1;
						if (b[0] === null) return 1;
						return 0;
					},
				);

				return new Map(sortedEntries);
			},
			{
				key: 'getFacetedUniqueValues_' + columnId,
			},
		);
}

export function filterFn<TData>(
	row: Row<TData>,
	id: string,
	filtersValue: any,
): boolean {
	const rowValue = row.getValue(id);
	const rowValueIsArray = Array.isArray(rowValue);
	const filtersValueIsArray = Array.isArray(filtersValue);

	// filter on a column with multiple values
	if (filtersValueIsArray && rowValueIsArray) {
		const s1 = new Set(rowValue as any[]);
		for (const v of filtersValue) {
			if (s1.has(v)) {
				return true;
			}
		}

		return false;
	}

	// filter on a column with simple value
	if (filtersValueIsArray && !rowValueIsArray) {
		return filtersValue.includes(rowValue);
	}

	// search on a column with multiple values
	if (!filtersValueIsArray && rowValueIsArray) {
		for (const v of rowValue) {
			if (
				v
					?.toString()
					?.toLowerCase()
					?.includes(filtersValue?.toString()?.toLowerCase())
			) {
				return true;
			}
		}

		return false;
	}

	// search on a simple column
	if (!filtersValueIsArray && !rowValueIsArray) {
		return !!rowValue
			?.toString()
			?.toLowerCase()
			?.includes(filtersValue?.toString()?.toLowerCase());
	}

	return false;
}
