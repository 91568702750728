import React, { useMemo, memo } from 'react';
import FilterBase, { FilterBaseVariant } from '../FilterBase';
import MultistepListCheckbox, {
	CheckboxList,
	MultistepItem,
} from '@/components/FormControls/MultistepListCheckbox';
import { User } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useDrivers } from '@/hooks/data/useDrivers';
import { TypographyP } from '@/components/ui/typography';

type DriverFilterType = number[] | null;

interface DriversFiltersValue {
	driverIds: DriverFilterType;
	hideDataFromUnknownDrivers: boolean;
}
interface DriversFiltersProps extends DriversFiltersValue {
	handleChange: (newValues: DriversFiltersValue) => void;
}

interface UseDriversFiltersTitleProps extends DriversFiltersValue {
	dataLength: number;
}

function useDriversFiltersTitle({
	driverIds,
	hideDataFromUnknownDrivers,
	dataLength,
}: UseDriversFiltersTitleProps): string {
	const { t } = useTranslation();

	const total = driverIds?.length || 0;

	if ((!driverIds || dataLength === total) && !hideDataFromUnknownDrivers) {
		return t('filters.drivers.all');
	}
	if (
		Array.isArray(driverIds) &&
		!driverIds?.length &&
		!hideDataFromUnknownDrivers
	) {
		return t('filters.drivers.unknownDrivers');
	}

	if (total === 0) return t('filters.drivers.title');

	return t('filters.drivers.base', { count: total });
}

function DriversFilters({
	driverIds,
	hideDataFromUnknownDrivers,
	handleChange,
	variant,
}: DriversFiltersProps & FilterBaseVariant): JSX.Element {
	const { t } = useTranslation();
	const { drivers } = useDrivers();
	const title = useDriversFiltersTitle({
		driverIds,
		hideDataFromUnknownDrivers,
		dataLength: drivers.length,
	});

	const allDriversSelected = driverIds === null;
	const data: CheckboxList = useMemo(() => {
		const unknownDrivers: MultistepItem = {
			active: !hideDataFromUnknownDrivers,
			label: t('filters.drivers.unknownDrivers'),
			searchFields: [t('filters.drivers.unknownDrivers')],
			value: 'hideDataFromUnknownDrivers',
			category: 'hideDataFromUnknownDrivers',
		};
		// Iterate over every driver and create a MultistepItem for each one
		const selectedDrivers: MultistepItem[] = drivers.map((driver) => {
			const active = driverIds ? driverIds.includes(driver.id) : false;
			const name = driver.driverFullname;

			return {
				label: name,
				searchFields: [
					name,
					...driver.driverCards.map((driver) => driver.tachoDriverId),
				],
				value: driver.id,
				active: active || allDriversSelected,
				category: 'driverIds',
			};
		});
		// Add unknown drivers to the top of the list
		selectedDrivers.unshift(unknownDrivers);

		return {
			title: t('filters.drivers.title'),
			items: selectedDrivers,
		};
	}, [hideDataFromUnknownDrivers, t, drivers, driverIds, allDriversSelected]);

	const handleSelect = (item: MultistepItem) => {
		const driverFilters: DriversFiltersValue = {
			hideDataFromUnknownDrivers: hideDataFromUnknownDrivers,
			driverIds: driverIds,
		};
		const allDriversIds = drivers.map((driver) => driver.id);

		// Toggle unknown driver data
		if (item.category === 'hideDataFromUnknownDrivers') {
			driverFilters.hideDataFromUnknownDrivers =
				!driverFilters.hideDataFromUnknownDrivers;
		}

		if (item.category === 'driverIds') {
			const val = Number(item.value);
			// In case the driverIds filter is null, that means every id is selected, so use the list of every driver available in the company
			// Otherwise, use the array of driverIds
			const listToAdd = allDriversSelected ? allDriversIds : driverIds || [];

			// Using a Set to make sure every value is unique
			// Add every driver ids to the Set
			const driversSet = new Set<number>(listToAdd);

			// Remove driver from the list if it exists, otherwise add id
			if (driversSet.has(val)) {
				driversSet.delete(val);
			} else {
				driversSet.add(val);
			}
			driverFilters.driverIds = [...driversSet];
		}

		const hasAllSelected =
			driverFilters.driverIds?.length === drivers.length ||
			driverFilters.driverIds === null;

		if (driverFilters.hideDataFromUnknownDrivers && hasAllSelected) {
			driverFilters.driverIds = [...allDriversIds];
		}

		handleChange(driverFilters);
	};

	const handleSelectAll = () => {
		handleChange({
			driverIds: null,
			hideDataFromUnknownDrivers: false,
		});
	};
	const handleDeselectAll = () => {
		handleChange({
			driverIds: [],
			hideDataFromUnknownDrivers: true,
		});
	};

	return (
		<FilterBase
			variant={variant}
			iconButton={User}
			buttonText={title}
			dataTestButton="open-drivers-filters-button"
			dataTestContent="drivers-filters-content"
		>
			<div className="w-[280px] h-full">
				<MultistepListCheckbox
					handleSelect={handleSelect}
					data={data}
					description={
						<TypographyP>{t('filters.drivers.description')}</TypographyP>
					}
					handleSelectAll={handleSelectAll}
					handleDeselectAll={handleDeselectAll}
					noDataText={t('filters.drivers.noData')}
					noDataWithSearchText={t('filters.drivers.noDataWithSearch')}
				/>
			</div>
		</FilterBase>
	);
}

export default memo(DriversFilters);
