import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@/contexts/Global/AuthContext';

import DashboardLayout from '@/components/Layouts/DashboardLayout';
import CompanyGroupsFormDialog from './CompanyGroupsFormDialog';
import CompanyGroupsTable from './CompanyGroupsTable';
import TKPageAppBar from '@/components/Layouts/DashboardLayout/TKPageAppBar';

import { Button } from '@/components/ui/button';
import { PlusIcon } from 'lucide-react';
import { CompanySettingsLayout } from '@/components/Layouts/DashboardLayout/CompanySettingsLayout';

const CompanyGroupsContainer: React.FC = () => {
	const { t } = useTranslation();
	const [dialogOpen, setDialogOpen] = useState(false);
	const { isOwner } = useAuth();

	return (
		<CompanySettingsLayout>
			<DashboardLayout
				disablePadding
				AppBarComponent={
					<TKPageAppBar title={t('company.groups.title')}>
						{isOwner && (
							<Button
								onClick={() => setDialogOpen(true)}
								data-testid="open-create-group-dialog"
							>
								<PlusIcon className="mr-2 h-4 w-4" />
								{t('company.groups.addNewGroup')}
							</Button>
						)}
					</TKPageAppBar>
				}
				noScroll
			>
				<CompanyGroupsTable />
			</DashboardLayout>
			<CompanyGroupsFormDialog
				dialogType="create"
				dialogOpen={dialogOpen}
				setDialogOpen={setDialogOpen}
			/>
		</CompanySettingsLayout>
	);
};

export default CompanyGroupsContainer;
