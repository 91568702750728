import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IntegrationConfig } from '@/types/company/integrations';
import IntegrationStatusIcons from './IntegrationStatusIcons';
import IntegrationStatusList from './IntegrationStatusList';
import { useDialog } from '@/contexts/Global/Dialog/DialogContext';
import { trpc } from '@/api/trpc';
import { toast } from 'sonner';
import IntegrationToggleSwitch from './IntegrationToggleSwitch';
import IntegrationFormDialog from './IntegrationFormDialog';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { Separator } from '@/components/ui/separator';
import { TypographyH6 } from '@/components/ui/typography';

interface Props {
	integration: IntegrationConfig;
	type: 'disabled' | 'enabled';
	expandedPanelId: number | null;
	setExpandedPanelId: React.Dispatch<React.SetStateAction<number | null>>;
}

function IntegrationAccordionItem({
	integration,
	type,
	expandedPanelId,
	setExpandedPanelId,
}: Props) {
	const dialog = useDialog();

	const { t } = useTranslation();
	const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

	const deleteMutation = trpc.integration.deleteById.useMutation({
		async onSuccess() {
			toast.success(
				t('common.api.delete.success', {
					type: t('company.integrations.base'),
				}),
			);
		},
		onError(error) {
			toast.error(error.message);
		},
	});

	function handleShowDeleteIntegrationDialog() {
		const valueToType = t(
			'company.integrations.deleteIntegrationDialog.confirmValueToType',
			{ integrationName: integration.name },
		);

		dialog({
			title: t('company.integrations.deleteIntegrationDialog.title'),
			description: t(
				'company.integrations.deleteIntegrationDialog.description',
				{
					name: `${integration.type}: ${integration.name}`,
				},
			),
			dataTestConfirm: 'confirm-delete-integration',
			withInputConfirm: {
				valueToType,
				descriptionComponent: (
					<Trans
						i18nKey="company.integrations.deleteIntegrationDialog.confirmDescription"
						components={{ bold: <strong /> }}
						values={{ confirmText: valueToType }}
					/>
				),
			},
			dialogType: 'delete',
		}).then(() =>
			deleteMutation.mutate({
				id: integration.id,
				companyId: integration.companyId,
			}),
		);
	}

	return (
		<>
			<Accordion
				type="single"
				collapsible // Added this prop
				value={
					expandedPanelId === integration.id
						? integration.id.toString()
						: undefined
				}
				onValueChange={(value) =>
					setExpandedPanelId(value ? parseInt(value) : null)
				}
				data-testid={`integrations-accordion-${type}`}
			>
				<AccordionItem value={integration.id.toString()}>
					<AccordionTrigger
						className="h-20"
						data-testid-integration-name={integration.name}
					>
						<div className="flex items-center">
							<IntegrationStatusIcons
								configId={integration.id}
								enabled={integration.enabled}
							/>
							<TypographyH6 className="ml-2" data-testid="integrations-title">
								{integration.type}: {integration.name}
							</TypographyH6>
						</div>
					</AccordionTrigger>
					<AccordionContent>
						<IntegrationStatusList
							configId={integration.id}
							clientId={integration.clientId}
							type={integration.type}
						/>
						<Separator className="my-4" />
						<div className="flex justify-end space-x-2">
							<IntegrationToggleSwitch
								configId={integration.id}
								enabled={integration.enabled}
								companyId={integration.companyId}
							/>
							<Button
								variant="outline"
								onClick={() => setUpdateDialogOpen(true)}
								data-testid="open-update-integration-dialog"
							>
								{t('common.edit')}
							</Button>
							<Button
								variant="destructive"
								onClick={handleShowDeleteIntegrationDialog}
								data-testid="open-delete-integration-dialog"
							>
								{t('common.delete')}
							</Button>
						</div>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
			{updateDialogOpen && (
				<IntegrationFormDialog
					dialogType="update"
					integrationTypeId={integration.type}
					open={updateDialogOpen}
					setOpen={setUpdateDialogOpen}
					defaultValues={integration}
				/>
			)}
		</>
	);
}
export default IntegrationAccordionItem;
